import React from 'react';
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { Card, Col, Row } from 'react-bootstrap';
import TablaMensajes from './TablaMensajes';

const Mensajes = () => {

  const { user } = useAuth();
  if( !user.profileMessage ) return <Navigate to={"/"} />;

  return (
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Mensajes</h3>
            </Col>
          </Row>
              <TablaMensajes />
        </Card.Body>
    );
  };

export default Mensajes;

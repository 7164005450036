import { getColor } from "./utils";

export const verifyAccess = ( user, seccion, urlToRedirect ) => {
    return ( user[seccion] ) ? true : false
}

export const getWeekOnYear = date => {
    const currentdate = new Date(date);
    var oneJan = new Date(currentdate.getFullYear(),0,1);
    var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
    var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
    return result;
}

export const setColors = (data) => {
  return data.map( dato => getColor(dato.color))
};

export const capitalizarString = (text) => text.replace(/^\w/, (c) => c.toUpperCase()); 

export const calcularMedida = (attribute) =>{
    if(attribute.id_unidad_medida == 1) 
    {
        return (Number.parseInt(attribute.value) * 0.039370).toFixed(2) + " pg" ; 
    }
    else if(attribute.id_unidad_medida == 2 )
    {
        return (Number.parseInt(attribute.value) / 0.039370).toFixed(2) + " mm" ;
    }
    //Casos Temperatura
    else if(attribute.id_unidad_medida == 4)
    {
        return ((Number.parseInt(attribute.value) * 1.8) + 32).toFixed(2) + " fahrenheit" ;
    }

    else if(attribute.id_unidad_medida == 3)
    {
        return ((Number.parseInt(attribute.value) - + 32) / 1.8 ).toFixed(2) + " Celsius" ;
    }
    //Casos Presion
    else if(attribute.id_unidad_medida == 5)
    {
        return ((Number.parseInt(attribute.value) * 14.504)).toFixed(2) + " psi" ;
    }
    else if(attribute.id_unidad_medida == 6)
    {
        return ((Number.parseInt(attribute.value) / 14.504)).toFixed(2) + " bar" ;
    }
}

// Convierte todos los valores de un formulario a mayusculas
export const convertStringsToUpperCase = (obj) => {
  if (typeof obj === 'object' && obj !== null) {
    if (Array.isArray(obj)) {
      return obj.map((item) => convertStringsToUpperCase(item));
    } else {
      const convertedObj = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (typeof value === 'string') {
            convertedObj[key] = value.toUpperCase();
          } else {
            convertedObj[key] = convertStringsToUpperCase(value);
          }
        }
      }
      return convertedObj;
    }
  }
  return obj;
};


// Obtiene el tiempo transcurrido entre una fecha y la fecha actual
export const getTimePast = ( timePast ) => {
  const now = new Date();
  const tiempoPasado = now - timePast;
  const horasPasadas = tiempoPasado / (1000 * 60 * 60); // Convertir a horas

  if (horasPasadas < 24) {
      return `Hace ${Math.floor(horasPasadas.toFixed(2))} horas`;
  } else {
      var diasPasados = Math.floor(horasPasadas / 24);
      return `Hace ${diasPasados} días`;
  }
}
import { useEffect, useState } from "react"
import { Card, Form, Spinner } from "react-bootstrap"

import useAuth from "hooks/useAuth";

import FalconCardHeader from "components/common/FalconCardHeader";
import EquipmentGraphic from 'pages/vendedor/inicio/graphics/EquipmentGraphic';
import axios from "utils/axios";

import { coloresGraphics, toastNotification } from 'helpers/utils';

export const TiposEquiposPorAreaChart = () => {

    const { user } = useAuth();
    
    const [ areas, setAreas ] = useState([]);
    const [ selectedArea, setSelectedArea ] = useState({});
    const [ tiposEquipos, setTiposEquipos ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);
    
    useEffect( () => getAreas(), [] );
    useEffect( () => getData(), [ selectedArea ] );

    const getAreas = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasByIdUser/${user.id}`);
            if( data.statusCode !== 200 ) return;
            setAreas(data.data);
            setSelectedArea(data.data[0])
        } catch (error) {
            console.error('Ocurrió un error al intentar obtener las áreas del usuario');
        };
    };

    const handleSelectChange = (event) => {
        setReadyContent(false);
        setSelectedArea(areas.find(area => area.id === parseInt(event.target.value)));
    };

    const getData = async () => {
        try {
            if( isObjectEmpty(selectedArea) ) return;
            const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/area/getCountTypeEquipmentByIdArea/${selectedArea.id}`);
            const dataRefactorized = data.data.map( (dato, index)=> { return { name: dato.name, value: dato.cantidad, color: coloresGraphics[index]  } } );
            setTiposEquipos(dataRefactorized);
        } catch (error) {
            toastNotification('warning', 'Ocurrió un error al intentar obtener los tipos de equipos del área seleccionada')
            console.error('Ocurrió un error al intentar obtener las áreas del usuario');
        };
        setReadyContent(true);
    };

    // Verifica si el objeto está vacio
    const isObjectEmpty = (obj) => {
        return obj && obj.constructor === Object && Object.keys(obj).length === 0;
    };

    return (
        <Card className="mb-3 h-100">
            <FalconCardHeader 
                light 
                title={'Tipos de Equipos por área'} 
                titleTag="h6" 
                className="py-3" 
                endEl={
                    <Form.Select 
                        size="sm" 
                        className="me-2" 
                        value={isObjectEmpty(selectedArea) ? '' : selectedArea.id} 
                        onChange={handleSelectChange}
                    >
                        { areas.map( area => <option key={area.id} value={area.id} >{area.nombre}</option> ) }
                    </Form.Select>
                }
            />
            
            <Card.Body className='p-3'>

                { !readyContent && <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> }

                { readyContent && (
                    tiposEquipos.length > 0
                    ? <EquipmentGraphic data={tiposEquipos} radius={['100%', '80%']} />
                    : <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}>
                        <p>El área no tiene ningún equipo</p>
                      </div>
                ) }

            </Card.Body>
        </Card>
    )

}

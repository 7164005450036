import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const EditArea = ({ data, handleChange, setShowEditModal }) => {

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [validated, setValidated] = useState(false);

  const [nombre, setNombre] = useState(data.nombre);
  const [descripcion, setDescripcion] = useState(data.descripcion);
  const [idTipoArea, setIdTipoArea] = useState(data.idTipoArea);
  const [idCorporativo, setIdCorporativo] = useState(data.idCorporativo);
  const [idPlanta, setIdPlanta] = useState(data.idPlanta);
  const [idLinea, setIdLinea] = useState(data.idLinea);

  const [tiposAreas, setTiposAreas] = useState([]);
  const [corporativos, setCorporativos] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [lineas, setLineas] = useState([]);

  useEffect(() => {
    Promise.all([ getAllTiposAreas(), getAllCorporativos(), getPlantas(idCorporativo), getLineas(idPlanta) ])
      .then(([ tiposAreas, corporativos, plantas, lineaas ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
    })
  }, []);

  const getAllTiposAreas = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tipoArea/getAllTypeAreas`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setTiposAreas(list);
      }
    }).catch((error) => {
      console.error("Error al intentar obtener todas las areas");
    });
  };

  const getAllCorporativos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporations`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setCorporativos(list);
      }
    }).catch((error) => {
      console.error("Error al intentar obtener todos los corporativos.")
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formData = {
        nombre: nombre,
        descripcion: descripcion,
        id_linea: idLinea,
        id_tipo_area: idTipoArea,
      };

      setIsLoading(true);
      await axios
      .put(
        `${process.env.REACT_APP_ADMIN_SERVICE}/area/${data.id}/${user.id}`,
        formData
        )
        .then((response) => {
          setIsLoading(false);
          if( verifyResponseUnauthorizedFetch(response, 3) ) return;
          handleChange(true);
          setShowEditModal(false);
          toastNotification("success", "Área editada correctamente");
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          toastNotification("error", "Ocurrió un error al editar el área");
        });
    }
    setValidated(true);
  };

  const getPlantas = (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getFloorsByIdCorporation/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setPlantas(list);
        }
      })
      .catch((err) => {
        console.error("Error al intentar obtener los datos de la planta.");
        setPlantas([]);
      });
  };

  const getLineas = (id) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/linea/getLinesByIdFloor/${id}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setLineas(list);
        }
      })
      .catch((err) => {
        console.error("Error al intentar obtener los datos de la linea");
        setLineas([]);
      });
  };
  const handleChangeCorporativo = (e) => {
    setPlantas([]);
    setLineas([]);
    setIdPlanta(0);
    setIdLinea(0);
    const id = e.target.value;
    if (id > 0) {
      setIdCorporativo(id);
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getFloorsByIdCorporation/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setPlantas(list);
            setLineas([]);
          }
        })
        .catch((err) => {
          setPlantas([]);
          setLineas([]);
        });
    }
  };

  const handleChangePlanta = (e) => {
    setLineas([]);
    setIdLinea(0);
    const id = e.target.value;
    if (id > 0) {
      setIdPlanta(id);
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/linea/getLinesByIdFloor/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setLineas(list);
          }
        })
        .catch((err) => {
          setLineas([]);
        });
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
          <Form.Label>Nombre:</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            required
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Nombre.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            as="textarea"
            rows={1}
            name="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Descripción.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Corporativo:</Form.Label>
          <Form.Select
            name="id_corporativo"
            value={idCorporativo}
            onChange={handleChangeCorporativo}
          >
            <option value="">Seleccione Corporativo</option>
            {corporativos.map((item) => (
              <option name="corporativo" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Planta:</Form.Label>
          <Form.Select
            name="id_planta"
            value={idPlanta}
            onChange={handleChangePlanta}
          >
            <option value="">Seleccione Planta</option>
            {plantas.map((item) => (
              <option name="planta" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Línea:</Form.Label>
          <Form.Select
            required
            name="id_linea"
            value={idLinea}
            onChange={(e) => setIdLinea(e.target.value)}
          >
            <option value="">Seleccione Línea</option>
            {lineas.map((item) => (
              <option name="linea" key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Línea.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Tipo Área:</Form.Label>
          <Form.Select
            required
            name="id_tipo_area"
            value={idTipoArea}
            onChange={(e) => setIdTipoArea(e.target.value)}
          >
            <option value="">Seleccione Tipo Área</option>
            {tiposAreas.map((item) => (
              <option name="tipo_area" key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Tipo Área.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2" disabled={isLoading}>
            Guardar
          </Button>
        </Form.Group>
        
      </Form>
    </div>
  );
};

import React, { useState, useEffect } from 'react'

import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import { columnsCiudades } from '../data.js';
import { toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';

import BitacoraEntidad from '../components/BitacoraEntidad'
import BitacoraData from '../components/BitacoraData.js'
import ModalDesabilitados from './components/ModalDesabilitados'
import ModalCiudades from './components/ModalCiudades'
import { showAlertaTokenExpired } from 'helpers/utils';
import useLoadingContext from 'hooks/useLoadingContext.js';

const CiudadesAdmin = () => {
    const navigate = useNavigate();

    const {user} = useAuth();
    const {isLoading, setIsLoading} = useLoadingContext();


    //! general
    const [ ciudades, setCiudades ] = useState([]);
    const [ ciudadesToTable, setCiudadesToTable ] = useState([]);
    const [ paises, setPaises ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false)

    //! Modales
    const [ showCiudadModal, setShowCiudadModal ] = useState(false);
    const [ showModalDisabled, setShowModalDisabled ] = useState(false);
    const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
    const [ showBitacoraData, setShowBitacoraData ] = useState(false);
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);

    //! To Update
    const [ idToUpdate, setIdToUpdate ] = useState(0);
    const [ idToDelete, setIdToDelete ] = useState(0);
    const [ ciudadToUpdate, setCiudadToUpdate ] = useState({});

    //! Bitacoras
    const [ idEntidad, setIdEntidad ] = useState(16);
    const [ idBitacoraData, setIdBitacoraData ] = useState([]);

    //? INICIANDO LA APP
    useEffect(() => {
      const hasAccess = verifyAccess(user, 'profileCiudadesAdmin', '/administrador/dashboard')
      if( !hasAccess ) navigate("/administrador/dashboard");
      if( hasAccess ) startResetApp();
    }, [])

    const startResetApp = async () => {
      setShowCiudadModal(false)
      setShowModalDisabled(false)
      setShowBitacoraEntidad(false)
      setShowBitacoraData(false)
      setShowDeleteModal(false)

      setIdToDelete(0)
      setIdToUpdate(0)
      setCiudadToUpdate({})
      Promise.all([ getAllPaises(), getAllCiudades() ])
        .then(([paises, ciudades]) => {
          setReadyContent(true)
        })
        .catch(error => {
          toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
          setReadyContent(true)
        })
    }

    useEffect(() => {
      if( ciudades.length < 1) return
      const toTable = ciudades.map( ciudad => {
        let toReturn = {}
        toReturn.descripcion = ciudad.descripcion;
        toReturn.distrito = ciudad.distrito;
        toReturn.region = ciudad.region.nombre;
        toReturn.pais = ciudad.region.country.nombre;
        toReturn.acciones = [
          <IconButton
              title="Editar"
              variant="white"
              size="sm"
              icon="edit"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setCiudadToUpdate(ciudad);
                setIdToUpdate(ciudad.id);
                setShowCiudadModal(true);
              }}
          ></IconButton>,
          <IconButton
              title="Deshabilitar"
              variant="white"
              size="sm"
              icon="trash-alt"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setIdToDelete(ciudad.id)
                setShowDeleteModal(true)
              }}
          ></IconButton>,
          <IconButton
              title="Ver Bitácora"
              variant="white"
              size="sm"
              icon="book"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={ () => {
                setShowBitacoraData(true)
                setIdBitacoraData(ciudad.id)
              }}
          ></IconButton>
        ];
        return toReturn
      } )
      setCiudadesToTable(toTable)
    }, [ciudades] )


    //! OBTENER TODOS LOS PAISES
    const getAllPaises = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`;
      try {
        const { data } = await axios.get(url);
        if( !data || data === undefined || data.statusCode === 401 ) {
          showAlertaTokenExpired()
          return;
        }
        if( data.statusCode == 200 ) setPaises(data.data)
      } catch (error) {
        console.error('Ha ocurrido un error al consultar los paises')
      }
    }
    
    //! OBTENER TODAS LAS CIUDADES
    const getAllCiudades = async () => {
      setReadyContent(false)
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getAllCities`;
      try {
        const { data } = await axios.get(url);
        if( data.statusCode == 200) setCiudades(data.data)
        setReadyContent(true)
      } catch (error) {
        console.error('Ha ocurrido un error al consultar las ciudades')
        setReadyContent(true)
      }
    }

    //! CREAR UN NUEVA CIUDAD
    const createNewCity = async ( ciudad ) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/createNewCiudad`;
      try {
        setIsLoading(true);
        const { data } = await axios.post(url, ciudad);
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 2) ) return;
        if( data.statusCode == 201) toastNotification('success', 'Ciudad creada correctamente');
        if( data.statusCode !== 201) toastNotification('warning', 'Ha ocurrido un error intentar crear la ciudad');
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error intentar crear la nueva ciudad')
        toastNotification('error', 'Ha ocurrido un error intentar crear la ciudad')
      }
    }

    //! ACTUALIZAR UNA CIUDAD
    const updateCity = async ( ciudad ) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/updateCiudad/${ciudad.id}`;
      try {
        setIsLoading(true);
        const { data } = await axios.patch(url, ciudad);
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 3) ) return;
        if( data.statusCode == 200) toastNotification('success', 'Ciudad actualizada correctamente')
        if( data.statusCode !== 200) toastNotification('warning', 'Ha ocurrido un error intentar actualizar la ciudad')
        startResetApp()
    } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error intentar actualizar la ciudad')
        toastNotification('error', 'Ha ocurrido un error intentar actualizar la ciudad')
      }
    }

    //! HABILITAR UNA CIUDAD
    const enableCity = async ( id ) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/enableCiudad/${id}`;
      try {
        setIsLoading(true);
        const { data } = await axios.patch(url);
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 3) ) return;
        if( data.statusCode == 200) toastNotification('success', 'Ciudad habilitada correctamente')
        if( data.statusCode !== 200) toastNotification('warning', 'Ha ocurrido un error intentar habilitar la ciudad')
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error intentar habilitar la ciudad');
        toastNotification('error', 'Ha ocurrido un error intentar habilitar la ciudad')
      }
    }
    
    //! DESHABILITAR UNA CIUDAD
    const disableCity = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/disableCiudad/${idToDelete}`;
      try {
        setIsLoading(true);
        const { data } = await axios.delete(url);
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 4) ) return;
        if( data.statusCode == 200) toastNotification('success', 'Ciudad deshabilitada correctamente')
        if( data.statusCode !== 200) toastNotification('warning', 'Ha ocurrido un error intentar deshabilitar la ciudad')
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error intentar deshabilitar la ciudad');
        toastNotification('error', 'Ha ocurrido un error intentar deshabilitar la ciudad')
      }
    }

    return (
        <>
            <Card.Body className="overflow-hidden p-lg-6">
              <Row className="justify-content-between">
                <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                  <h3 className="text-primary">Ciudades</h3>
                </Col>
              </Row>
      
            { (!readyContent) ? 
              <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
              <Card className="h-md-100">
                <Card.Body>
      
                <AdvanceTableWrapper
                  columns={columnsCiudades}
                  data={ciudadesToTable}
                  sortable
                  pagination
                  perPage={10}
                >
      
                <Row className="flex-end-center mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowCiudadModal(true)
                        }}
                      >
                        Nueva Ciudad
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowBitacoraEntidad(true);
                        }}
                      >
                        Bitácora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                            setShowModalDisabled(true)
                        }}
                      >
                        Deshabilitadas
                      </IconButton>
                    </div>
                  </Col>
                </Row>
                
                <AdvanceTable
                  title='Ciudades'
                  subtitle='Tabla de Ciudades'
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={ciudades.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </AdvanceTableWrapper>
                </Card.Body>
              </Card>
            ) }
            </Card.Body>


          <div>
            <BitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
          </div>

          <div>
            <BitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
          </div>

          <div>
            <ModalDesabilitados showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableData={enableCity}/>
          </div>

          <div>
            <ModalCiudades showCiudadModal={showCiudadModal} setShowCiudadModal={setShowCiudadModal} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} ciudadToUpdate={ciudadToUpdate} setCiudadToUpdate={setCiudadToUpdate} createNewCiudad={createNewCity} updateCiudad={updateCity} paises={paises}/>
          </div>
    
    
          <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
            <Modal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Deshabilitar Ciudad</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro de deshabilitar la Ciudad?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    setShowDeleteModal(false)
                  }}
                  >
                  Cancelar
                </Button>
                <Button 
                  variant="danger"
                  disabled={isLoading}
                  onClick={() => {
                    disableCity()
                    setShowDeleteModal(false)
                  }}

                >
                  Deshabilitar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
    
        </>
    )
}

export default CiudadesAdmin
 import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import EquipmentGraphic from './graphics/EquipmentGraphic'
import ClientGraphic from './graphics/ClientGraphic';
import IndustryGraphic from './graphics/IndustryGraphic';
import PlantGraphic from './graphics/PlantGraphic';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { coloresGraphics } from 'helpers/utils';
import { CantidadEquiposPorAreaChart } from 'pages/common/components/CantidadEquiposPorAreaChart';
import { TiposEquiposPorAreaChart } from 'pages/common/components/TiposEquiposPorAreaChart';
import { PrincipalesEquiposAhorroEconomicoChart } from 'pages/common/components/PrincipalesEquiposAhorroEconomicoChart';

export const Inicio = () => {

  const { user } = useAuth();
  const [ readyContent, setReadyContent ] = useState(false);
  const [ cantidadEquipos, setCantidadEquipos ] = useState(0);

  const [ tiposEquipos, setTiposEquipos ] = useState([]);
  const [ equiposPorIndustrias, setEquiposPorIndustrias ] = useState([]);
  const [ equiposPorCorporativos, setEquiposPorCorporativos ] = useState([]);
  const [ equiposPorPlantas, setEquiposPorPlantas ] = useState([]);

  const colores = coloresGraphics;

  useEffect(() => obtenerDetallesByIdUser(), []);
  
  const obtenerDetallesByIdUser = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getDetailEquipmentByIdUsuario/${user.id}`
    const { data } = await axios.get(url);

    setCantidadEquipos(data.data.length);

    getData( data.data.map( dato => dato.type), setTiposEquipos );
    getData( data.data.map( dato => dato.industry), setEquiposPorIndustrias );
    getData( data.data.map( dato => dato.corporation), setEquiposPorCorporativos );
    getData( data.data.map( dato => dato.floor), setEquiposPorPlantas );
    setReadyContent(true)
  };

  const statsData = [
    {
      title: 'Equipos en Monitoreo',
      value: cantidadEquipos,
      decimal: false,
      suffix: '',
      prefix: '',
      valueClassName: 'text-warning',
      link: '/vendedor/equipos',
      linkText: 'Ver Equipos'
    }
  ];

  const getData = ( data, func = () => {} ) => { 
    if( !data.length ) return;

    const contador = {};
  
    data.forEach( dato => {
      // Si el texto ya está en el contador, incrementa su valor, de lo contrario, inicialízalo en 1
      contador[dato] = (contador[dato] || 0) + 1; // (contador[typeEquipo] || 0) + 1; = Se intenta acceder al valor. Si el valor es undefined (Es primera vez que se encuentra), se inicializa en 0 y luego se le suma 1. De lo contrario. obtiene el valor y le suma 1.
    });
               //  [ 'llave', 'valor' ] y retorna { name: '', value: 0}
    let result = Object.entries(contador).map(([nombre, valor]) => ({ name: nombre, value: valor }));
    result.sort( ( a, b ) => b.value - a.value );
    result.forEach((dato, index) => dato.color = colores[index]);

    func(result);
  }

  
  return (
    <Card.Body className="overflow-hidden p-lg-6">

        <Row className="justify-content-between">
          <Col lg={6} className="ps-lg-4 my-5text-lg-start">
            <h3 className="text-primary">Inicio</h3>
          </Col>
        </Row>

        { ( !readyContent ) ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> : (
          <>
            <Row className="g-3 mb-3">
              {statsData.map((stat, index) => (
                <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
                  <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                </Col>
              ))}
            </Row>
            
            <Row className="g-3 mb-3">
              <Col md={6} sm={12}>
                {tiposEquipos.length > 0 && 
                  <Card className="h-md-100">
                    <Card.Body>
                      <EquipmentGraphic key={'tiposEquiposMonitoreo'} title='Tipos de Equipos en Monitoreo' data={tiposEquipos} radius={['100%', '80%']} />
                    </Card.Body>
                  </Card>
                }
              </Col>
              <Col md={6} sm={12}>
                {equiposPorIndustrias.length > 0 && 
                  <Card className="h-md-100">
                    <Card.Body>
                      <IndustryGraphic key={'equiposIndustria'} title='Equipos por Industria' data={equiposPorIndustrias} radius={['100%', '80%']} />
                    </Card.Body>
                  </Card>
                }
              </Col>
            </Row>
            <Row className="g-3 mb-3">
              <Col md={6} sm={12}>
                {equiposPorCorporativos.length > 0 && 
                  <Card className="h-100">
                    <Card.Body>
                      <ClientGraphic key={'EquiposCorporativo'} title='Equipos por Corporativo' data={equiposPorCorporativos} radius={['100%', '80%']} />
                    </Card.Body>
                  </Card>
                }
              </Col>
              <Col md={6} sm={12}>
                {equiposPorPlantas.length > 0 && 
                  <Card className='h-100'>
                    <Card.Body>
                      <PlantGraphic key='EquiposArea' title='Equipos por Planta' data={equiposPorPlantas} radius={['100%', '80%']} />
                    </Card.Body>
                  </Card>
                }
              </Col>
            </Row>
            
            { user.userGraphicsConfiguration.some( grafico => grafico._id === 1 ) && <Col md={12}><CantidadEquiposPorAreaChart /></Col> }
            <Row className='g-3'>
              { user.userGraphicsConfiguration.some( grafico => grafico._id === 2 ) && <Col className="mh-100" sm={12} md={6}><TiposEquiposPorAreaChart /></Col> }
              { user.userGraphicsConfiguration.some( grafico => grafico._id === 3 ) && <Col className="mh-100" sm={12} md={6}><PrincipalesEquiposAhorroEconomicoChart /></Col> }
            </Row>
          </>
        ) }

      </Card.Body>
  );
};
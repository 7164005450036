import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "utils/axios";
import { formatRut, toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const CreateCorporativo = ({ handleChange, setShowCreateModal }) => {

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [industrias, setIndustrias] = useState([]);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [paises, setPaises] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [validated, setValidated] = useState(false);

  const [nombre, setNombre] = useState();
  const [descripcion, setDescripcion] = useState();
  const [rut, setRut] = useState();
  const [razonSocial, setRazonSocial] = useState();
  const [idIndustria, setIdIndustria] = useState();
  const [idClasificacion, setIdClasificacion] = useState();
  const [idCiudad, setIdCiudad] = useState();

  useEffect(() => {
    // if( verifyResponseUnauthorizedFetch(response, 1) ) return;
    Promise.all([ getAllIndustrias(), getAllClasificaciones(), getAllPaises() ])
      .then(([allIndustrias, allClasificaciones, allPaises ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
    })
  }, []);

  const getAllIndustrias = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/industria/getAllIndustries`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setIndustrias(list);
      }
    }).catch( error => {
      console.error("Error al intentar obtener todas las industrias");
    } );
  };

  const getAllClasificaciones = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/clasificacion/getAllClasifications`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setClasificaciones(list);
      }
    }).catch( error => {
      console.error("Error al intentar obtener todas las clasificaciones");
    } );
  };

  const getAllPaises = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setPaises(list);
      }
    }).catch( error => {
      console.error("Error al intentar obtener todos los paises");
    } );
  };

  const handleChangeRut = ( value = '') => {
    let rutSinPuntos = value.replace(/\./g, "");
    let rutSinGuion = rutSinPuntos.replace(/-/g, "");
    let rutFormateado = formatRut(rutSinGuion);
    setRut(rutFormateado);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formData = {
        nombre: nombre,
        descripcion: descripcion,
        rut: rut,
        razon_social: razonSocial,
        id_industria: idIndustria,
        id_clasificacion: idClasificacion,
        id_ciudad: idCiudad,
        id_usuario: user.id,
      };

      setIsLoading(true);
      await axios
        .post(
          `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/createCorporation/`,
          formData
        )
        .then((response) => {
          setIsLoading(false);
          if( verifyResponseUnauthorizedFetch(response, 2) ) return;
          handleChange(true);
          setShowCreateModal(false);
          toastNotification('success', 'Corporativo creado correctamente')
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(err);
          toastNotification(
            "error",
            "Ocurrió un error al crear el corporativo"
          );
        });
    }
    setValidated(true);
  };

  const handleChangePais = (e) => {
    const id = e.target.value;
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getRegionsByIdCountry/${id}`;
    setCiudades([]);
    setRegiones([]);
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setRegiones(list);
          setCiudades([]);
        }
      })
      .catch((err) => {
        setRegiones([]);
        setCiudades([]);
      });
  };

  const handleChangeRegion = (e) => {
    setCiudades([]);
    const id = e.target.value;
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getCitiesByIdRegion/${id}`;
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setCiudades(list);
        }
      })
      .catch((err) => {
        setCiudades([]);
      });
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col lg={6}>

            <Form.Group className="mb-3 ">
              <Form.Label>Nombre:</Form.Label>
              <Form.Control
                type="text"
                name="nombre"
                value={nombre}
                required
                onChange={(e) => setNombre(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Descripción:</Form.Label>
              <Form.Control
                autoComplete="off"
                as="textarea"
                rows={1}
                name="descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Rut:</Form.Label>
              <Form.Control
                type="text"
                maxLength={12}
                value={rut}
                required
                onChange={(e) => handleChangeRut(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Rut.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Razón Social:</Form.Label>
              <Form.Control
                type="text"
                name="razon_social"
                required
                value={razonSocial}
                onChange={(e) => setRazonSocial(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Razón Social.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col lg={6}>

            <Form.Group className="mb-3">
              <Form.Label>Industria:</Form.Label>
              <Form.Select
                required
                name="id_industria"
                value={idIndustria}
                onChange={(e) => setIdIndustria(e.target.value)}
              >
                <option value="">Seleccione Industria</option>
                {industrias.map((item) => (
                  <option name="industria" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Industria.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Clasificación:</Form.Label>
              <Form.Select
                required
                name="id_clasificacion"
                value={idClasificacion}
                onChange={(e) => setIdClasificacion(e.target.value)}
              >
                <option value="">Seleccione Clasificación</option>
                {clasificaciones.map((item) => (
                  <option name="clasificacion" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Clasificación.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>País:</Form.Label>
              <Form.Select name="id_pais" onChange={handleChangePais}>
                <option value="">Seleccione País</option>
                {paises.map((item) => (
                  <option name="pais" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Región:</Form.Label>
              <Form.Select name="id_region" onChange={handleChangeRegion}>
                <option value="">Seleccione Región</option>
                {regiones.map((item) => (
                  <option name="region" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Ciudad:</Form.Label>
              <Form.Select
                required
                name="id_ciudad"
                value={idCiudad}
                onChange={(e) => setIdCiudad(e.target.value)}
              >
                <option value="">Seleccione Ciudad</option>
                {ciudades.map((item) => (
                  <option name="ciudad" key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Ciudad.
              </Form.Control.Feedback>
            </Form.Group>

          </Col>
        </Row>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button 
            type="submit" 
            variant="success"
            className="m-2"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Form.Group>
        
      </Form>
    </div>
  );
};

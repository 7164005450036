import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const getOptions = data => ({
  series: [
    {
      type: 'gauge',
      startAngle: 90,
      endAngle: -270,
      radius: '90%',
      pointer: {
        show: false
      },
      progress: {
        show: true,
        overlap: false,
        roundCap: true,
        clip: false,
        itemStyle: {
          color: getColor('primary')
        }
      },
      axisLine: {
        lineStyle: {
          width: 8,
          color: [[1, getColor('gray-200')]]
        }
      },
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: false
      },
      data: [
        {
          value: data,
          detail: {
            offsetCenter: ['7%', '4%']
          }
        }
      ],
      detail: {
        width: 50,
        height: 14,
        fontSize: 28,
        fontWeight: 500,
        fontFamily: 'poppins',
        color: getColor('gray-500'),
        formatter: '{value}%'
      }
    }
  ]
});


const BandwidthSaved = ({ isInstaled = false, nameMantencion, percentageComplented = 0, projectedCompletionDate, bodyClassName }) => {
  return (
    <>
      <ReactEChartsCore
        echarts={echarts}
        option={getOptions( (isInstaled) ? Number(percentageComplented) : 0 )}
        style={{ height: '10.3rem', widht: '10.3rem' }}
      />
      <div className="text-center mt-3">
        <h6 className="fs-0 mb-1">
          <FontAwesomeIcon
            icon={ isInstaled ? 'check': 'times' }
            transform="shrink-2"
            className={`${isInstaled ? 'text-success': 'text-danger'} me-2`}
          />
          {isInstaled ? 'Propuesta en marcha' : 'Sin mantención activa'}
        </h6>
        { isInstaled && (
          <>
            <p className="fs--1 mb-0">{nameMantencion}</p>
            <p className="fs--1 mb-0">Fecha supuesta de termino: <br /> <span className='text-primary fw-bold'>{moment(projectedCompletionDate).format('DD-MM-YYYY')}</span></p>
          </>
        ) }
      </div>
    </>
  );
};

BandwidthSaved.propTypes = {
  bodyClassName: PropTypes.string,
  isInstaled: PropTypes.bool,
  nameMantencion: PropTypes.string,
  percentageComplented: PropTypes.number,
  projectedCompletionDate: PropTypes.string,
};

export default BandwidthSaved;
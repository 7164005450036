export const configSelectTop = [
    { 
        id: 1,
        label: '7 días',
        value: '7 days'
    },
    { 
        id: 2,
        label: '14 días',
        value: '14 days'
    },
    { 
        id: 3,
        label: '21 días',
        value: '21 days'
    },
    { 
        id: 4,
        label: '1 mes',
        value: '1 month'
    },
    { 
        id: 5,
        label: 'Historial Completo',
        value: ''
    },
]

export const months = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Septiembre' },
    { id: 10, name: 'Octubre' },
    { id: 11, name: 'Noviembre' },
    { id: 12, name: 'Diciembre' }
];

export const years = Array.from({ length: 30 }, (_, index) => ({
    id: new Date().getFullYear() - index,
    name: (new Date().getFullYear() - index)
}));
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const CreatePlanta = ({ handleChange, setShowCreateModal }) => {
  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [validated, setValidated] = useState(false);

  const [corporativos, setCorporativos] = useState([]);
  const [paises, setPaises] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [ciudades, setCiudades] = useState([]);

  const [nombre, setNombre] = useState();
  const [codigoErp, setCodigoErp] = useState();
  const [idCorporativo, setIdCorporativo] = useState();
  const [idCiudad, setIdCiudad] = useState();

  useEffect(() => {
    Promise.all([ getAllCorporativos(), getAllPaises() ])
    .then(([ allCorporativos, allPaises ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al intentar cargar el formulario. Por favor, intentelo intentelo nuevamente');
    })
  }, []);

  const getAllCorporativos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporations`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setCorporativos(list);
      }
    }).catch( error => {
      console.error("Ha ocurrido un error al obtener los corporativos")
    });
  };

  const getAllPaises = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setPaises(list);
      }
    }).catch( error => {
      console.error("Ha ocurrido un error al obtener los corporativos");
    } );
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const formData = {
        nombre: nombre,
        codigo_erp: codigoErp,
        id_corporativo: idCorporativo,
        id_ciudad: idCiudad,
        id_usuario: user.id,
      };

      setIsLoading(true)
      await axios
        .post(
          `${process.env.REACT_APP_ADMIN_SERVICE}/planta/createFloor/`,
          formData
        )
        .then((response) => {
          setIsLoading(false)
          if( verifyResponseUnauthorizedFetch(response, 2) ) return; 
          handleChange(true);
          setShowCreateModal(false);
          toastNotification("success", "Planta creada correctamente");
        })
        .catch((err) => {
          setIsLoading(false)
          console.error(err);
          toastNotification("error", "Ocurrió un error al crear la planta");
        });
    }
    setValidated(true);
  };

  const handleChangePais = (e) => {
    const id = e.target.value;
    setRegiones([]);
    setCiudades([]);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getRegionsByIdCountry/${id}`;
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setRegiones(list);
          setCiudades([]);
        }
      })
      .catch((err) => {
        setRegiones([]);
        setCiudades([]);
      });
  };

  const handleChangeRegion = (e) => {
    const id = e.target.value;
    setCiudades([]);
    setIdCiudad(0);
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getCitiesByIdRegion/${id}`;
    axios
      .get(url)
      .then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if (response.data.statusCode === 200) {
          const list = response.data.data;
          setCiudades(list);
        }
      })
      .catch((err) => {
        setCiudades([]);
      });
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
          <Form.Label>Nombre:</Form.Label>
          <Form.Control
            type="text"
            name="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Nombre.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Código ERP:</Form.Label>
          <Form.Control
            type="text"
            name="codigoErp"
            value={codigoErp}
            onChange={(e) => setCodigoErp(e.target.value)}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Código ERP.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Corporativo:</Form.Label>
          <Form.Select
            required
            name="idCorporativo"
            value={idCorporativo}
            onChange={(e) => setIdCorporativo(e.target.value)}
          >
            <option value="">Seleccione Corporativo</option>
            {corporativos.map((item) => (
              <option name="corporativo" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Corporativo.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>País:</Form.Label>
          <Form.Select name="id_pais" onChange={handleChangePais}>
            <option value="">Seleccione País</option>
            {paises.map((item) => (
              <option name="pais" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Región:</Form.Label>
          <Form.Select name="id_region" onChange={handleChangeRegion}>
            <option value="">Seleccione Región</option>
            {regiones.map((item) => (
              <option name="region" key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Ciudad:</Form.Label>
          <Form.Select
            required
            name="id_ciudad"
            value={idCiudad}
            onChange={(e) => setIdCiudad(e.target.value)}
          >
            <option value="">Seleccione Ciudad</option>
            {ciudades.map((item) => (
              <option name="ciudad" key={item.id} value={item.id}>
                {item.descripcion}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione Ciudad.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button 
            type="submit" 
            variant="success" 
            className="m-2"
            disabled={isLoading}
          >
            Guardar
          </Button>
        </Form.Group>
        
      </Form>
    </div>
  );
};

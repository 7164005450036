import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, CloseButton, Card } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'utils/axios';

import { getExtension, toastNotification } from 'helpers/utils';
import { TablaArchivos } from '../../components';
import useAuth from 'hooks/useAuth';
import useLoadingContext from 'hooks/useLoadingContext';

export const NuevoDocumento = ({ startResetApp, showLiteratureModal, setShowLiteratureModal }) => {

  let params = useParams();

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [inputKey, setInputKey] = useState(Date.now());

  const [ title, setTitle ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ archivos, setArchivos ] = useState([]); // Lista de archivos
  
  const [ validated, setValidated ] = useState(false);

  useEffect( () =>{
    if( showLiteratureModal ) return;
    setTitle('');
    setDescription('');
    setArchivos([]);
    setValidated(false);
  }, [showLiteratureModal]);

  const handleSubmit = async(e) => {

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const formRequest = new FormData();
    formRequest.append("title", title);
    formRequest.append("description", description);
    formRequest.append("id_equipment", params.id);
    formRequest.append("date_creation", moment().toJSON());

    if (archivos) {
        for (const element of archivos) {
            formRequest.append("files", element.file);
        }
    }

    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/technicalLiterature/saveTechnicalLiterature/`, formRequest);
      if( resp.status === 201 ) {
          toastNotification( 'success','Literatura agregada correctamente',2000 )
          startResetApp();
          setShowLiteratureModal(false);
      }
      setIsLoading(false);

    } catch (error) {
      setIsLoading(false);
      console.log(error);
      error.response?.data?.statusCode === 413 
          ? toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
          : toastNotification("error", "Ha ocurrido un error al intentar agregar el documento", 2000)

      return;
    }
  };

  const handleFilesChange = (e) => {
    setInputKey(Date.now());

    const filesRecibidos = [...e];
    let array = filesRecibidos.map((archivo) => {
      const nameExtension = getExtension(archivo.name);
      return {
        file: archivo,
        temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
        name_file: nameExtension[0],
        extension: nameExtension[1],
        date_file: Date.now(),
        size: archivo.size,
        user: { name: user.displayName, lastName: user.displayLastName },
      }
    })

    setArchivos([ ...archivos, ...array]);
  };

  const handleDeleteFile = ( archivo ) => {
    setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
  }

  return (
    <>
      <div> {/* MODAL PARA AGREGAR UNA LITERATURA */}
          <Modal
            size='lg'
            show={showLiteratureModal}
            centered
            onHide={() => setShowLiteratureModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">Nuevo Documento</Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowLiteratureModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">

                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formGroupName">
                          <Form.Label>Título:</Form.Label>
                          <Form.Control
                              type="text"
                              name='title'
                              required
                              onChange={(e) => setTitle(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid">
                              Por favor ingresar Título.
                          </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="description">
                          <Form.Label>Descripción:</Form.Label>
                          <Form.Control
                              onChange={(e) => setDescription(e.target.value)}
                              as="textarea"
                              name='description'
                              rows={3}
                              required
                          />
                          <Form.Control.Feedback type="invalid">
                              Por favor ingresar Descripción.
                          </Form.Control.Feedback>                            
                      </Form.Group>

                      <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                          <Form.Control 
                            key={inputKey}
                            type="file" 
                            multiple 
                            onChange={(e) => handleFilesChange(e.target.files)} 
                          />
                      </Form.Group>

                      { archivos.length > 0 && (
                          <div className="my-4">
                            <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                          </div>
                        )
                      }

                      <Button disabled={isLoading} className='m-2' variant="secondary" onClick={() => {window.location.reload()}}>
                          Cancelar
                      </Button>
                      <Button disabled={isLoading} className='m-2' variant="success" type="submit">
                          Guardar
                      </Button>
                  </Form>

                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>
    </>
  )
}

import React, { useState, useEffect } from 'react';
import axios from 'utils/axios';
import moment from 'moment';
import { Card, CloseButton, Modal, Spinner } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

import { columnsBitacoraEntity } from '../data'
import { capitalizarString } from 'helpers';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const BitacoraData = ({ title, entidad, showBitacoraData, setShowBitacoraData, idBitacoraData }) => {

  const [ bitacoraToTable, setBitacoraToTable ] = useState([]);
  const [ bitacoraData, setBitacoraData ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  useEffect(() => {
    if (showBitacoraData) {
        setReadyContent(false);
        if(entidad == 'perfil') getBitacoraData(11,idBitacoraData);
        if(entidad == 'usuario') getBitacoraData(24,idBitacoraData);
    };
  }, [showBitacoraData]);

  useEffect( () => {
    if( bitacoraData.length < 1 ) {
        setBitacoraToTable([])
        return
    };
    const bitacoraAdapted = bitacoraData.map( bitacora => {
        const fechaHora = moment(bitacora.fecha_transaccion).format('DD-MM-YYYY, HH:mm:ss a')
        bitacora.fecha_transaccion = fechaHora.slice(0, 10) 
        bitacora.hora_transaccion = fechaHora.slice(12, 20)
        bitacora.nombreUser = `${bitacora.user.name} ${bitacora.user.lastName}`
        bitacora.nombreEntidad = capitalizarString(bitacora.typeEntity.nombre)
        return bitacora
    })
    setBitacoraToTable(bitacoraAdapted)
  }, [bitacoraData] );

    //! OBTENER BITACORA DE UN DATA POR SU ID
    const getBitacoraData = async (idEntidad,idData) => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/bitacora/getBitacoraByIdEntityIdData/${idEntidad}/${idData}`
        try {
          const { data } = await axios.get(url);
          if(verifyResponseUnauthorizedAwait(data, 1)) return;
          if(data.statusCode === 200 || data.statusCode === 204) {
            setBitacoraData(data.data)
            setReadyContent(true);
          }
        } catch (error) {
          console.error('Algo salió mal al consultar la bitacora del dato');
        }
      }

  return (
    <Modal
        size='xl'
        show={showBitacoraData}
        centered
        onHide={() => {
            setBitacoraData([])
            setShowBitacoraData(false)
        }}
        >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { title }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowBitacoraData(false)
                    setBitacoraData([])
                }}
            />
        </Modal.Header>

        <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( bitacoraData.length > 0 ) ? (
                        <AdvanceTableWrapper
                            columns={columnsBitacoraEntity}
                            data={bitacoraToTable}
                            sortable
                            pagination
                            perPage={10}
                        >
                        
                        <AdvanceTable
                            title='Bítacora'
                            subtitle='Tabla de Bítacora'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={bitacoraData.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) : <p className='text-center'>El dato no tiene historial</p>}
                </Card>
            )}
        </Modal.Body>
    </Modal>
  )
}

export default BitacoraData
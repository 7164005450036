import React, { useState } from 'react'
import { Modal, CloseButton, Form, Button } from 'react-bootstrap'

const OlvidePassword = ({ modalShow, setModalShow, handleOlvide }) => {
  
  const [ validated, setValidated ] = useState(false);
  const [ emailToRecuperar, setEmailToRecuperar ] = useState('');

  const recuperar = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }
    handleOlvide(emailToRecuperar);
    reinicarState()
  }

  const reinicarState = () => {
    setValidated(false)
    setEmailToRecuperar('')
    setModalShow(false);
  }

  return (
    <>
        <Modal
            show={modalShow}
            centered
            onHide={() => {
                setModalShow(false)
            }}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <p className=''>Recupera tu Cuenta</p>
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setModalShow(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-2 m-2">
                <p className='mb-5 '>Ingresa tu correo electrónico registrado en promanet y te enviaremos las instrucciones para recuperar tu contraseña</p>

                <Form noValidate validated={validated} onSubmit={recuperar}>


                    <Form.Group className="mb-3" controlId="email">
                        <Form.Control
                            placeholder={'name@example.com'}
                            autoComplete="off"
                            type="email"
                            name="email"
                            required
                            value={emailToRecuperar}
                            onChange={(e) => {
                                setEmailToRecuperar(e.target.value)
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, ingrese el correo electronico
                        </Form.Control.Feedback>              
                    </Form.Group>

                    <Form.Group>
                        <Button
                            variant="secondary"
                            className="m-2"
                            disabled={false}
                            onClick={() => {
                                setModalShow(false)
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button type="submit" variant="success" className="m-2" disabled={false} >
                            Cambiar Contraseña
                        </Button>
                    </Form.Group>

                </Form>

            </Modal.Body>
        </Modal>
    </>
  )
}

export default OlvidePassword
import React, { useState, useEffect } from 'react'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';
import { Button, Col, Spinner, Modal, Row } from 'react-bootstrap';
import { columns } from './data'
import { NuevoDocumento } from './NuevoDocumento';
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import { EditarDocumento } from './EditarDocumento';

import BitacoraData from '../../components/Bitacora'

import { toastNotification } from 'helpers/utils';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import moment from 'moment';
import TipoPerfilEnum from 'utils/TipoPerfilEnum';
import { TablaArchivos } from '../../components';
import useLoadingContext from 'hooks/useLoadingContext';

export const TablaLiteratura = () => {

  const navigate = useNavigate();
  const { user } = useAuth()
  const { isLoading, setIsLoading } = useLoadingContext()
  let params = useParams();

  const [showLiteratureModal, setShowLiteratureModal] = useState(false);
  const [showEditLiteratureModal, setShowEditLiteratureModal] = useState(false);
  const [showDeleteLiteratureModal, setShowDeleteLiteratureModal] = useState(false);
  const [showFilesModal, setShowFilesModal] = useState(false);
  const [deleteLiterature, setDeleteLiterature] = useState();
  const [editLiterature, setEditLiterature] = useState();
  const [ literatures, setLiteratures] = useState([]);
  const [files, setFiles] = useState([]);



  const [ readyContent, setReadyContent ] = useState(false);
  
  //? Bitacora
  const [ showBitacora, setShowBitacora ] = useState(false);
  const [ bitacoraLiteratura, setBitacoraliteratura ] = useState([]);
  
  //? OBTENER BITACORA DEL REPORTE
  const getBitacoraLiteraturaById = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/bitacora/getBitacoraByIdEntityIdData/27/${id}`;
    try {
      const { data } = await axios.get(url);
      if(data.statusCode == 200) setBitacoraliteratura(data.data);
    } catch (error) {
      console.error("Ha ocurrido un error al consultar la bitácora de la Literatura");
      toastNotification('error', 'Ha ocurrido un error al consultar la bitácora de la Literatura');
    }
  };

  

  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    !hasAccess ? navigate("/vendedor/inicio") : startResetApp();
  }, [])

  const startResetApp = () => {
    getLiteraturas()
  }

const getLiteraturas = async () => {
  if (user != null){
    let isAdmin = user.idTypeProfile == TipoPerfilEnum.administrador ? true : false;
    await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/technicalLiterature/getTechnicalLiteratureByEquipment/${params.id}`).then((response) => {
      const list = response.data.data;
      list.forEach(element => {
        element.dateCreation = moment(element.dateCreation).format('DD-MM-YYYY');
        element.accion =  [
          <IconButton
            className={ !isAdmin ? 'd-none' : 'shadow-none w-25' }
            title='Editar'
            variant="white"
            size="sm"
            icon="edit"
            onClick={() => {
              setShowEditLiteratureModal(true)
              setEditLiterature(element)
            }}
          >
          </IconButton>,
          <IconButton
            className={ !isAdmin ? 'd-none' : 'shadow-none w-25' }
            title='Eliminar'
            variant="white"
            size="sm"
            icon="trash-alt"
            onClick={() => {
              setShowDeleteLiteratureModal(true)
              setDeleteLiterature(element.id)
            }}
          >
          </IconButton>,
          <IconButton
            title="Adjuntos"
            variant="white"
            size="sm"
            icon="paperclip"
            className={ !isAdmin ? "shadow-none w-100" : 'shadow-none w-25' }
            onClick={() => {
              setShowFilesModal(true);
              setFiles(element.files)
            }}
          ></IconButton>,
          <IconButton
            className={ !isAdmin ? 'd-none' : 'shadow-none w-25' }
            title="Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ boxShadow: "none", width: "25%" }}
            onClick={() => {
              setShowBitacora(true)
              getBitacoraLiteraturaById(element.id)
            }}
          ></IconButton>,
        ];
      })
      setLiteratures(list)
      setReadyContent(true);
  })
  .catch(e => {
    setReadyContent(true);
  });
}
}

const openFile = async(e) => {
  window.open(`${process.env.REACT_APP_EQUIPO_SERVICE}/file/downloadFileById/${e.id}`, '_blank');
}

const handleDeleteLiterature = async() => {
  try {
    setIsLoading(true);
    const resp = await axios.delete(`${process.env.REACT_APP_EQUIPO_SERVICE}/technicalLiterature/${deleteLiterature}`)
    if( resp.status === 200 ){
      toastNotification('success','Literatura eliminada correctamente', 2000)
      setShowDeleteLiteratureModal(false)
      startResetApp();
    };
    setIsLoading(false);
  } catch (error) {
    console.error("Ha ocurrido un error al intentar eliminar la literatura tecnica");
  }
}


  return (
    <>
    { !readyContent ? 
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
      : (
        <>
          <AdvanceTableWrapper
            columns={columns}
            data={literatures}
            sortable
            pagination
            perPage={5}
          >
            <Row>
              <Col xs={12} className='mx-auto mb-2'>
                { user.idTypeProfile !== TipoPerfilEnum.cliente && (
                  <div id="new-document">
                    <IconButton
                      variant="falcon-success"
                      size="sm"
                      icon="plus"
                      transform="shrink-3"
                      className='me-2'
                      onClick={() => setShowLiteratureModal(true)}
                    >
                      Nuevo Documento
                    </IconButton>
                  </div>
                ) }
              </Col>
            </Row>
            <AdvanceTable
              title='Literatura Técnica'
              subtitle='Tabla de Literaturas Técnicas'
              table
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={literatures.length}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </>
      )}

      {/* LOS MODALES ESTARAN DISPONIBLE SOLO CUANDO EL USUARIO NO SEA DE TIPO CLIENTE */}
      { user.idTypeProfile !== TipoPerfilEnum.cliente && (
        <>
          <NuevoDocumento startResetApp={startResetApp} showLiteratureModal={showLiteratureModal} setShowLiteratureModal={setShowLiteratureModal}  />
          <EditarDocumento literaturaToEdit={editLiterature} setEditLiterature={setEditLiterature} startResetApp={startResetApp} showEditLiteratureModal={showEditLiteratureModal} setShowEditLiteratureModal={setShowEditLiteratureModal} />

          <div> {/* MODAL PARA ELIMINAR UNA LITERATURA */}
            <Modal show={showDeleteLiteratureModal}
              onHide={() => setShowDeleteLiteratureModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar Informe</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro que quiere eliminar el informe?</Modal.Body>
              <Modal.Footer>
                <Button disabled={isLoading} variant="secondary" onClick={() => setShowDeleteLiteratureModal(false)}>
                  Cancelar
                </Button>
                <Button disabled={isLoading} variant="danger" onClick={handleDeleteLiterature}>
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <BitacoraData title="Bitacora de la Literatura" showBitacoraData={showBitacora} setShowBitacoraData={setShowBitacora} bitacoraData={bitacoraLiteratura} setBitacoraData={setBitacoraliteratura} />
          </div>
        </>


      ) }

      <div>
        <Modal
          show={showFilesModal}
          onHide={() => setShowFilesModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size='lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>Archivos</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <TablaArchivos key={'tablaArchivos'} archivos={files} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowFilesModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default TablaLiteratura;


import IconButton from 'components/common/IconButton';
import React from 'react';

export const columns = [
  {
    accessor: 'type',
    Header: 'Tipo'
  },
  {
    accessor: 'category',
    Header: 'Categoría'
  },
  {
    accessor: 'issue',
    Header: 'Asunto'
  },
  {
    accessor: 'author',
    Header: 'Autor'
  },
  {
    accessor: 'date_message',
    Header: 'Fecha'
  },
  {
    accessor: 'accion',
    Header: 'Acciones'
  }
];

export const data = [
  {
    tipo: 'Otro',
    asunto: 'Sonido de la Máquina',
    fecha: '2022-09-11',
    semana: 'Semana 23',
    autor: 'Hans Huerta',
    accion: [
      <IconButton
        title='Ver Mensaje'
        variant="white"
        size="sm"
        icon="eye"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>,
      <IconButton
        title='Adjuntos'
        variant="white"
        size="sm"
        icon="paperclip"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>
    ]
  },
  {
    tipo: 'Solicitud',
    asunto: 'Mantención',
    fecha: '2022-09-12',
    semana: 'Semana 37',
    autor: 'Ricardo Olivares',
    accion: [
      <IconButton
        title='Ver Mensaje'
        variant="white"
        size="sm"
        icon="eye"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>,
      <IconButton
        title='Adjuntos'
        variant="white"
        size="sm"
        icon="paperclip"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>
    ]
  },
  {
    tipo: 'Urgente',
    asunto: 'Máquina Mala',
    fecha: '2022-09-01',
    semana: 'Semana 35',
    autor: 'Jorge Perez',
    accion: [
      <IconButton
        title='Ver Mensaje'
        variant="white"
        size="sm"
        icon="eye"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>,
      <IconButton
        title='Adjuntos'
        variant="white"
        size="sm"
        icon="paperclip"
        style={{ boxShadow: 'none', width: '50%' }}
      >
      </IconButton>
    ]
  }
];
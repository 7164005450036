import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  Modal,
  Nav,
  Dropdown,
  CloseButton
} from 'react-bootstrap';
import Login from 'components/authentication/Login';
import ContactForm from 'components/authentication/ContactForm';

const LandingRightSideNavItem = () => {
  const [showContactModal, setShowContactModal] = useState(false);

  return (
    <Nav navbar className="ms-auto">
      <Dropdown>
        <Dropdown.Toggle as={Link} to="#!" className="nav-link fw-semi-bold" style={{fontSize:'20px', color: 'white'}}>
          Ingresar a Promanet
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end dropdown-menu-card mt-0 dropdown-caret dropdown-caret-bg">
          <Card className="navbar-card-login shadow-none">
            <Card.Body className="fs--1 fw-normal p-4">
              <Login />
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>

      <Nav.Item>
        <Nav.Link
        className='ms-2'
          as={Link}
          to="#!"
          onClick={() => setShowContactModal(!showContactModal)}
          style={{fontSize:'20px', color:'white'}}
        >
          Contáctanos
        </Nav.Link>
        <Modal
          show={showContactModal}
          centered
          onHide={() => setShowContactModal(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Contáctanos
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowContactModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <ContactForm setShowContactModal={setShowContactModal} />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;

export const columnsContacto = [
    {
        accessor: 'nombre',
        Header: 'Nombre'
    },
    {
        accessor: 'correo',
        Header: 'Correo'
    },
    {
        accessor: 'fecha_contacto',
        Header: 'Fecha Contacto'
    },    
    {
        accessor: 'acciones',
        Header: 'Acciones'
    }
]

export const columnsMesaAyuda =[
    {
        accessor: 'usuarioSoli',
        Header: 'Usuario'
    },
    {
        accessor: 'asunto',
        Header: 'Asunto'
    },
    {
        accessor: 'shortMsg',
        Header: 'Mensaje'
    },    
    {
        accessor: 'fechaIngreso',
        Header: 'Fecha de Ingreso'
    },
    {
        accessor: 'estado',
        Header: 'Estado'
    },
    {
        accessor: 'usuarioAten',
        Header: 'Usuario Atención'
    },
    {
        accessor: 'fechaAten',
        Header: 'Fecha Atencion'
    },
    {
        accessor: 'acciones',
        Header: 'Acciones'
    },
]
import React, { useState, useEffect } from "react";
import { Button, Form, Tabs, Tab, Modal, CloseButton, Card } from "react-bootstrap";

import useAuth from "hooks/useAuth";
import Camara from "components/camara/Camara";
import axios from "utils/axios";
import { getExtension, toastNotification } from "helpers/utils";
import { useParams } from "react-router-dom";
import { TablaArchivos } from "pages/common/components";
import useLoadingContext from "hooks/useLoadingContext";

export const NuevoMensaje = ({ startResetApp, showMessageModal, setShowMessageModal }) => {

  let params = useParams();

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [showCamaraModal, setShowCamaraModal] = useState(false);
  const author = `${user.displayName} ${user.displayLastName}`;
  const [inputKey, setInputKey] = useState(Date.now());


  const [ archivos, setArchivos ] = useState([]);
  
  const [ type, setType ] = useState();
  const [ idCategoria, setIdCategoria ] = useState(undefined);
  const [ issue, setIssue ] = useState();
  const [ message, setMessage ] = useState();

  const [ validated, setValidated ] = useState(false);
  const [ tiposMessage, setTiposMessage ] = useState([]);
  const [ categoriasMensajes, setCategoriasMensajes ] = useState([]);


  useEffect(() => {
    if( showMessageModal ) return;
    setMessage('');
    setIssue('');
    setArchivos([]);
    setValidated(false);
  }, [ showMessageModal ]);

  useEffect(() => {
    getTiposMensajes()
    getCategoriaMensajes()
  }, []);

  //* OBTIENE LOS TIPOS DE MENSAJES DESDE LA BD
  const getTiposMensajes = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/message/getAllTypeMessage`;
    try {
      const { data } = await axios.get(url)
      if( data.statusCode === 200 ){
        setTiposMessage(data.data)
      }else{
        toastNotification('warning', 'Error al consultar los tipos de mensajes')
      }
    } catch (error) {
      console.error("Algo salio mal al intentar consultar los tipos de mensajes");
      toastNotification('error', 'Error al consultar los tipos de mensajes')
    }
  };

  const getCategoriaMensajes = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/categoriaMensaje/getAllCategoriaMensajes`;
    try {
      const { data } = await axios.get(url);
      ( data.statusCode === 200 )
        ? setCategoriasMensajes(data.data)
        : toastNotification('error', 'Ha ocurrido un error al obtener las categorias de mensajes');
    } catch (error) {
      console.error("Algo salio mal al intentar consultar la categorias de mensajes");
      toastNotification('error', 'Error al consultar las categorias de mensajes')
    }
  }

  //* SUBMIT DEL FORM
  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const formRequest = new FormData();
    formRequest.append("issue", issue);
    formRequest.append("message", message);
    formRequest.append("author", author);
    formRequest.append("id_type_message", type);
    formRequest.append("id_category_message", idCategoria);
    formRequest.append("id_equipment", params.id);
    formRequest.append("id_user", user.id);
    
    if (archivos) {
      for (const element of archivos) {
        formRequest.append("files", element.file);
      }
    } 

    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/message/saveMessage/`, formRequest);
      if( resp.status === 201 ){
        toastNotification('success', 'Mensaje creado correctamente');
        startResetApp();
        setShowMessageModal(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if( error.response?.data?.statusCode === 413) toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
      return;
    }
  
  };


  //* SE EJECUTA CUANDO SE ESCOGEN ARCHIVOS DESDE EL INPUT FILE
  const handleFilesChange = (e) => {
    setInputKey(Date.now());

    const filesRecibidos = [...e];
    let array = filesRecibidos.map((archivo) => {
      const nameExtension = getExtension(archivo.name);
      return {
        key: `${nameExtension[0]}_${archivo.lastModified}_${Math.floor(Math.random() * 1000)}`,
        file: archivo,
        temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
        name_file: nameExtension[0],
        extension: nameExtension[1],
        date_file: Date.now(),
        size: archivo.size,
        user: { name: user.displayName, lastName: user.displayLastName },
      }
    })

    setArchivos([ ...archivos, ...array]);
  };

  //* SE EJECUTA CUANDO SE TOMA UNA FOTOGRAFIA
  const handlePhotoChange = ( file ) => {
    const nameExtension = getExtension(file.name);
    const newPhoto = {
      file: file,
      temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
      name_file: nameExtension[0],
      extension: nameExtension[1],
      date_file: Date.now(),
      size: file.size,
      user: { name: user.displayName, lastName: user.displayLastName },
    };

    setArchivos([...archivos, newPhoto]);
    setShowCamaraModal(false);
  }


  //* SE EJECUTA CUANDO SE ELIMINA UN ARCHIVO DESDE LA TABLA
  const handleDeleteFile = ( archivo ) => {
    setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
  };

  return (
    <>
      {/* MODAL PARA NUEVOS MENSAJES */}
      <Modal
        size="lg"
        show={showMessageModal}
        className={ showCamaraModal ? 'd-none' : '' }
        centered
        onHide={() => setShowMessageModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Nuevo Mensaje
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowMessageModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          <span className="form-text m-4">
            El siguiente mensaje no funciona como chat, y quedará a la espera de una respuesta.
          </span>
          <Card>
            <Card.Body className="fs--1 fw-normal p-4">

              {/* CONTENIDO ACA */}
              <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Form.Group className="mb-3" onChange={(e) => setType(e.target.id) }>
                <Form.Label>Tipo:</Form.Label>
                <br/>
                { (tiposMessage.length > 0) ? (
                  tiposMessage.map( mensaje => {
                    return <Form.Check
                      inline
                      label={mensaje.description}
                      name="type"
                      type={'radio'}
                      id={mensaje.id}
                      required
                    />
                  })
                ) : (
                  <>
                    <Form.Check
                      inline
                      label="Normal"
                      name="type"
                      type={'radio'}
                      id={1}
                      required
                    />
                    <Form.Check
                      inline
                      label="Urgente"
                      name="type"
                      type={'radio'}
                      id={2}
                      required
                    />
                    <Form.Check
                      inline
                      label="Otro"
                      name="type"
                      type={'radio'}
                      id={3}
                      required
                    />
                  </>
                  
                )}
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Mensaje.
                  </Form.Control.Feedback>
                </Form.Group>


                <Form.Group className="mb-3" controlId="categoriaMensajeGroup">
                    <Form.Label>Categoría de Mensaje:</Form.Label>
                    <Form.Select
                        value={idCategoria}
                        onChange={(e) => setIdCategoria(e.target.value)}
                        name="categoriaMensaje"
                        required
                    >
                        <option disabled selected value="">--Seleccione Categoría--</option>
                        { categoriasMensajes.map( categoria => <option key={categoria.id} value={categoria.id}>{categoria.descripcion}</option> ) }
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Por favor seleccione categoría de mensaje.
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Asunto:</Form.Label>
                  <Form.Control
                    type="text"
                    name="issue"
                    required
                    onChange={(e) => setIssue(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Asunto.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Mensaje:</Form.Label>
                  <Form.Control
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    name="message"
                    rows={3}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Mensaje.
                  </Form.Control.Feedback>
                </Form.Group>

                <Tabs defaultActiveKey="uploadFile" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="uploadFile"
                    title="Subir Archivo"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                      <Form.Control
                        key={inputKey}
                        type="file"
                        multiple
                        onChange={(e) => handleFilesChange(e.target.files)}
                      />
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="takePhoto"
                    title="Tomar Foto"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formPhotoMultiple" className="mb-3">
                      <Form.Label>Tome una fotografía:</Form.Label>
                      <Button
                        variant="success"
                        className="ms-4"
                        onClick={() => setShowCamaraModal(!showCamaraModal)}
                      >
                        Tomar Foto
                      </Button>
                    </Form.Group>

                  </Tab>
                </Tabs>


                { archivos.length > 0 && (
                    <div className="my-4">
                      <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                    </div>
                  )
                }

                <Form.Group>
                  <Button
                    className="m-2"
                    disabled={isLoading}
                    variant="secondary"
                    onClick={() => setShowMessageModal(false)}
                  >
                    Cancelar
                  </Button>
                  <Button disabled={isLoading} className="m-2" variant="success" type="submit">
                    Guardar
                  </Button>
                </Form.Group>
              </Form>

            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

      {/* MODAL CAMARA */}
      <Modal
        size="lg"
        show={showCamaraModal}
        centered
        onHide={() => setShowCamaraModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Cámara</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowCamaraModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="fs--1 fw-normal">
              <Camara savePhoto={handlePhotoChange}/>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}


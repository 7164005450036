import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import "./index.css";
import App from "./App";
import Main from "./Main";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "helpers/initFA";
import { AuthProvider } from "./context/JWTContext";
import { LoadingProvider } from "context/LoadingContext";

ReactDOM.render(
    // <React.StrictMode>
        <Main>
            <BrowserRouter>
                <AuthProvider>
                    <LoadingProvider>
                        <App/>
                    </LoadingProvider>
                </AuthProvider>
            </BrowserRouter>
        </Main>,
    // </React.StrictMode>,
    document.getElementById("main")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useRef, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { coloresGraphics, getColor } from "helpers/utils";

function ChartOptions({ data = [], leyendText = '' }) {

  const chartRef = useRef(null)
  const [ datos, setDatos ] = useState([]);
  const [ totalValues, setTotalValues ] = useState(0);

  useEffect(() => {
      if( !data.length ) return;
      let totalVal = 0;
      setDatos(data.map((dato, index) => {
        totalVal = totalVal + Number(dato.value);
        return {
          name: dato.name,
          value: dato.value,
          itemStyle: {
              color: coloresGraphics[index]
          }
        }
      })
      )
      setTotalValues(totalVal);
  }, [data]);
    
  const getOption = (datos, leyendText) => ({
    title: [
      {
        text: leyendText.length > 0 ? `${leyendText} ${totalValues}` : '',
        left: 'center',
        textStyle: {
          color: getColor('gray-600'),
          fontSize: 12
        }
      }
    ],
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        center: window.innerWidth < 530 ? ['65%', '55%'] : ['50%', '45%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: getColor('gray-100'),
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        labelLine: {
          show: false
        },
        data: datos
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-1100') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  });
  
    //------- Responsive on window resize -------
    
  const updateDimensions = () => {
    if (window.innerWidth < 530) {
      chartRef.current.getEchartsInstance().setOption({
        series: [
          {
            center: ['65%', '55%']
          }
        ]
      });
    } 
    else
      chartRef.current.getEchartsInstance().setOption({
        series: [
          {
            center: ['50%', '55%']
          }
        ]
      });
    }
    
    useEffect(() => {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
      <ReactEChartsCore
        echarts={echarts}
        option={getOption(datos, leyendText)}
        ref={chartRef}
        style={{ height: '18.8rem' }}
      />
    );
  }


export default ChartOptions;
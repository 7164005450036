import React, { useEffect, useRef, useState } from 'react';
import { Card, CloseButton, Col, Modal, Row, Button, Spinner } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import GraficoPropuestas from './grafico/GraficoPropuestas';
import CrmStats from './ahorroPropuesta/CrmStats';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import {columns, data} from './data';
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { useParams, useNavigate } from 'react-router-dom';
import { NuevaPropuesta } from './nuevaPropuesta';
import { ActualizarPropuesta } from './updatePropuesta';
import { DetallePropuesta } from './detallePropuesta';
import TipoPerfilEnum from 'utils/TipoPerfilEnum';

import { toastNotification } from 'helpers/utils';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import useLoadingContext from 'hooks/useLoadingContext';

export const Propuestas = () => {
  const navigate = useNavigate();

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  let params = useParams();

  const [ showNewPropuestaModal, setShowNewPropuestaModal] = useState(false);
  const [ showUpdatePropuestaModal, setShowUpdatePropuestaModal] = useState(false);
  const [ showDeletePropuestaModal, setShowDeletePropuestaModal ] = useState(false);
  const [ showDetallePropuestaModal, setShowDetallePropuestaModal ] = useState(false);


  const [ tiposCostos, setTiposCostos ] = useState([]);
  const [ tiposEstados, setTiposEstados ] = useState([]);
  const [ propuestaToUpdate, setPropuestaToUpdate ] = useState();
  const [ propuestaToSee, setPropuestaToSee ] = useState({});

  const [ situacionAnterior, setSituacionAnterior ] = useState({});
  const [ costosSituacionAnterior, setCostosSituacionAnterior ] = useState({});
  const [ propuestaSelect, setPropuestaSelect ] = useState({});
  const [ costosPropuestaSelect, setCostosPropuestaSelect ] = useState([]);
  const [ periodoPropuestaSelect, setPeriodoPropuestaSelect ] = useState(0);

  const [ propuestas, setPropuestas] = useState([]);
  const [ propuetasConBotones, setPropuestasConBotones ] = useState([]);

  const [ idPropuestaToDelete, setIdPropuestaToDelete] = useState();

  const [ totalAnterior, setTotalAnterior ] = useState(0);
  const [ totalPropuesta, setTotalPropuesta ] = useState(0);

  const [ readyContent, setReadyContent ] = useState(false);

  const [ objToGraf, setObjToGraf ] = useState({
    promather: [],
    anterior: []
  });

  const isAdmin = useRef( (user.idTypeProfile === TipoPerfilEnum.administrador) ? true : false).current;

  useEffect(async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/vendedor/inicio");
    if(hasAccess) {
      startResetApp();
      await getTiposCostos();
      await getTiposEstadosPropuesta();

    }
  }, [])

  
  //! CREANDO EL OBJETO DE CON LOS BOTONES QUE SE MOSTRARAN EN LA TABLA
  useEffect( () => {
    let isAdmin = user.idTypeProfile == TipoPerfilEnum.administrador ? true : false;
    propuestas.forEach( (e, index) => {
      e.nombreEstado = e.idEstadoPropuesta.nombre;
      e.accion = [
        <IconButton
          title="Ver"
          variant="white"
          size="sm"
          icon="eye"
          style={{ 
            width: isAdmin ? '25%' : '50%',
            boxShadow: 'none',
          }}
          onClick={ () => {
            setPropuestaSelect(e);
            setPeriodoPropuestaSelect(e.periodo)
            getCostosPropuestaById(e.id);
          } }
        ></IconButton>,
        <IconButton
          style={{ 
            width: isAdmin ? '25%' : '',
            boxShadow: 'none',
            display: isAdmin ? '' : 'none'
          }}
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          onClick={ () => {
            setPropuestaToUpdate(e);
            setShowUpdatePropuestaModal(!showUpdatePropuestaModal)
          } }
        ></IconButton>,
        <IconButton
          className={ isAdmin ? '' : 'd-none' }
          title="Eliminar"
          variant="white"
          size="sm"
          icon="trash-alt"
          onClick={() => {
            setIdPropuestaToDelete(e.id)
            setShowDeletePropuestaModal(true);
            // deletePropuesta(e.id)
          }}
          style={{ 
            width: isAdmin ? '25%' : '',
            boxShadow: 'none',
            display: isAdmin ? '' : 'none'
          }}
        ></IconButton>,
        <IconButton
            // className={ isAdmin ? '' : 'd-none' }
            title="Ver Detalle"
            variant="white"
            size="sm"
            icon="list"
            style={{ 
              width: isAdmin ? '25%' : '50%',
              boxShadow: 'none',
            }}
            onClick={ () => {
              setPropuestaToSee(e);
              setShowDetallePropuestaModal(!showDetallePropuestaModal)
            } }
          ></IconButton>
      ];
        
    })
    setPropuestasConBotones(propuestas);
  }, [propuestas] )


  //! FUNCION PARA INICIA RO REINICIAR LA APP
  const startResetApp = async() => {
    getDataSituacionAnterior();
    getPropuestasByIdEquipo();
  }

  //! FUNCION PARA SETEAR TOTAL DE LA PROPUESTA Y TOTAL DE LA ANTERIOR
  const handleTotal = (anterior, propuesta) => {
    setTotalPropuesta(propuesta)
    setTotalAnterior(anterior);
  }


  //! FUNCION PARA OBTENER TODAS LAS PROPUESTAS POR EL ID DEL EQUIPO
  const getPropuestasByIdEquipo = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getPropuestasByIdEquipo/${params.id}`;
    const { data } = await axios.get(url);
    const propue = data.data
    setPropuestas(propue);
    setReadyContent(true);
  }

  //! FUNCION PARA OBTENER LA SITUAICON ANTERIOR Y SUS COSTOS
  const getDataSituacionAnterior = async() => {
    const situacionUrl = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getSituacionAnteriorByIdEquipment/${params.id}`;

    try {
      const { data } = await axios.get(situacionUrl);
      if( data.statusCode == 200 ) {
        setSituacionAnterior(data.data[0]);
        const costosSituacionUrl = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getCostosByIdSituacion/${data.data[0].id}`
        const respuesta = await axios.get(costosSituacionUrl);
        setCostosSituacionAnterior(respuesta.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  }


  //! FUNCION PARA ELIMINAR UNA PROPUESTA POR EL ID DE LA PROPUESTA
  const deletePropuesta = async (id) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/deletePropuestaById/${idPropuestaToDelete}`;
    const { data } = await axios.delete(url);
    if( data.statusCode === 200 ) {
      setShowDeletePropuestaModal(false);
      startResetApp();
      toastNotification('success','Propuesta eliminada correctamente', 2000)
    }
    setIsLoading(false);
  }


  //! FUNCION PARA OBTENER UNA PROPUESTA AL MOMENTO DE PRESIONAR EL BOTON VER
  const getCostosPropuestaById = async (id) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getCostosByIdPropuesta/${id}`
    const { data } = await axios.get(url);
    if( data.statusCode === 404 ) {
      setCostosPropuestaSelect([]);
      return
    } 
    setCostosPropuestaSelect(data.data)
  }


  //! FUNCION QUE OBTIENE LOS TIPOS DE COSTOS PARA ENVIARSELO AL MODAL DE COSTOS
  const getTiposCostos = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getAllTypeCostes`;
    const { data } = await axios.get(url);
    setTiposCostos( ...tiposCostos, data.data );
  }
  
  //! FUNCION QUE OBTIENE LOS ESTADOS DE UNA PROPUESTA PARA ENVIARSELO AL MODAL DE PROPUESTAS
  const getTiposEstadosPropuesta = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getAllEstadosPropuestas`;
    const { data } = await axios.get(url)
    setTiposEstados( ...tiposEstados, data.data );
  }


  return (
    <>
    { !readyContent ? 
     <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
    : (
      <>
        { (propuestas.length == 0) ? (
          <div className='my-5 py-5 text-center'>
            <p className='fs-3'>Este equipo no posee ninguna propuesta</p>
            <p className=''>Haz click en el siguiente botón para añadir una propuesta</p>
            { ( isAdmin ) && (
              <IconButton
                variant="falcon-success"
                size="sm"
                icon="plus"
                transform="shrink-3"
                className='me-2'
                onClick={() => {
                  setShowNewPropuestaModal(true)              
                }}
              >
                Nueva Propuesta
              </IconButton>
            ) }
          </div>
        ) : (
          <div>
            <Row>
              <Col lg={6} className="mt-2 mb-4">
                <Card>
                  <Card.Body>
                    <AdvanceTableWrapper
                      columns={columns}
                      data={propuetasConBotones}
                      sortable
                      pagination={true}
                      perPage={50}
                    >
                      <Row className="mb-3">
                        <Col xs={12} sm={7} className='text-md-center' >
                          <h5>Listado de Propuestas</h5>
                        </Col>
                        <Col xs={12} sm={5} className='flex align-items-center justify-content-center text-center'>
                          { ( isAdmin ) && (
                            <div id="new-document">
                              <IconButton
                                variant="falcon-success"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                className='me-2'
                                onClick={() => setShowNewPropuestaModal(!showNewPropuestaModal)}
                              >
                                Nueva Propuesta
                              </IconButton>
                            </div>
                          )}
                        </Col>
                      </Row>
                      <AdvanceTable
                        title='Propuestas'
                        subtitle='Tabla de Propuestas'
                        table
                        btnExport={false}
                        searchBar={false}
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />
                    </AdvanceTableWrapper>
                  </Card.Body>
                </Card>
              </Col >
              <Col className="mt-2 mb-4">
                <CrmStats propuesta={propuestaSelect} totalAnterior={totalAnterior} totalPropuesta={totalPropuesta}/> {/* GRAFICO DEL LADO */}
              </Col >
            </Row>
            <Row>
              <Col lg={12} className="mt-2 mb-4">
                <GraficoPropuestas propuesta={propuestaSelect} costosPropuesta={costosPropuestaSelect} situacionAnterior={situacionAnterior} costosSituacionAnterior={costosSituacionAnterior} periodoPropuestaSelect={periodoPropuestaSelect} handleTotal={handleTotal}/>
              </Col>
            </Row>
          </div>
        ) }
      </>

    )}

      {/* ************ MODALES ************ */}
      {/* LOS MODALES ESTARAN DISPONIBLE SOLO CUANDO EL USUARIO SEA DE TIPO ADMINISTRADOR */}
      { user.idTypeProfile === TipoPerfilEnum.administrador && (
        <>
        
          {/* NUEVA PROPUESTA */}
          <NuevaPropuesta showNewPropuestaModal={showNewPropuestaModal} tiposCostos={tiposCostos} tiposEstados={tiposEstados} setShowNewPropuestaModal={setShowNewPropuestaModal} idEquipo={params.id} startResetApp={startResetApp} setPropuestas={setPropuestas}/>
          
          {/* MODAL UPDATE PROPUESTA */}
          { showUpdatePropuestaModal && <ActualizarPropuesta startResetApp={startResetApp} tiposCostos={tiposCostos} tiposEstados={tiposEstados} propuestaToUpdate={propuestaToUpdate} showUpdatePropuestaModal={showUpdatePropuestaModal} setShowUpdatePropuestaModal={setShowUpdatePropuestaModal} setPropuestaToUpdate={setPropuestaToUpdate} setPropuestaSelect={setPropuestaSelect}/> }

          <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
            <Modal
              show={showDeletePropuestaModal}
              onHide={() => setShowDeletePropuestaModal(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Eliminar Propuesta</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro de querer eliminar la Propuesta?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeletePropuestaModal(false)}
                >
                  Cancelar
                </Button>
                <Button variant="danger" onClick={deletePropuesta}>
                  Eliminar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
        </>
      ) }

      <div>{/* MUESTRA EL DETALLE DE LA PROPUESTA */}
        <Modal
            size='xl'
            show={showDetallePropuestaModal}
            centered
            onHide={() => setShowDetallePropuestaModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Detalles de la Propuesta
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowDetallePropuestaModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                  <DetallePropuesta tiposCostos={tiposCostos} tiposEstados={tiposEstados} propuestaToSee={propuestaToSee} showDetallePropuestaModal={showDetallePropuestaModal} setShowDetallePropuestaModal={setShowDetallePropuestaModal} setPropuestaToSee={setPropuestaToSee}/>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>{/* FIN MODAL DETALLE DE LA PROPUESTA */}
</>
  )
}
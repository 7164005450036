import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Card, Dropdown, ListGroup } from "react-bootstrap";
import FalconCardHeader from "components/common/FalconCardHeader";
import Notification from "components/notification/Notification";
import useAuth from "hooks/useAuth";
import axios from "utils/axios";
import moment from "moment";

import iconReporte from '../../../assets/icons/notificaciones/report.png';
import iconMessage from '../../../assets/icons/notificaciones/message.png'
import iconLiteratura from '../../../assets/icons/notificaciones/literaturas.png'
import iconPropuesta from '../../../assets/icons/notificaciones/propuestas.png'

const iconMap = {
  reporte: iconReporte,
  mensaje: iconMessage,
  literatura: iconLiteratura,
  propuesta: iconPropuesta,
}

const NotificationDropdown = () => {
  const { user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(true);
  const [notifications, setNotifications] = useState([]);
  let navigate = useNavigate(); 

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleNotification = async ( notificacionId, id_equipment ) =>{

    let path = ''
    if( user.idTypeProfile == 1) path = `/vendedor/equipo/${id_equipment}`;
    if( user.idTypeProfile == 2) path = `/cliente/equipo/${id_equipment}`;
    if( user.idTypeProfile == 4) path = `/general/equipo/${id_equipment}`;
    
    navigate(path);
    await axios.put(`${process.env.REACT_APP_EMAILS_SERVICE}/notificaciones/setNotificacionVista`, { idUserNoti: notificacionId })
    setNotifications(notifications.filter(element => element.id != notificacionId));
    setIsOpen(!isOpen);
  };

  const getIconToNotify = (notificacion) => {
    
    if( notificacion.reporte !== null ) { return iconMap.reporte; }
    if( notificacion.message !== null ){ return iconMap.mensaje; }
    if( notificacion.literatura !== null ){ return iconMap.literatura; }
    if( notificacion.propuesta !== null || notificacion.situacionReal !== null ){ return iconMap.propuesta; }
  
    return null;
  }


  useEffect( async () => {
    if( user === null) return
    try {
    
      const { data } = await axios.get(`${process.env.REACT_APP_EMAILS_SERVICE}/notificaciones/getNotificacionesByIdUser`);

      if( data.statusCode !== 200 ) return;

      const notifications = data.data.map( userNoti => {
        const toReturn = { 
          id           : userNoti.id,
          titulo       : userNoti.notificacion.titulo,
          mensaje      : userNoti.notificacion.mensaje,
          fecha        : userNoti.notificacion.fecha,
          idEquipo     : userNoti.notificacion.equipo.id,
          typePriority : (userNoti.notificacion.message) ? userNoti.notificacion.message.typeMessage : null,
          icon         : getIconToNotify(userNoti.notificacion),
        };
        return toReturn
      });
      
      setNotifications(notifications)

    } catch (error) {
      console.error('Error al consultar las notificaciones del usuario')
    }
  }, [])
  

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        // as={Link}
        as={'Link'}
        to=""
        className={classNames("px-0 nav-link cursor-pointer", {
          "notification-indicator notification-indicator-primary ": notifications.length > 0,
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu 
        className="dropdown-menu-card dropdown-menu-end dropdown-caret-bg"
        style={{ right: "50%", transform: "translateX(50%)" }} // Alinea el centro del menú con el botón
      >
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: "20rem" }}
        >
          <FalconCardHeader
            className="card-header"
            title="Notificaciones"
            titleTag="h6"
            light={false}
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: "19rem" }}
          >

            { notifications.length < 1 ? ((<p className="text-center mt-2 text-secondary">Usted no tiene notificaciones</p>))
              : notifications.map((notification) => (
                <ListGroup.Item 
                  key={notification.id}
                  onClick={() => handleNotification(notification.id, notification.idEquipo)}
                  className="pb-0"
                >
                  <div style={{ maxWidth: "25rem" }}>
                    <Notification
                      children = {notification.mensaje}
                      emoji = {notification.issue}
                      time = {notification.fecha}
                      typePriority = {notification.typePriority}
                      icon = { notification.icon }
                    />
                  </div>
                </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;

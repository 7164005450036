import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown  } from 'react-bootstrap';
import useAuth from 'hooks/useAuth';
import { profiles } from 'data/profiles';
import ModalChangePassword from './ModalChangePassword'
import { toastNotification } from 'helpers/utils';
import axios from 'utils/axios';

const ProfileDropdown = ({ profileId }) => {
  const { logout, user } = useAuth();
  const [ modalShow, setModalShow ] = useState(false);

  const changePassword = async ( oldPassword, newPassword ) => {
    const url = `${process.env.REACT_APP_AUTH_SERVICE}/userPassword/updatePassword`;
    try {
      const { data } = await axios.post(url, { oldPassword, newPassword });
      if(data.statusCode == 200 ) {
        toastNotification('success', data.message, 2000)
        setTimeout(() => {
          handleLogout()
        }, 2000);
      }
      if(data.statusCode !== 200 ) toastNotification('warning', data.message)
    } catch (error) {
      toastNotification('error', 'Hubo un error al cambiar la contraseña')
    }
  };


  const handleProfile = function() {
    
    switch (user.idTypeProfile) {
      case profiles.vendedor:
          return "Vendedor";
      case profiles.administrador:
          return  "Administrador";
      case profiles.cliente:
        return  "Cliente";
      default:
          return "Vendedor";
    };
  }

  const handleLogout = async() =>{
    await logout();
  }



  return (
    <div>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link"
        >
          <Button variant="outline-primary">
            {`${user.displayName}`}
            <span className='d-none d-sm-inline'>{` ${user.displayLastName}`}</span>
            <small className='d-none d-sm-block'>({handleProfile()})</small> 
          </Button>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item onClick={ () => setModalShow(true)}>
              Cambiar Contraseña
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout} as={Link} to="/">
              Salir
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
        <ModalChangePassword modalShow={modalShow} setModalShow={setModalShow} changePassword={changePassword} />
      </Dropdown>
    </div>
  );
};

export default ProfileDropdown;

import { Suspense } from "react";
// components
// import LoadingScreen from "components/LoadingScreen";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Component {...props} />
    </Suspense>
  );
};

export default Loadable;

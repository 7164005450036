import React from 'react';
import axios from 'utils/axios';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Col, Dropdown, Row, Table } from 'react-bootstrap';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { faFileExport, faFilePdf, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import CardDropdownCustom from 'pages/common/components/CardDropdownCustom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toastNotification } from 'helpers/utils';
import moment from 'moment';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  data,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  globalFilter,
  setGlobalFilter,
  rowInfo,
  rowCount,
  title = '',
  subtitle = '',
  searchBar = true,
  btnExport = true,
}) => {
  const getDataFiltered = () => {
    const datosFiltrados = data.filter(fila => {
      const valoresFila = Object.values(fila); // Obteniendo todos los valores de las propiedades de la fila como un array
      return valoresFila.some(valor => { // some() para verificar si al menos uno de los valores cumple con el filtro global
        if (typeof valor === 'string') { // Si el valor es una cadena, puedes aplicar el filtro global directamente
          return valor.toLowerCase().includes(globalFilter.toLowerCase());
        }
        // Si el valor no es una cadena, conviértelo a cadena y aplica el filtro global
        return String(valor).toLowerCase().includes(globalFilter.toLowerCase());
      });
    });
    return datosFiltrados;
  }

  const exportTable = async ( fileExtension = '' ) => {
    
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/dataExports/${fileExtension}`;
    const header = headers.map( header => { 
      if( header.id === 'acciones' || header.id === 'accion' ) return null;
      return { label: header.Header, property: header.id } 
    }).filter( header => header !== null);
    let rows = globalFilter ? getDataFiltered() : data;

    // Formateando la data en caso de que contenga un HTML
    rows = rows.map( row => {
      header.forEach( header => {
        row[header.property] = ( typeof row[header.property] === 'object' && row[header.property].props.children )
                                ? row[header.property].props.children 
                                : row[header.property] !== undefined 
                                    ? row[header.property] 
                                    : '';
      });
      return row;
    } );
    
    axios.post(url, { header, rows, title, subtitle }, { responseType: 'blob'})
    .then(response => {
      // Enlace temporal para descargar el archivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = url;
      a.download = `${title.length > 0 ? title.replace(/ /g, '_') : 'document'}${moment().format('DDMMYYYY_HHmmss')}.${fileExtension}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch( error => {
      console.error(error)
      toastNotification('error', 'Ocurrio un error al intentar exportar la tabla');
    });

  };

  return (
    <>
      {/* CABECERAS DE LAS TABLAS */}
      <Row className='mb-2 d-flex flex-sm-row-reverse justify-content-between'>

        {/* Columna para el botón de exportación */}
        <Col xs={12} sm={4} className="d-flex justify-content-center mb-2 mb-md-0">
          { btnExport && (
            <CardDropdownCustom icon={faFileExport} text='Exportar' className="m-0 w-25">
              <div className="py-2">
                <Dropdown.Item onClick={() => exportTable('pdf')}>
                  Exportar a PDF
                  <FontAwesomeIcon icon={faFilePdf} className="text-danger ms-2 me-3" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => exportTable('xlsx')}>
                  Exportar a Excel
                  <FontAwesomeIcon icon={faFileExcel} className="text-primary ms-2 me-3" />
                </Dropdown.Item>
              </div>
            </CardDropdownCustom>
          )}
        </Col>

        {/* Columna para la barra de búsqueda */}
        <Col xs={12} sm={8}>
          { searchBar && (
            <AdvanceTableSearchBox setGlobalFilter={setGlobalFilter} globalFilter={globalFilter} />
          )}
        </Col>
      </Row>

      <SimpleBarReact autoHide={false}>
        <Table {...getTableProps(tableProps)}>
          <thead className={headerClassName}>
            <tr>
              {headers.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps(column.headerProps)
                  )}
                >
                  {column.render('Header')}
                  {column.canSort ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="sort desc" />
                      ) : (
                        <span className="sort asc" />
                      )
                    ) : (
                      <span className="sort" />
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr key={i} className={rowClassName} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    return (
                      <td
                        key={index}
                        {...cell.getCellProps(cell.column.cellProps)}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </SimpleBarReact>
    </>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object
};

export default AdvanceTable;

import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button, Row, Col} from 'react-bootstrap';

const ModalTipoEquipo = ({ showItemModal, setShowItemModal, idToUpdate, setIdToUpdate, typeEquipoToUpdate, setTypeEquipoToUpdate, crearTypeEquipo, updateTypeEquipo, atributos }) => {

  const [ name, setName ] = useState('');
  const [ description, setDescription ] = useState('');

  const [ validated, setValidated ] = useState(false);
  const [ atributosToInsert, setAtributosToInsert ] = useState([]);
  
  const [ atributosToInsertTest, setAtributosToInsertTest ] = useState({});
   

  useEffect( () => {

    if(showItemModal == false) {
        setAtributosToInsertTest({})
        setAtributosToInsert([]);
        return
    }

    let objToTest = {};
    atributos.forEach( (atributo) => {
        objToTest[atributo.id] = false;
    })
    
    //! Si estamos actualizando rellenamos los datos del formulario
    if( idToUpdate > 0 ) {
        if(typeEquipoToUpdate.equipmentTypeAttribute.length > 0){
            typeEquipoToUpdate.equipmentTypeAttribute.forEach( (atributo) => {
                let arrayWithAtributos = atributosToInsert
                arrayWithAtributos.push(atributo.attribute.id) 
                setAtributosToInsert(arrayWithAtributos)
                objToTest[atributo.attribute.id] = true;
            })
            setAtributosToInsertTest(objToTest);
        }
        setName(typeEquipoToUpdate.name)
        setDescription(typeEquipoToUpdate.description)
    }else{
        setName('')
        setDescription('')
        setAtributosToInsertTest(objToTest);
    }
    
  }, [showItemModal])

  const cambiarValor = ( id ) => {
    let objToTest = atributosToInsertTest
    objToTest[id] = !objToTest[id]
    setAtributosToInsertTest(objToTest);
  }


  const addDeleteAtributo = ( id ) => {
    let array = atributosToInsert
    if( atributosToInsert.includes(id) ){
        array = array.filter( idAtributo => idAtributo !== id )
    }else{
        array.push(id);
    };
    setAtributosToInsert(array)
  }


  const handleSubmit = ( e ) => {
    e.preventDefault();
    
    if( idToUpdate > 0){
        const typeEquipo = { idToUpdate, name, description, atributos: atributosToInsert};
        updateTypeEquipo(typeEquipo);
    }else{
        const typeEquipo = { name, description, atributos: atributosToInsert};
        crearTypeEquipo(typeEquipo);
    }
  }

  return (
    <>
    <Modal
        size='xl'
        show={showItemModal}
        centered
        onHide={() => {
            setIdToUpdate(0)
            setShowItemModal(false)
            setTypeEquipoToUpdate({})
            setAtributosToInsert([])

        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { (idToUpdate > 0) ? 'Actualizar Tipo de Equipo' : "Nuevo Tipo de Equipo" }
            </Modal.Title>
            <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => {
                setIdToUpdate(0)
                setShowItemModal(false)
                setTypeEquipoToUpdate({})
            }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                type="text"
                                name="nombre"
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingrese un nombre valido.
                            </Form.Control.Feedback>              
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="descripcion">
                            <Form.Label>Descripción:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                // id={'exampleForm'}
                                type="text"
                                as="textarea" 
                                rows={3} 
                                name="descripcion"
                                onChange={(e) => setDescription(e.target.value)}
                                value={description}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingrese descripción valida.
                            </Form.Control.Feedback>              
                        </Form.Group>

                        <Form.Group className=''>
                            <Row>
                                <Col sm={12} lg={5} className={'m-3'}  >
                                    
                                    <Card className={'p-3'}>
                                        <h5 className="mb-5">Atributos Dimensionales</h5>

                                        { atributos.map( atributo => (
                                            ( atributo.isDimensional) ? 
                                            <Form.Check 
                                                type={'checkbox'}
                                                key={atributo.id}
                                                id={atributo.id}
                                                label={atributo.description}
                                                defaultChecked={atributosToInsertTest[atributo.id]}
                                                onClick={( e ) => {
                                                    cambiarValor(Number(e.target.id))
                                                    addDeleteAtributo(Number(e.target.id))
                                                }}
                                            /> : ''
                                        ) ) }

                                    </Card>

                                </Col>

                                <Col sm={12} lg={6} className={'m-3'}>
                                        
                                    <Card className={'p-3'}>
                                        <h5 className="mb-5">Atributos Operacionales</h5>

                                        { atributos.map( atributo => (
                                            ( !atributo.isDimensional) ? 
                                            <Form.Check 
                                                type={'checkbox'}
                                                key={atributo.id}
                                                id={atributo.id}
                                                label={atributo.description}
                                                defaultChecked={atributosToInsertTest[atributo.id]}
                                                onClick={( e ) => {
                                                    cambiarValor(Number(e.target.id))
                                                    addDeleteAtributo(Number(e.target.id))
                                                }}
                                            /> : ''
                                        ) ) }
                                    </Card>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group>
                            <Button
                                variant="secondary"
                                className="m-2"
                                onClick={() => {
                                    setIdToUpdate(0)
                                    setShowItemModal(false)
                                    setTypeEquipoToUpdate({})
                                    setAtributosToInsert([])
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" variant="success" className="m-2">
                                Guardar
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
        </Modal>
    </>
  )
}

export default ModalTipoEquipo

export const columns = [
  {
    accessor: 'tipoCosto',
    Header: 'Ítem'
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'diaCostoFormat',
    Header: 'Día Costo'
  },
  {
    accessor: 'periodoDias',
    Header: 'Periodo'
  },
  {
    accessor: 'accion',
    Header: 'Acciones'
  }
];


export const columnsCliente = [
  {
    accessor: 'tipoCosto',
    Header: 'Ítem'
  },
  {
    accessor: 'valorFormat',
    Header: 'Valor'
  },
  {
    accessor: 'diaCostoFormat',
    Header: 'Día Costo'
  },
  {
    accessor: 'periodoDias',
    Header: 'Periodo'
  },
];

  export const data = [
    {
      item: 'Costo sello',
      costo: '2000',
      total: '4000'
    },        
    {
        item: 'Mano de obra',
        costo: '5000',
        total: '10000'
      }
    ];
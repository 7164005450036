import { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

import { faFilter } from '@fortawesome/free-solid-svg-icons';

import useAuth from "hooks/useAuth";
import { getAreaByIdUserAndLine, getCorporativosByIdUser, getFloorByIdUserAndCorporation, getLineByIdUserAndFloor } from "../services";
import IconButton from "components/common/IconButton";

const useFilters = (initialFilters) => {
    const [filters, setFilters] = useState(() => {
      return JSON.parse(sessionStorage.getItem('filtrosSeccionEquipos')) || initialFilters;
    });
  
    useEffect(() => {
      sessionStorage.setItem('filtrosSeccionEquipos', JSON.stringify(filters));
    }, [filters]);
  
    return [filters, setFilters];
  };

export const FiltrosComponent = ({ findEquiposByFilters }) => {

    const { user } = useAuth();
    const [configFilters, setConfigFilters] = useFilters({ idCorporativo: 0, idPlanta: 0, idLinea: 0, idArea: 0 });
 
    const [corporationList, setCorporationList] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [lineList, setLineList] = useState([]);
    const [areaList, setAreaList] = useState([]);

    const handleConfigFilters = useCallback((updatedFilters) => {
        setConfigFilters((prevFilters) => ({
          ...prevFilters,
          ...updatedFilters,
        }));
    }, [setConfigFilters]);


    //! Cambios en las estrucutras
    const handleChangeStructure = async ({ corporativo, planta, linea, area }) => {

        if (corporativo !== undefined) {
            handleConfigFilters({ idCorporativo: corporativo, idPlanta: 0, idLinea: 0, idArea: 0 });
            setFloorList([]);
            setLineList([]);
            setAreaList([]);
        
            setFloorList( await getFloorByIdUserAndCorporation(user.id, corporativo) );
            findEquiposByFilters({ userId: user.id, idCorporativo: corporativo });
        } else if (planta !== undefined) {
            handleConfigFilters({ idPlanta: planta, idLinea: 0, idArea: 0 });
            setLineList([]);
            setAreaList([]);
            
            setLineList( await getLineByIdUserAndFloor(user.id, planta) );
            findEquiposByFilters({ userId: user.id, idCorporativo: configFilters.idCorporativo, idPlanta: planta });
        } else if (linea !== undefined) {
            handleConfigFilters({ idLinea: linea, idArea: 0 });
            setAreaList([]);
            
            setAreaList( await getAreaByIdUserAndLine(user.id, linea) );
            findEquiposByFilters({ userId: user.id, idCorporativo: configFilters.idCorporativo, idPlanta: configFilters.idPlanta, idLinea: linea });
        } else if (area !== undefined) {
            handleConfigFilters({ idArea: area });
            findEquiposByFilters({ userId: user.id, idCorporativo: configFilters.idCorporativo, idPlanta: configFilters.idPlanta, idLinea: configFilters.idLinea, idArea: area });
        }
    };

    const initFilters = useCallback(async () => {
        if (configFilters.idArea) {
          handleConfigFilters({ idArea: configFilters.idArea });
        };
        if (configFilters.idLinea) {
          handleConfigFilters({ idLinea: configFilters.idLinea });
          setAreaList( await getAreaByIdUserAndLine(user.id, configFilters.idLinea) );
        };
        if (configFilters.idPlanta) {
          handleConfigFilters({ idPlanta: configFilters.idPlanta });
          setLineList( await getLineByIdUserAndFloor( user.id, configFilters.idPlanta ) );
        };
        if (configFilters.idCorporativo) {
          handleConfigFilters({ idCorporativo: configFilters.idCorporativo })
          setFloorList( await getFloorByIdUserAndCorporation(user.id, configFilters.idCorporativo) );
        };

        findEquiposByFilters({ userId: user.id, idCorporativo: configFilters.idCorporativo, idPlanta: configFilters.idPlanta, idLinea: configFilters.idLinea, idArea: configFilters.idArea });
    }, [configFilters]);

    const resetFilters = useCallback(() => {
        handleConfigFilters({ idCorporativo: 0, idPlanta: 0, idLinea: 0, idArea: 0 });
        findEquiposByFilters({ userId: user.id });
    }, []);


    //! INICIALIZANDO FILTROS DESDE SESSION STORAGE
    useEffect( async () => {
        initFilters();
        setCorporationList( await getCorporativosByIdUser(user.id) );
    }, [user.id]);

    return (
        <Card>
            <Card.Body className="h-md-100 ms-4 me-4">
                <Container fluid="md">

                    <Row className="mb-3">

                        <Col className="mb-3" xs={12} md={6}>

                            <Col>
                                <h5 className="text-primary">Corporativo</h5>
                            </Col>

                            <Col>

                                <Form.Select
                                    aria-label="Seleccionar Corporativo"
                                    onChange={(e) => handleChangeStructure({ corporativo: Number(e.target.value) })}
                                    value={configFilters.idCorporativo}
                                >
                                    <option key="corporativo0" value={0} disabled={corporationList.length > 0}>Seleccione Corporativo</option>
                                    {corporationList.map((corporation) => (
                                        <option key={corporation.id} value={corporation.id}>{corporation.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Col>

                        <Col xs={12} md={6}>

                            <Col>
                                <h5 className="text-primary">Planta</h5>
                            </Col>

                            <Col>
                                <Form.Select
                                    aria-label="Seleccionar Planta"
                                    onChange={(e) => handleChangeStructure({ planta: Number(e.target.value) })}
                                    value={configFilters.idPlanta}
                                >
                                    <option key="planta0" value={0} disabled={floorList.length > 0}>Seleccione Planta</option>
                                    {floorList.map((floor) => (
                                        <option key={floor.id} value={floor.id}>{floor.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Col>

                    </Row>

                    <Row className="mb-3">
                        <Col className="mb-3" xs={12} md={6}>

                            <Col>
                                <h5 className="text-primary">Línea</h5>
                            </Col>

                            <Col>
                                <Form.Select
                                    aria-label="Seleccionar Línea"
                                    onChange={(e) => handleChangeStructure({ linea: Number(e.target.value) })}
                                    value={configFilters.idLinea}
                                >
                                    <option key="line0" value={0} disabled={lineList.length > 0}>Seleccione Línea</option>
                                    {lineList.map((line) => (
                                        <option key={line.id} value={line.id}>{line.description}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                        </Col>

                        <Col xs={12} md={6}>
                            <Col>
                                <h5 className="text-primary">Área</h5>
                            </Col>

                            <Col>
                                <Form.Select
                                    aria-label="Seleccionar Área"
                                    onChange={(e) => handleChangeStructure({ area: Number(e.target.value) })}
                                    value={configFilters.idArea}
                                >

                                    <option key="area0" value={0} disabled={areaList.length > 0}>Seleccione Área</option>
                                    {areaList.map((area) => (
                                        <option key={area.id} value={area.id}>{area.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                        </Col>
                    </Row>

                    { configFilters.idCorporativo > 0 
                        && (
                            <div className="text-end">
                                <IconButton
                                    variant="falcon-primary"
                                    size="sm"
                                    icon={faFilter}
                                    transform="shrink-3"
                                    className="me-2 mb-2"
                                    onClick={resetFilters}
                                >
                                    Limpiar
                                </IconButton>
                            </div>
                        )
                    }

                </Container>
            </Card.Body>
        </Card>
    )
}

FiltrosComponent.propTypes = {
    findEquiposByFilters: PropTypes.func,
};
import React, { useState, useEffect } from 'react'
import axios from "utils/axios";
import { columnsPaises } from '../../data'
import { Card, Modal, Spinner, CloseButton, Button } from 'react-bootstrap'

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalDesabilitados = ({ showModalDisabled, setShowModalDisabled, enableData }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ dataDisabled, setDataDisabled ] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idPaisToEnable, setIdPaisToEnable] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showModalDisabled) {
        setReadyContent(false);
        getAllCountriesDisabled();
    };
  }, [showModalDisabled]);

  useEffect( () => {
    const msgActions = dataDisabled.map( data => {
        data.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                    setShowConfirmation(true);
                    setIdPaisToEnable(data.id)
                }}
            ></IconButton>
        ]
        return data
    } )
    setMensajesToTable(msgActions)
    setReadyContent(true);
  }, [dataDisabled]);

  const getAllCountriesDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountriesDisabled`
    try {
        const { data } = await axios.get(url);
        if(verifyResponseUnauthorizedAwait(data, 1)) return;
        if( data.statusCode == 200 ) setDataDisabled(data.data);
    } catch (error) {
        console.error('Ha ocurrido un error al consultar los paises deshabilitados');
        setReadyContent(true);
    }
};

  return (
    <>
        <Modal
            size='xl'
            show={showModalDisabled}
            centered
            className={ showConfirmation ? 'd-none' : '' }
            onHide={() => {
                setShowModalDisabled(false)
            }}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Datos Deshabilitados
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowModalDisabled(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( dataDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                        <AdvanceTableWrapper
                            columns={columnsPaises}
                            data={mensajesToTable}
                            sortable
                            pagination
                            perPage={5}
                        >
                        
                        <AdvanceTable
                            title='Países Deshabilitados'
                            subtitle='Tabla de Países Deshabilitados'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={dataDisabled.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) }
                </Card>
                )}
            </Modal.Body>
        </Modal>

        <Modal
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setIdPaisToEnable(0);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title>Habilitar País</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de querer habilitar el país?</Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={() => {
                    setShowConfirmation(false);
                    setIdPaisToEnable(0);
                }}
            >
                Cancelar
            </Button>
            <Button variant="success" 
                onClick={() => {
                    setShowConfirmation(false);
                    setShowModalDisabled(false);
                    enableData(idPaisToEnable);
                }}
            >
                Habilitar
            </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default ModalDesabilitados
import axios from 'utils/axios';
import { toastNotification } from "helpers/utils";

export const registryAccessToEquipment = async (idEquipo = 0) => {
  const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/registryAccessToEquipment`;
  try {
    const { data } = await axios.post(url, { idEquipo });
    if (data.statusCode !== 201) console.error('Error al registrar el acceso al equipo');
  } catch (error) {
    console.error("Error al intentar registrar el acceso al equipo");
  }
};

export const getEquiposOfUser = async (userId) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${userId}`);
    return (data.statusCode === 200) ? data.data : [];
  } catch (error) {
    toastNotification('Ocurrió un error al intentar obtener los equipos del usuario');
    return [];
  }
};

export const getEquipmentByFilter = async (filters) => {
  const { userId = 0, idCorporativo = 0, idPlanta = 0, idLinea = 0, idArea = 0 } = filters;
  let url = `${process.env.REACT_APP_EQUIPO_SERVICE}/equipment/getEquipmentByFilter/${userId}`;
  if (idCorporativo > 0) url += `/${idCorporativo}`;
  if (idPlanta > 0) url += `/${idPlanta}`;
  if (idLinea > 0) url += `/${idLinea}`;
  if (idArea > 0) url += `/${idArea}`;

  try {
    const { data } = await axios.get(url);
    return (data.statusCode === 200) ? data.data : [];
  } catch (error) {
    toastNotification('Ocurrió un error al intentar obtener los equipos del usuario');
    return [];
  }
};
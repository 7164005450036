import React, { useState } from 'react';
import axios from 'utils/axios';
import { Button, Form } from 'react-bootstrap';
import { toastNotification } from "helpers/utils";

const ContactForm = ({ setShowContactModal }) => {

  const [validated, setValidated] = useState(false);
  // State
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    mensaje: ''
  });


  // Handler
  const handleSubmit = async(e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setValidated(true);
      toastNotification('success', 'Muchas gracias por tu mensaje, te contactaremos a la brevedad!.', 4000)
      try {
        const { status } = await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/contacto/createContact/`, formData);
        if( status === 201 ){
          const url = `${process.env.REACT_APP_EMAILS_SERVICE}/sendEmails/sendEmailContacto`;
          await axios.post(url, { nombre: formData.nombre, email:formData.correo } )
        }
        setShowContactModal(false);
      } catch (error) {
        console.error(error)
      }
    }
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            placeholder='Ingrese Nombre'
            value={formData.nombre}
            name="nombre"
            onChange={handleFieldChange}
            type="text"
            autoComplete='off'
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Nombre.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Correo Electrónico</Form.Label>
          <Form.Control
            placeholder='Ingrese Correo'
            value={formData.correo}
            name="correo"
            onChange={handleFieldChange}
            type="email"
            autoComplete='off'
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Correo Electrónico Válido, ejemplo: example@example.cl.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Mensaje</Form.Label>
          <Form.Control
            placeholder='Ingrese Mensaje'
            value={formData.mensaje}
            name="mensaje"
            onChange={handleFieldChange}
            as="textarea"
            rows={3}
            autoComplete='off'
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Mensaje.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-4">
          <Button
            className="btn btn-success w-100"
            type="submit"
            disabled={
              !formData.nombre ||
              !formData.correo ||
              !formData.mensaje
            }
          >
            Enviar
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default ContactForm;

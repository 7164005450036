import React from "react";
import { ToastContainer } from "react-toastify";

import Router from "routes/router";
import LoadingScreen from "components/common/LoadingScreen";
import useLoadingContext from "hooks/useLoadingContext";


const App = () => {
  
  const { isLoading } = useLoadingContext();

  return (
    <>
      { isLoading && <LoadingScreen /> }
      <ToastContainer />
      <Router />
    </>
  )
};

export default App;

import { useState, useEffect } from 'react';
import { Card, CloseButton, Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import moment from 'moment';

import axios from 'utils/axios';
import { capitalizarString } from 'helpers';
import { toastNotification } from 'helpers/utils';
import { columnsBitacora } from './data';
import { TableWrapper } from './TableWrapper';

const getDatosBitacora = async ( idEntidad = 0, idData = 0 ) => {
    const url = ( idData === 0 ) 
                    ? `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getBitacoraByIdEntity/${idEntidad}`
                    : `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getBitacoraByIdEntityByIdData/${idEntidad}/${idData}`;

    try {
        const { data } = await axios.get(url);
        return ( data.statusCode === 200 ) ? data.data : [];
    } catch (error) {
        console.error(error);
        toastNotification('warning', 'Ocurrió un error al intentar los datos de la bitácora')
    };
};

const BitacoraData = ({ 
        title = 'Bitacora',
        titleToExport = 'Categoría de Mensajes',
        subtitleToExport = 'Tabla de Categoria de Mensajes',
        showBitacoraData,
        setShowBitacoraData,
        idData = 0,
        idEntidad = 0,
    }) => {

    const [ dataBitacora, setDataBitacora ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        ( showBitacoraData ) ? getDataBitacora() : resetState();
    }, [showBitacoraData]);


    const getDataBitacora = async () => {
        setIsLoading(true);
        const datos = await getDatosBitacora(idEntidad, idData);
        const datosAdaptados = datos.map( (bitacora, index) => {
            const fechaHora = moment(bitacora.fecha_transaccion).format('DD-MM-YYYY, HH:mm:ss a');
            bitacora.fecha_transaccion = fechaHora.slice(0, 10);
            bitacora.hora_transaccion = fechaHora.slice(12, 20);
            bitacora.nombreUser = `${bitacora.user.name} ${bitacora.user.lastName}`;
            bitacora.nombreEntidad = capitalizarString(bitacora.typeEntity.nombre);
            bitacora.id = `${bitacora.id}_${index}`;
            return bitacora;
        } );
        setDataBitacora(datosAdaptados);
        setIsLoading(false);
    };
  
    const resetState = () => {
        setShowBitacoraData(false);
        setDataBitacora([]);
    }

    return (
        <>
            <Modal
                size='xl'
                show={showBitacoraData}
                centered
                onHide={resetState}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                    </Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={resetState}
                    />
                </Modal.Header>

                <Modal.Body className="p-3">
                    <Card className='p-3'>
                        { isLoading
                            ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>
                            : (
                                <>
                                    { ( dataBitacora.length < 1 ) 
                                        ? <p className='text-center'>No se han encontrado datos</p> 
                                        : (
                                            <TableWrapper
                                                key={'tablaBitacora'}
                                                columnas={columnsBitacora}
                                                data={dataBitacora}
                                                elementosPorPagina={10}
                                                titleToExport={titleToExport}
                                                subtitleToExport={subtitleToExport}
                                            />
                                        )
                                    }
                                </>
                            )   
                        }
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
};

BitacoraData.propTypes = {
    title: PropTypes.string,
    titleToExport: PropTypes.string,
    subtitleToExport: PropTypes.string,
    showBitacoraData: PropTypes.bool.isRequired,
    setShowBitacoraData: PropTypes.func.isRequired,
    idData: PropTypes.number,
    idEntidad: PropTypes.number,
};

export default BitacoraData;
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Card, Dropdown, Modal, CloseButton, Form, Button } from "react-bootstrap";
import useAuth from "hooks/useAuth";
import axios from "utils/axios";
import { ModalConfirmacion } from "pages/common/components/ModalConfirmacion";
import { toastNotification } from "helpers/utils";
import useLoadingContext from "hooks/useLoadingContext";

export const MesaAyuda = () => {
  
    const { user } = useAuth();
    const { isLoading, setIsLoading } = useLoadingContext();

    const [isOpen, setIsOpen] = useState(false);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ validated, setValidated ] = useState(false); // VALIDADOR PARA EL FORMULARIO

    // Formulario
    const [ asunto, setAsunto ] = useState('');
    const [ mensaje, setMensaje ] = useState('');
    
    const cerrarModal = () => {
        setAsunto('');
        setMensaje('');
        setValidated(false);
        setIsOpen(false)
        setShowConfirmation(false);
    }    

    //! ENVIAR SOLICITUD AL BACKEND
    const addSoliMesaAyuda = async () => {
        setShowConfirmation(false);
        setIsLoading(true)
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/mesaAyuda/createSolicitudMesaAyuda`;
        try {
            const { data } = await axios.post(url, { asuntoSoli: asunto, mensajeSoli: mensaje });
            if( data.statusCode != 201 ) toastNotification('warning', "Ha ocurrido un error al intentar crear la solicitud a la mesa de ayuda");
            toastNotification('success', data.message);
            setIsOpen(false);
            sendEmailConfirmationToUser();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false)
            console.log(error);
            toastNotification('warning', 'Ha ocurrido un error al intentar crear la solicitud a la mesa de ayuda');
        }
    };

    //! NOTIFICAR VIA EMAIL AL USUARIO
    const sendEmailConfirmationToUser = async () => {
        const url = `${process.env.REACT_APP_EMAILS_SERVICE}/mesaAyudaEmails/sendEmailConfirmation`;
        try {
            await axios.post(url);
        } catch (error) {
            console.error("Ocurrio un error al intentar enviar correo electronico al usuario");
        }
    };

    //! VALIDAR EL FORMULARIO
    const validateForm = ( e ) => {
        e.preventDefault();
        
        const form = e.currentTarget;
        if (form.checkValidity() === false || customValidationTextArea() === false) {
            e.stopPropagation();
            setValidated(true);
            return;
        }

        setIsOpen(false);
        setShowConfirmation(true);
    }

    const customValidationTextArea = () => mensaje.length >= 30;



  return (
    <>
        { user.idTypeProfile != 3 && (

            <Dropdown navbar={true} as="li" onClick={() => setIsOpen(true)}> 
                <Dropdown.Toggle
                    bsPrefix="toggle"
                    as={'Link'}
                    to=""
                    className={classNames("px-0 nav-link cursor-pointer")}
                >
                    <FontAwesomeIcon icon="users" transform="shrink-6" className="fs-4" />
                </Dropdown.Toggle>

            </Dropdown>

        ) }

        { showConfirmation && 
            <ModalConfirmacion 
                key={1} 
                showModal={showConfirmation} 
                setShowModal={cerrarModal}
                confirmar={addSoliMesaAyuda}
                title="Solicitud a la mesa de ayuda" 
                msg="¿Desea enviar una solicitud de atencion a la mesa de ayuda?" 
            /> 
        }

        <Modal
            show={isOpen}
            centered
            size="lg"
            onHide={cerrarModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Mesa de Ayuda
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={cerrarModal}
                />
            </Modal.Header>

            
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                    
                        <Form noValidate validated={validated} onSubmit={validateForm}>


                            <Form.Group className="mb-3" controlId="asuntoFormControl">
                                <Form.Label>Asunto:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    name="Asunto"
                                    required
                                    pattern=".{6,}"
                                    value={asunto}
                                    onChange={(e) => setAsunto(e.target.value) }
                                />
                                <Form.Control.Feedback type="invalid">
                                    El asunto no puede estar vacio y debe contener como minimo 6 caracteres.
                                </Form.Control.Feedback>              
                            </Form.Group>


                            <Form.Group className="mb-3" controlId="mensajeFormControl">
                                <Form.Label>Mensaje:</Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    type="text"
                                    as='textarea'
                                    name="mensaje"
                                    value={mensaje}
                                    onChange={( e ) => setMensaje(e.target.value)}
                                    required
                                    minLength={30}
                                    isInvalid={ validated && !customValidationTextArea() }
                                />
                                <Form.Control.Feedback type="invalid">
                                    El mensaje no puede estar vacío y debe contener al menos 30 caracteres.
                                </Form.Control.Feedback>
                            </Form.Group>
                            

                            <Form.Group aria-disabled={true}>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    disabled={isLoading}
                                    onClick={cerrarModal}
                                >
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="success" className="m-2" disabled={isLoading}>
                                    Enviar
                                </Button>
                            </Form.Group>

                        </Form>

                    </Card.Body>

                </Card>
            </Modal.Body>
        </Modal>

    </>
  )
}

import React, { useState, useEffect } from "react";
import { Modal, CloseButton, Card, Spinner, Button } from "react-bootstrap";

import axios from "utils/axios";
import { verifyResponseUnauthorizedAwait } from "helpers/utils.js";

import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableFooter from "components/common/advance-table/AdvanceTableFooter";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import IconButton from "components/common/IconButton";

import { perfilesDisabledTable } from "../data.js";

const ModalPerfilesDisabled = ({
  showModalPerfilesDisabled,
  setShowModalPerfilesDisabled,
  enablePerfil,
}) => {

  const [perfilesToTable, setPerfilesToTable] = useState([]);
  const [perfilesDisabled, setPerfilesDisabled] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idPerfilToEnable, setIdPerfilToEnable] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showModalPerfilesDisabled) {
        setReadyContent(false);
        getPerfilesDisabled();
    };
  }, [showModalPerfilesDisabled]);

  useEffect(() => {
    const perfiles = perfilesDisabled.map((perfil) => {
      let perfilToReturn = {};
      perfilToReturn.name = perfil.name;
      perfilToReturn.description = perfil.description;
      perfilToReturn.acciones = [
        <IconButton
          title="Habilitar"
          variant="white"
          size="sm"
          icon="check"
          style={{ boxShadow: "none", width: "100%" }}
          onClick={() => {
            setShowConfirmation(true);
            setIdPerfilToEnable(perfil.id);
          }}
        ></IconButton>,
      ];
      return perfilToReturn;
    });
    setReadyContent(true);
    setPerfilesToTable(perfiles);
  }, [perfilesDisabled]);

  //! OBTENER PERFILES DESHABILITADOS
  const getPerfilesDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/getPerfilesDisabled`;
    try {
      const { data } = await axios.get(url);
      if(verifyResponseUnauthorizedAwait(data, 1)) return;
      if (data.statusCode === 200 || data.statusCode === 204) setPerfilesDisabled(data.data);
    } catch (error) {
      console.error("Algo salió mal al consultar los perfiles Deshabilitados");
      setReadyContent(true);
    }
  };

  return (
    <>
    <Modal
        size="xl"
        show={showModalPerfilesDisabled}
        centered
        className={ showConfirmation ? 'd-none' : '' }
        onHide={() => {
          setShowModalPerfilesDisabled(false);
        }}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Perfiles Deshabilitados
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => {
              setShowModalPerfilesDisabled(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                  <AdvanceTableWrapper
                    columns={perfilesDisabledTable}
                    data={perfilesToTable}
                    sortable
                    pagination
                    perPage={10}
                  >

                    <AdvanceTable
                      title="Perfiles Deshabilitados"
                      subtitle="Tabla de Perfiles Deshabilitados"
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: "fs--1 mb-0 overflow-hidden",
                      }}
                    />

                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={perfilesDisabled.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
              </Card.Body>
            </Card>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showConfirmation}
        onHide={() => {
          setShowConfirmation(false);
          setIdPerfilToEnable(0);
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Habilitar Perfil</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Está seguro de querer habilitar el perfil?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowConfirmation(false);
              setIdPerfilToEnable(0);
            }}
          >
            Cancelar
          </Button>
          <Button variant="success" 
            onClick={() => {
              setShowConfirmation(false);
              setShowModalPerfilesDisabled(false);
              enablePerfil(idPerfilToEnable);
            }}
          >
            Habilitar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalPerfilesDisabled;

import { createContext, useState } from "react";

export const LoadingContext = createContext({
    isLoading: false,
    setIsLoading: ( value ) => {},
});

export const LoadingProvider = ({ children }) => {

    const [ isLoadingState, setIsLoadingState ] = useState(false);

    const setIsLoading = ( value = false ) => { setIsLoadingState(value) }

    return (
        <LoadingContext.Provider value={{
            isLoading: isLoadingState,
            setIsLoading
        }}>
            { children }
        </LoadingContext.Provider>
    )
};
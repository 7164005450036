import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { Button, Card, CloseButton, Col, Modal, Row, Spinner } from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { columns, columns2, columns3, columnsPropuesta } from './data';
import IconButton from 'components/common/IconButton';
import moment from 'moment';
import GraficoReales from './grafico/GraficoReales';
import CrmStats from './ahorro/CrmStats';
import { graficar, getDaysBetweenDates, formatterPesoChile } from "helpers/utils";
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';

export const SituacionesRealesAnteriores = () => {
  const navigate = useNavigate();
  const { user } = useAuth()
  let params = useParams();

const [ situacionesRealesTerminadas, setSituacionesRealesTerminadas ] = useState([]);
  const [ showDetalleModal, setShowDetalleModal ] = useState(false);
  const [ situacionSelect, setSituacionSelect ] = useState();
  const [ readyContent, setReadyContent ] = useState(false);

  const [ fechaInicio, setFechaInicio] = useState();
  const [ fechaFinal, setFechaFinal] = useState();

  //CARGA DE COSTOS
  const [ costosSituacionAnterior, setCostosSituacionAnterior ] = useState([]);
  const [ costosPropuesta, setCostosPropuesta ] = useState([]);
  const [ costosSituacionReal, setCostosSituacionReal ] = useState([]);

  const [ graficarSituacionAnterior, setGraficarSituacionAnterior] = useState([]);
  const [ graficarPropuesta, setGraficarPropuesta] = useState([]);
  const [ graficarSituacionReal, setGraficarSituacionReal ] = useState([]);

  const [dataSituacionRealValorFecha, setDataSituacionRealValorFecha] = useState([]);
  const [dataPropuestaValorFecha, setDataPropuestaValorFecha] = useState([]);
  const [dataSituacionAnteriorValorFecha, setDataSituacionAnteriorValorFecha] = useState([]);

  const [ costoAnteriorTotal, setCostoAnteriorTotal ] = useState(0);


  const startResetApp = async () => {
    getSituacionRealTerminada();
  }

  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/cliente/inicio");
    if(hasAccess) startResetApp();
  }, [])

  //DATA SITUACIONES REALES TERMINADAS
  const getSituacionRealTerminada = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/getSituacionRealTerminadaByIdEquipment/${params.id}`;
    axios.get(url).then((response) => {
      if(response.data.statusCode === 200){
      const list = response.data.data;
      list.forEach(element => {
        element.fechaInicioFormat = moment(element.fecha_inicio).format('DD-MM-YYYY');
        element.fechaTerminoFormat = moment(element.fecha_termino).format('DD-MM-YYYY');
        element.accion = [
          <IconButton
            title="Ver"
            variant="white"
            size="sm"
            icon="eye"
            style={{ boxShadow: "none", width: "50%" }}
            onClick={ () => {
              setSituacionSelect(element);
            } }
          ></IconButton>,
          <IconButton
          title="Ver Detalle"
          variant="white"
          size="sm"
          icon="list"
          style={{ boxShadow: "none", width: "50%" }}
          onClick={ () => {
            setShowDetalleModal(true);
            setSituacionSelect(element);
          } }
        ></IconButton>
        ];
      });
      setSituacionesRealesTerminadas(response.data.data)
    }
    });
    setReadyContent(true)
  }
  
  useEffect(() => {
    if(situacionSelect != undefined){
      getCostosAnteriores(situacionSelect.id)
      getCostosPropuesta(situacionSelect.id_propuesta)
      getCostosSituacionReal(situacionSelect.id)
    }
  }, [situacionSelect])
  

  const getCostosAnteriores = async (id) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/costoAnteriorReal/getCostosByIdSituacion/${id}`;
    axios.get(url).then((response) => {
      if(response.data.statusCode === 200){
        const list = response.data.data;
        list.forEach((element) => {
          element.valorFormat = formatterPesoChile(element.valor);
          setCostoAnteriorTotal(costoAnteriorTotal + element.valor);
        })
      setCostosSituacionAnterior(list);
      }
    });
  }

  const getCostosPropuesta = async (id) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getCostosByIdPropuestaInstalada/${id}`
    axios.get(url).then((response) => {
      if(response.data.statusCode === 200){
        const list = response.data.data;
        list.forEach((element) => {
          element.valorFormat = formatterPesoChile(element.valor)
          element.tipoCostoPropuesta = element.id_tipo_costo.nombre
        })
      setCostosPropuesta(list);
      }
    });
  }

  const getCostosSituacionReal = async (id) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionReal/getCostosByIdSituacion/${id}`
    axios.get(url).then((response) => {
      if(response.data.statusCode === 200){
        const list = response.data.data;
        list.forEach((element) => {
          element.valorFormat = formatterPesoChile(element.valor)
          element.fechaFormat = moment(element.fechaCosto).format('DD-MM-YYYY')
        })
      setCostosSituacionReal(list);
      }
    });
  }

    //AGREGA FECHA A LOS ARREGLOS
    const agregarValorFecha = (costos, fechaInicio) => {
      const arrayValorFecha = [];
      costos.forEach((e, index) => {
        const inicio = moment(fechaInicio);
        const fecha = inicio.add(index, 'day').format('DD-MM-YYYY') 
        arrayValorFecha.push({valor: e, fecha: fecha})
      }) 
      return arrayValorFecha;
    }

    //FECHA INICIO Y TERMINO GRAFICAR SITUACION REAL TERMINADA
    useEffect(() => {
      if(situacionSelect){
        const termino = moment(situacionSelect.fecha_termino);
        const inicio = moment(situacionSelect.fecha_inicio);
        termino.set('hour', 4).set('minute', 0).set('second', 0)
        inicio.set('hour', 4).set('minute', 0).set('second', 0)
        const terminoPopuesta = inicio.add(situacionSelect.periodo, 'days').format();
        setFechaInicio(situacionSelect.fecha_inicio)
        if(terminoPopuesta < situacionSelect.fecha_termino){ // Si la situacion fue mas que la propuesta
          setFechaFinal(termino)
        }else{
          setFechaFinal(terminoPopuesta)
        }
      }
    },[situacionSelect])

    //GRAFICAR SITUACION REAL
  useEffect(() => {
    if(costosSituacionReal.length > 0){    
      const costosAlt = costosSituacionReal.map( costo => {
        const dia = getDaysBetweenDates(moment(situacionSelect.fecha_inicio).format(), moment(costo.fechaCosto).format()); 
        costo.diaCosto = dia;
        costo.periodo = 0;
        return costo
      })

      if(costosAlt[0] !== undefined){
        // const graficarCostosReales = graficar(situacionSelect.fecha_inicio, moment().add(1, 'day').format(), costosAlt) //Renato
        const graficarCostosReales = graficar(situacionSelect.fecha_inicio, moment(situacionSelect.fecha_termino).add(1, 'day').format(), costosAlt) //
        
        setGraficarSituacionReal(graficarCostosReales);
        const line = agregarValorFecha(graficarCostosReales, situacionSelect.fecha_inicio)
        setDataSituacionRealValorFecha(line);
      }
    }
  },[costosSituacionReal])


    //GRAFICAR SITUACION ANTERIOR TERMINADA
    useEffect(() => {
      if(costosSituacionAnterior.length > 0){
        const fechaTermino = moment(situacionSelect.fecha_termino).add(1, 'day').format();
        const graficarCostosAnteriores = graficar(situacionSelect.fecha_inicio, fechaTermino, costosSituacionAnterior);
        setGraficarSituacionAnterior(graficarCostosAnteriores);
        const line = agregarValorFecha(graficarCostosAnteriores, situacionSelect.fecha_inicio)
        setDataSituacionAnteriorValorFecha(line);
      }
    }, [costosSituacionAnterior])

    //GRAFICAR PROPUESTA TERMINADA
    useEffect(() => {
      if(costosPropuesta.length > 0){
        const inicio = situacionSelect.fecha_inicio;
        const termino = moment(inicio).add(situacionSelect.periodo, 'days').format();
        const graficarCostosPropuesta = graficar(inicio, termino, costosPropuesta);
        setGraficarPropuesta(graficarCostosPropuesta);
        const line = agregarValorFecha(graficarCostosPropuesta, situacionSelect.fecha_inicio)
        setDataPropuestaValorFecha(line);
      }
    }, [costosPropuesta])

  return (
    <>
    { !readyContent ?
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
      : (

        <div>
          <Row>
            <Col lg={6} className="mt-2 mb-4">
              <Card>
                <Card.Body>
                  <AdvanceTableWrapper
                    columns={columns}
                    data={situacionesRealesTerminadas}
                    sortable
                    pagination={true}
                    perPage={50}
                  >
                    <Row className="flex-end-center mb-3">
                      <Col>
                        <h5>Listado de Situaciones Reales Anteriores</h5>
                      </Col>
                    </Row>
                    <AdvanceTable
                      title='Situaciones Reales Anteriores'
                      subtitle='Tabla de Situaciones Reales Anteriores'
                      table
                      btnExport={false}
                      searchBar={false}
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        // striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                  </AdvanceTableWrapper>
                </Card.Body>
              </Card>
            </Col >
            <Col className="mt-2 mb-4">
                <CrmStats
                  situacionSelect={situacionSelect} 
                  situacionReal={graficarSituacionReal} 
                  situacionAnterior={graficarSituacionAnterior} 
                  propuesta={graficarPropuesta} 
                  />
            </Col >
          </Row>
          
          <Row>
            <Col lg={12} className="my-5 text-lg-start">
                <GraficoReales
                  situacionSelect={situacionSelect} 
                  situacionReal={graficarSituacionReal} 
                  propuesta={graficarPropuesta} 
                  situacionAnterior={graficarSituacionAnterior} 
                  fechaInicio={fechaInicio}
                  fechaTermino={fechaFinal}
                />
            </Col>
          </Row>
        </div>
      )
     }

      <div>{/* START MODAL DETALLES SITUACIONES */}
        <Modal
            size='xl'
            show={showDetalleModal}
            centered
            onHide={() => setShowDetalleModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Ver Detalle de Costos
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowDetalleModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                  <Row>
                    <Col lg={4} className="mt-2 mb-4">
                      <Card>
                        <Card.Body>
                          <AdvanceTableWrapper
                            columns={columns2}
                            data={costosSituacionAnterior}
                            sortable
                            pagination={true}
                            perPage={50}
                          >
                            <Row className="flex-end-center mb-3">
                              <Col>
                                <h5>Costos Situación Anterior</h5>
                              </Col>
                            </Row>
                            <AdvanceTable
                              table
                              searchBar={false}
                              btnExport={false}
                              headerClassName="bg-200 text-900 text-nowrap align-middle"
                              rowClassName="align-middle white-space-nowrap"
                              tableProps={{
                                bordered: true,
                                // striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                              }}
                            />
                            <AdvanceTableFooter
                              className={'mt-2'}
                              rowCount={costosSituacionAnterior.length}
                              table
                              navButtons
                            />
                          </AdvanceTableWrapper>
                          <h6 className='m-2'>Total Costos Inicio Mantención: {formatterPesoChile(Math.min(...graficarSituacionAnterior))}</h6>
                          <h6 className='m-2'>Total Costos Fin Mantención: {formatterPesoChile(Math.max(...graficarSituacionAnterior))}</h6>
                        </Card.Body>
                      </Card>
                    </Col >
                    <Col lg={4} className="mt-2 mb-4">
                      <Card>
                        <Card.Body>
                          <AdvanceTableWrapper
                            columns={columnsPropuesta}
                            data={costosPropuesta}
                            sortable
                            pagination={true}
                            perPage={50}
                          >
                            <Row className="flex-end-center mb-3">
                              <Col>
                                <h5>Costos Propuesta</h5>
                              </Col>
                            </Row>
                            <AdvanceTable
                              table
                              searchBar={false}
                              btnExport={false}
                              headerClassName="bg-200 text-900 text-nowrap align-middle"
                              rowClassName="align-middle white-space-nowrap"
                              tableProps={{
                                bordered: true,
                                // striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                              }}
                            />
                            <AdvanceTableFooter
                              className={'mt-2'}
                              rowCount={costosPropuesta.length}
                              table
                              navButtons
                            />
                          </AdvanceTableWrapper>
                          <h6 className='m-2'>Total Costos Inicio Mantención: {formatterPesoChile(Math.min(...graficarPropuesta))}</h6>
                          <h6 className='m-2'>Total Costos Fin Mantención: {formatterPesoChile(Math.max(...graficarPropuesta))}</h6>
                        </Card.Body>
                      </Card>
                    </Col >
                    <Col lg={4} className="mt-2 mb-4">
                      <Card>
                        <Card.Body>
                          <AdvanceTableWrapper
                            columns={columns3}
                            data={costosSituacionReal}
                            sortable
                            pagination={true}
                            perPage={50}
                          >
                            <Row className="flex-end-center mb-3">
                              <Col>
                                <h5>Costos Situación Real</h5>
                              </Col>
                            </Row>
                            <AdvanceTable
                              table
                              searchBar={false}
                              btnExport={false}
                              headerClassName="bg-200 text-900 text-nowrap align-middle"
                              rowClassName="align-middle white-space-nowrap"
                              tableProps={{
                                bordered: true,
                                // striped: true,
                                className: 'fs--1 mb-0 overflow-hidden'
                              }}
                            />
                            <AdvanceTableFooter
                              className={'mt-2'}
                              rowCount={costosSituacionReal.length}
                              table
                              navButtons
                            />
                          </AdvanceTableWrapper>
                          <h6 className='m-2'>Total Costos Inicio Mantención: {formatterPesoChile(Math.min(...graficarSituacionReal))}</h6>
                          <h6 className='m-2'>Total Costos Fin Mantención: {formatterPesoChile(Math.max(...graficarSituacionReal))}</h6>
                        </Card.Body>
                      </Card>
                    </Col >
                  </Row>

                </Card.Body>
              </Card>
            </Modal.Body>
            <Modal.Footer>
            <Button
              variant="success"
              onClick={() => setShowDetalleModal(false)}
            >
              Aceptar
            </Button>
          </Modal.Footer>
          </Modal>
      </div>{/* FIN MODAL DETALLES SITUACIONES */}
    </>
  )
}
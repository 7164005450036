import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconItem from 'components/common/icon/IconItem';
import { formatterPesoChile } from "helpers/utils";

const StatsItem = ({ situacionReal, propuesta, situacionAnterior, situacionSelect }) => {

  const [percent, setPercent] = useState(0);
  const [percentReal, setPercentReal] = useState(0);
  
  const valorMaxPropuesta = Math.max(...propuesta);
  const valorMaxSituacionAnterior = Math.max(...situacionAnterior)
  const valorMaxSituacionReal = Math.max(...situacionReal)
  
  useEffect(() => {
    calcularPercentAnterior( valorMaxPropuesta, valorMaxSituacionAnterior);
    calcularPercentReal(valorMaxSituacionReal, valorMaxSituacionAnterior )
  }, [propuesta, situacionAnterior, situacionReal])

  const calcularPercentAnterior = (prop, ant) => {
    let porcentaje
    if( prop > ant){
      porcentaje = ((ant * 100) / prop);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }else{
      porcentaje = ((prop * 100) / ant);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercent(Number(+porcentaje))
    }
  }

  const calcularPercentReal = (real, ant) => {
    let porcentaje
    if( real > ant){
      porcentaje = ((ant * 100) / real);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercentReal(Number(+porcentaje))
    }else{
      porcentaje = ((real * 100) / ant);
      porcentaje = (porcentaje - 100)
      porcentaje = (porcentaje * -1).toString()
      porcentaje = porcentaje.slice(0, 4)
      setPercentReal(Number(+porcentaje))
    }
  }

  return (
    <>
      { !situacionSelect ? (
        <h6>No Hay Ninguna Situación Real Anterior seleccionada</h6>
        ) : (
          <div>
            <h6>Ahorro</h6>
            <h6>{situacionSelect.nombre}</h6>
              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 mt-4 position-static"
              >
                <IconItem
                  tag="div"
                  icon='file'
                  bg={`soft-primary`}
                  color='primary'
                  size="sm"
                  iconClass="fs--2"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">Propuesta VS Situación Anterior</h6>
              </Flex>
              <Flex>
              <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{isNaN(percent) ? '0': percent}%</p>
                <div className="d-flex flex-column mb-4">
                { (valorMaxPropuesta > valorMaxSituacionAnterior) ? (
                        <FontAwesomeIcon
                          icon='caret-up'
                          className={`me-1 mb-0 text-danger`}
                        />
                        ) : (
                          <FontAwesomeIcon
                          icon='caret-down'
                          className={`me-1 mb-0 text-success`}
                        />
                      ) }
                      <p className="fs--2 mb-0 mt-0 text-nowrap">{isNaN(percent) ? (
                        '0 vs 0'
                      ) : (
                        `${formatterPesoChile(valorMaxPropuesta)} vs ${formatterPesoChile(valorMaxSituacionAnterior)}`
                      )}
                      </p>
                </div>
              </Flex>

              <Flex
                justifyContent="center"
                alignItems="center"
                className="mb-3 mt-4 position-static"
              >
                <IconItem
                  tag="div"
                  icon='file'
                  bg={`soft-success`}
                  color='success'
                  size="sm"
                  iconClass="fs--2"
                  className="me-2 shadow-none"
                />
                <h6 className="mb-0 flex-1">Real VS Situación Anterior</h6>
              </Flex>
              <Flex>
                <p className="font-sans-serif lh-1 mb-1 fs-4 pe-2">{isNaN(percentReal) ? '0': percentReal}%</p>
                <div className="d-flex flex-column mb-4">
                  { (valorMaxSituacionReal > valorMaxSituacionAnterior) ? (
                        <FontAwesomeIcon
                          icon='caret-up'
                          className={`me-1 mb-0 text-danger`}
                        />
                        ) : (
                          <FontAwesomeIcon
                          icon='caret-down'
                          className={`me-1 mb-0 text-success`}
                        />
                      ) }
                      <p className="fs--2 mb-0 mt-0 text-nowrap">{isNaN(percentReal) ? (
                        '0 vs 0'
                      ) : (
                        `${formatterPesoChile(valorMaxSituacionReal)} vs ${formatterPesoChile(valorMaxSituacionAnterior)}`
                      )}
                      </p>
                </div>
              </Flex>
          </div>
        )}
    </>
  );
};

const CrmStats = ({situacionReal, propuesta, situacionAnterior, situacionSelect}) => {
  return (
    <Card>
      <Card.Body>
        <Row>
              <StatsItem 
                situacionSelect={situacionSelect}
                situacionReal={situacionReal}
                propuesta={propuesta}
                situacionAnterior={situacionAnterior}   
              />
        </Row>
      </Card.Body>
    </Card>
  );
};

// StatsItem.propTypes = {
//   stat: PropTypes.shape({
//     amount: PropTypes.number.isRequired,
//     caret: PropTypes.string.isRequired,
//     caretColor: PropTypes.string.isRequired,
//     color: PropTypes.string.isRequired,
//     data: PropTypes.array.isRequired,
//     icon: PropTypes.string.isRequired,
//     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//     target: PropTypes.string.isRequired,
//     title: PropTypes.string.isRequired
//   })
// };

// CrmStats.propTypes = {
//   stats: PropTypes.arrayOf(PropTypes.shape(StatsItem.propTypes.stat))
// };

export default CrmStats;

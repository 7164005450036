import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "utils/axios";
import { toastNotification } from "helpers/utils";

export const NuevoItem = ({ data, change, setShowItemModal }) => {
  const [showPeriodo, setShowPeriodo] = useState(false);
  const [validated, setValidated] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [formData, setFormData] = useState({
    idTipoCosto: null,
    valor: "",
    descripcion: "",
    idSituacionAnterior: data.id,
    diaCosto: 0,
    periodo: 0,
  });

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_EQUIPO_SERVICE}/tipoCosto/getAllTypeCost`)
      .then((response) => {
        const list = response.data.data;
        setItemList(list);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/createCostoAnterior/`,
          formData
        )
        .then(() => {
          change(true);
          setShowItemModal(false);
        })
        .catch((err) => {
          console.error(err);
          toastNotification(
            "error",
            "Costo Anterior no se pudo agregar.",
            2000
          );
        });
    }
    setValidated(true);
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Ítem</Form.Label>
          <Form.Select required name="idTipoCosto" onChange={handleFieldChange}>
            <option value="">Seleccione ítem</option>
            {itemList.map((item) => (
              item.estado ? (
                <option name="tipoCosto" key={item.id} value={item.id} disabled={(item.estado === false) ? true : false}>
                  {item.nombre}
                </option>
              ) : ('')
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            Por favor seleccione un ítem.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            autoComplete="off"
            as="textarea"
            rows={3}
            name="descripcion"
            onChange={handleFieldChange}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Descripción.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            value={formData.valor}
            name="valor"
            onChange={handleFieldChange}
            type="number"
            min={1}
            pattern="^[0-9]+"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Valor, número entero y positivo sobre 0 (ej:
            1000)
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="m-4">
          <Form.Label>Tipo de costo:</Form.Label>
          <br />
          <div className="text-center ">
            <Form.Check
              inline
              label={`Costo Unitario`}
              name="type"
              type={"radio"}
              id={`fijo`}
              required
              defaultChecked={true}
              onChange={(e) => {
                setShowPeriodo(false);
              }}
            />
            <Form.Check
              inline
              label={`Costo Periódico`}
              name="type"
              type={"radio"}
              id={`periodico`}
              required
              onChange={(e) => {
                setShowPeriodo(true);
              }}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Por favor seleccionar Tipo de Costo.
          </Form.Control.Feedback>
        </Form.Group>

        <Row>
          <Col xl="6">
            <Form.Group className="mb-3">
              <Form.Label>Día Primer Costo (En Días):</Form.Label>
              <Form.Control
                autoComplete="off"
                value={formData.diaCosto}
                name="diaCosto"
                onChange={handleFieldChange}
                type="number"
                min={0}
                pattern="^[0-9]+"
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Día Costo, número entero y positivo (ej: 5)
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xl="6">
            {showPeriodo && (
              <Form.Group className="mb-3">
                <Form.Label>Periodicidad (En Días):</Form.Label>
                <Form.Control
                  autoComplete="off"
                  value={formData.periodo}
                  name="periodo"
                  onChange={handleFieldChange}
                  type="number"
                  min={1}
                  pattern="^[0-9]+"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Periodo, número entero y positivo sobre 0
                  (ej: 5)
                </Form.Control.Feedback>
              </Form.Group>
            )}
          </Col>
        </Row>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowItemModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

import axios from 'axios';
import { toastNotification } from 'helpers/utils';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_URL_BACK,
});


axiosInstance.interceptors.response.use(
    (res) => {
        return res;
    },  (err) => {
        const errorConfig = err.config;
        if (err.response) {
            if (err.response.status === 401 && err.response.data.message === 'Unauthorized' && !errorConfig._retry) {
                return new Promise((resolve, reject) => {
                    errorConfig._retry = true;
                    const uninterceptedAxiosInstance = axios.create({
                        baseURL: process.env.REACT_APP_AUTH_SERVICE,
                    });

                    uninterceptedAxiosInstance.post("/auth/refreshToken", {
                        "refresh_token": window.localStorage.getItem("refreshToken"),
                    },{
                        auth:{
                            username: process.env.REACT_APP_CLIENT_ID_JWT, // This is the client_id
                            password: process.env.REACT_APP_PASSWORD_CLIENT_JWT, // This is the client_secret
                        },
                    }).then((rs) => {
                        localStorage.setItem("accessToken", rs.data.access_token);
                        localStorage.setItem("refreshToken", rs.data.refresh_token);
                        axiosInstance.defaults.headers.common.Authorization = `Bearer ${rs.data.access_token}`;

                        errorConfig.__isRetryRequest = true;
                        errorConfig.headers.Authorization = `Bearer ${rs.data.access_token}`
                        // resolve(axiosInstance(errorConfig));
                        // resolve(() => axiosInstance(errorConfig)); // Solución: envolver en una función
                        resolve (axiosInstance({
                            ...errorConfig,
                            headers: {...errorConfig.headers, Authorization: `Bearer ${rs.data.access_token}`},
                            sent: true
                        }));
                    }).catch((error) => {
                        toastNotification('warning', 'La sesión ha expirado. Por favor, vuelva a iniciar sesión', 5000, true);
                        reject(error);
                    });
                })
            } else {
                return Promise.reject(err);
            }
        }
        return Promise.reject(err);
    }
);

export default axiosInstance;

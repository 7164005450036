import { toastNotification } from 'helpers/utils';
import axios from 'utils/axios';

export const getCorporativosByIdUser = async (userId) => {
  if (!userId) return [];
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/corporation/getCorporationByIdUser/${userId}`);
    return (data.statusCode === 200) ? data.data : [];
  } catch (error) {
    toastNotification('error', 'Ocurrió un error al intentar obtener los corporativos del usuario');
    return [];
  }
};

export const getFloorByIdUserAndCorporation = async ( userId, idCorporativo ) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/floor/getFloorByIdUserAndCorporation/${userId}/${idCorporativo}`);
    return ( data.statusCode === 200 ) ? data.data : [];
  } catch (error) {
    toastNotification('error', 'Ocurrió un error al intentar obtener las plantas del corporativo');
    return [];
  }
}

export const getLineByIdUserAndFloor = async ( userId, idPlanta ) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/line/getLineByIdUserAndFloor/${userId}/${idPlanta}`);
    return ( data.statusCode === 200 ) ? data.data : [];
  } catch (error) { 
    toastNotification('error', 'Ocurrió un error al intentar obtener las lineas de la planta');
    return [];
  }
}

export const getAreaByIdUserAndLine = async ( userId, idLinea ) => {
  try {
    const { data } = await axios.get(`${process.env.REACT_APP_CLIENTE_SERVICE}/area/getAreaByIdUserAndLine/${userId}/${idLinea}`);
    return ( data.statusCode === 200 ) ? data.data : [];
  } catch (error) { 
    toastNotification('error', 'Ocurrió un error al intentar obtener las areas de la linea');
    return [];
  }
}
import { useEffect, useState } from "react";
import { Card, Form, Modal, CloseButton, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';

import { toastNotification } from "helpers/utils";
import moment from "moment";
import { createNewAviso, updateAviso } from "../services";

export const ModalAddEditAviso = ({
    showAddEditModal,
    setShowAddEditModal,
    avisoToUpdate = {},
    setAvisoToUpdate,
    addAviso,
    editAviso,
    setIsLoading,
}) => {

    const [ aviso, setAviso ] = useState({ fechaInicio: null, fechaFin: null, estado: 1 });
    const [ validated, setValidated ] = useState(false);

    const handleSubmit = async ( e ) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        };

        if( moment(aviso.fechaInicio) > moment(aviso.fechaFin) ){
            toastNotification('error', 'La mantención no puede terminar antes de la fecha de comienzo')
            return;
        }

        setIsLoading(true)
        try {
            const response = avisoToUpdate.id
                ? await updateAviso(aviso)
                : await createNewAviso(aviso)

            if( response?.statusCode === 200 || response?.statusCode === 201 ){
                avisoToUpdate.id ? editAviso(response.data) : addAviso(response.data);
                onCloseModal();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const onChangeValue = ({ fechaInicio, fechaFin, estado }) => {
        
        const newsValues = {};

        if( fechaInicio > aviso.fechaFin ) newsValues.fechaFin = null;
        if( fechaInicio ) newsValues.fechaInicio = fechaInicio;
        if( fechaFin ) newsValues.fechaFin = fechaFin;
        if( estado ) newsValues.estado = estado;

        setAviso((prevValues) => ({
            ...prevValues,
            ...newsValues
        }));

    }

    const onCloseModal = () => {
        setAviso({ fechaInicio: null, fechaFin: null, estado: 1 });
        setShowAddEditModal(false);
        setValidated(false);
        setAvisoToUpdate({});
    };

    useEffect(() => {
        if( avisoToUpdate.id > 0 ){
            setAviso({ 
                id: avisoToUpdate.id,
                fechaInicio: moment(avisoToUpdate.fechaInicio, "DD/MM/YYYY - HH:mm").toDate(),
                fechaFin: moment(avisoToUpdate.fechaFin, "DD/MM/YYYY - HH:mm").toDate(), 
            })
        }
    }, [showAddEditModal]);

    return (
        <Modal
            show={showAddEditModal}
            centered
            onHide={onCloseModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { ( avisoToUpdate.id > 0 ) ? 'Actualizar Aviso de Mantención' : "Nuevo Aviso de Mantención" }
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={onCloseModal}
                />
            </Modal.Header>

            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Form.Group className="mb-4 me-4">
                                <Form.Label>Fecha de inicio mantención:</Form.Label>
                                <DatePicker
                                    selected={aviso.fechaInicio}
                                    onChange={ value => onChangeValue({ fechaInicio: new Date(value) })} // Maneja la selección de fecha y hora
                                    minDate={new Date()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    timeFormat="HH:mm"
                                    timeIntervals={15} // Intervalos de 15 minutos
                                    timeCaption="Hora"
                                    locale='es'
                                    className='form-control'
                                    placeholderText="Seleccione Fecha y Hora"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    La fecha y hora de inicio de mantencion es obligatoria
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group className="mb-4 me-4">
                                <Form.Label>Fecha de fin mantención:</Form.Label>
                                <DatePicker
                                    selected={aviso.fechaFin}
                                    onChange={ value => onChangeValue({ fechaFin: new Date(value) })} // Maneja la selección de fecha y hora
                                    minDate={aviso.fechaInicio ? aviso.fechaInicio : new Date()}
                                    showTimeSelect
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    timeFormat="HH:mm"
                                    timeIntervals={15} // Intervalos de 15 minutos
                                    timeCaption="Hora"
                                    locale='es'
                                    className='form-control'
                                    placeholderText="Seleccione Fecha y Hora"
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    La fecha y hora de fin de mantencion es obligatoria
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group aria-disabled={true}>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </Button>

                                <Button type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>

                        </Form>

                    </Card.Body>
                        
                </Card>
            </Modal.Body>
        </Modal>
    )
}

ModalAddEditAviso.propTypes = {
    showAddEditModal: PropTypes.bool.isRequired,
    setShowAddEditModal: PropTypes.func.isRequired,
    avisoToUpdate: PropTypes.shape({
        id: PropTypes.number,
        fechaInicio: PropTypes.string,
        fechaFin: PropTypes.string,
        estado: PropTypes.number,
    }),
    setAvisoToUpdate: PropTypes.func.isRequired,
    addAviso: PropTypes.func.isRequired,
    editAviso: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
}
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DraggableComponent = ({ elements = [], onItemsReordered }) => {

  const [items, setItems] = useState(elements);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    
    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderedItems);
    onItemsReordered(reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <Card
            {...provided.droppableProps}
            ref={provided.innerRef}
            // style={{ padding: '8px', backgroundColor: '#f8f9fa' }}
            style={{ padding: '8px',}}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    key={item.id}
                    style={{
                      userSelect: 'none',
                      padding: '16px',
                      marginBottom: '8px',
                      borderRadius: '4px',
                      ...provided.draggableProps.style,
                    }}
                  >
                    {item.title && <span className="fw-bold">{item.title}</span>}
                    {item.title && item.description && <br />}
                    {item.description && <span>{item.description}</span>}
                  </Card>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Card>
        )}
      </Droppable>
    </DragDropContext>
  );
};

// Define the propTypes for the items
DraggableComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ),
  onItemsReordered: PropTypes.func,
};

export default DraggableComponent;

import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { Col, Form, Row } from "react-bootstrap";

import PropTypes from 'prop-types';

import es from 'date-fns/locale/es';
registerLocale('es', es);

export const FiltrosComponent = ({ handleConfigFilters, configFilters, perfiles, cargos, }) => {

  return (
    <>
      <Row className='mb-3' >

        <Col md={3} sm={6} xs={12}>
        
          <Form.Label className='mb-0 me-2'>Perfil:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.perfilSelected}
            onChange={(e) => handleConfigFilters({ perfil: Number(e.target.value) })}
          >
            <option key={'perfil0'} value={0} disabled={true}>Seleccione Perfil</option>
            {perfiles.map((perfil) => (
              <option key={`${perfil.id}${perfil.name}`} value={perfil.id}>{perfil.name}</option>
            ))}
          </Form.Select>

        </Col>

        <Col md={3} sm={6} xs={12}>
        
          <Form.Label className='mb-0 me-2'>Cargo:</Form.Label>
          <Form.Select
            size="md"
            value={configFilters.cargoSelected}
            onChange={(e) => handleConfigFilters({ cargo: Number(e.target.value) })}
          >
            <option key={'cargo0'} value={0} disabled={true} >Seleccione Cargo</option>
            {cargos.map((cargo) => (
              <option key={`${cargo.id}${cargo.nombre}`} value={cargo.id}>{cargo.nombre}</option>
            ))}
          </Form.Select>

        </Col>

        <Col md={3} sm={6} xs={12}>

          <Form.Label className='mb-0 me-2'>Desde:</Form.Label>
          <DatePicker
            selected={configFilters.desde}
            onChange={date => handleConfigFilters({ dateDesde: new Date(date) })}
            maxDate={new Date()}
            dateFormat="dd/MM/yyyy"
            value={configFilters.desde}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
          />

        </Col>

        <Col md={3} sm={6} xs={12}>

          <Form.Label className='mb-0 me-2'>Hasta:</Form.Label>
          <DatePicker
            selected={configFilters.hasta}
            onChange={date => handleConfigFilters({ dateHasta: new Date(date) })}
            maxDate={new Date()}
            minDate={configFilters.desde}
            dateFormat="dd/MM/yyyy"
            value={configFilters.hasta}
            locale='es'
            className='form-control'
            placeholderText="Seleccione Fecha"
          />

        </Col>

      </Row>
    </>
  )

}

FiltrosComponent.propTypes = {
  handleConfigFilters: PropTypes.func,
  configFilters: PropTypes.shape({
    perfilSelected: PropTypes.number,
    cargoSelected: PropTypes.number,
    desde: PropTypes.string,
    hasta: PropTypes.string
  }),
  perfiles: PropTypes.arrayOf(PropTypes.object),
  cargos: PropTypes.arrayOf(PropTypes.object),
};

FiltrosComponent.defaultProps = {
  handleConfigFilters: () => {},
};
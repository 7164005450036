import React, { useEffect, useState } from 'react'
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap'
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import ReactTooltip from "react-tooltip";
import { calcularMedida } from 'helpers';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';

export const InformacionDimensional = () => {
  const navigate = useNavigate();
  const { user } = useAuth()
  let params = useParams();

  const [ attributes, setAttributes ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  const unidadesDeMedidas = [ 1, 2, 3, 4, 5, 6 ];

  useEffect(async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/vendedor/inicio");
    if( hasAccess ) getAtributos();
  }, [])


  const getAtributos = async () => {
    if( !user.profileDimensional ) return <Navigate to={"/"} />;
    if (user !== null) {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_EQUIPO_SERVICE}/equipment-value-attribute/getAttributeByIdEquipment/${params.id}`);
        setAttributes( data.data );
      } catch (error) {
        console.error("Ha ocurrido un error al intentar obtener los atributos del equipo");
      }
      setReadyContent(true);
    }
  }


  if( !user.profileDimensional ) return <Navigate to={"/"} />;

  return (
    <>
      <h4 className="text-primary mb-4">Información Dimensional</h4>
      { !readyContent ? <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> : (
        <div className="row ps-sm-3 d-flex justify-content-evenly px-3 align-middle">
          {attributes.map((atributo,index) => {
            if(atributo.attribute?.isDimensional && atributo.value){
              let mostrarToolTip = true;
              if( atributo.measureUnit == null || !unidadesDeMedidas.includes(atributo.measureUnit.id) ) mostrarToolTip = false;
              return(
                <div data-tip data-for={`tooltipDimensional-${index}`} key={atributo.id} className="row mb-4 col-lg-5 px-3 py-0 d-flex justify-content-between border rounded bg-200 shadow-sm">
                  <span className="col-sm-8 col-form-label col-form-label-sm" >{atributo.attribute?.description}</span>
                  <p className="col-sm-4 col-form-label col-form-label-sm text-end fw-bold">{atributo.value} {( !atributo.measureUnit ) ? '' : atributo.measureUnit?.descripcion }</p>
                    { mostrarToolTip && (
                      <ReactTooltip id={`tooltipDimensional-${index}`} className={'tooltipReact'}>
                        {calcularMedida(atributo)}
                      </ReactTooltip>
                    ) }
                </div>
              );
            }
          })}
          <div className="row mb-4 col-lg-5 px-3 py-0 d-flex justify-content-between"></div>
        </div>
      )}
    </>
  )
}

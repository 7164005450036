import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

const getOption = () => ({
  color: getColor('primary'),
  tooltip: {
    trigger: 'item',
    axisPointer: {
      type: 'none'
    },
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0
  },
  xAxis: {
    type: 'category',
    show: false,
    boundaryGap: false
  },
  yAxis: {
    show: false,
    type: 'value',
    boundaryGap: false
  },
  series: [
    {
      type: 'bar',
      symbol: 'none'
    }
  ],
  grid: { right: '0', left: '0', bottom: '0', top: '0' }
});

const BasicEChartsCliente = ({ echarts, options, ...rest }) => {

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={merge(getOption(), options)}
      {...rest}
    />
  );
};

BasicEChartsCliente.propTypes = {
  echarts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired
};

const tooltipFormatterPersonalizated =  (prefix = '') => params => {
  let tooltipItem = ``;
  if (Array.isArray(params)) {
    params?.forEach(el => {
      tooltipItem =
        tooltipItem +
        `<div className='ms-1'> 
      <h6 className="text-700">
      <div className="dot me-1 fs--2 d-inline-block" style="background-color:${
        el.borderColor ? el.borderColor : el.color
      }"></div>
      ${prefix}
      ${
          typeof el.value === 'object' ? el.value[1] : el.value
        }
      </h6>
      </div>`;
    });
  }
  return `<div>
            <p className='mb-2 text-600'>
              ${ params[0].axisValue }
            </p>
            ${tooltipItem}
          </div>`;
};


const getOptionsAdmin = (prefix, labelAxisX, labelAxisY) => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: {
      color: getColor('dark')
    },
    borderWidth: 1,
    formatter: tooltipFormatterPersonalizated(prefix),
    transitionDuration: 0,
    axisPointer: {
      type: 'none'
    }
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisLine: {
      lineStyle: {
        color: getColor('gray-300')
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      formatter: value => value.substring(0, 3),
      margin: 15
    },
    splitLine: {
      show: false
    },
    name: labelAxisX, // Etiqueta del eje X
    nameLocation: 'middle',
    nameGap: 35, // Ajuste la distancia de la etiqueta del eje
    nameTextStyle: {
      color: getColor('black'),
      padding: [0, 0, 0, 0]
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
      lineStyle: {
        type: 'dashed',
        color: getColor('gray-200')
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 30

    },
    axisTick: { show: false },
    axisLine: { show: false },
    min: 0,
    name: labelAxisY, // Etiqueta del eje Y
    nameLocation: 'middle',
    nameGap: 5, // Ajuste la distancia de la etiqueta del eje
    nameRotate: 90,
    nameTextStyle: {
      color: getColor('black'),
      padding: [0, 0, 0, 0],
    }
  },
  series: [
    {
      type: 'line',
      itemStyle: {
        color: getColor('white'),
        borderColor: getColor('primary'),
        borderWidth: 2
      },
      lineStyle: {
        color: getColor('primary')
      },
      showSymbol: false,
      symbol: 'circle',
      symbolSize: 10,
      smooth: false,
      emphasis: {
        scale: true
      },
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.5)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { right: '3%', left: '10%', bottom: '15%', top: '5%' } // Ajuste la distancia del gráfico
});

const BasicEChartsAdmin = ({ echarts, options, onClick, prefix, labelAxisX, labelAxisY, ...rest }) => {

  const handleClick = useCallback((params) => {
    if( onClick === undefined ) return;
    onClick(params.name);
  }, []);

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={merge(getOptionsAdmin(prefix, labelAxisX, labelAxisY), options)}
      onEvents={{ click: handleClick }}
      {...rest}
    />
  );
};

BasicEChartsAdmin.propTypes = {
  echarts: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  prefix: PropTypes.string,
  labelAxisY: PropTypes.string,
  labelAxisX: PropTypes.string,
};

BasicEChartsAdmin.defaultProps = {
  prefix: '',
  labelAxisX: '',
  labelAxisY: ''
};

// export default BasicECharts;
export { BasicEChartsAdmin, BasicEChartsCliente };
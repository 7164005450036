import React, { useState, useEffect } from 'react'
import axios from 'utils/axios';
import { Card, Row, Col, Form, Modal, Button, Spinner } from 'react-bootstrap'

import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';

import { toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import { capitalizarString, verifyAccess } from 'helpers';
import { columnsPerfiles } from './data.js'

import ModalPerfil from './components/ModalPerfil.js'
import ModalPerfilesDisabled from './components/ModalPerfilDisabled.js';
import BitacoraEntidad from './components/BitacoraEntity.js'
import BitacoraData from './components/BitacoraData.js'
import useLoadingContext from 'hooks/useLoadingContext.js';

const PerfilesAdmin = () => {
  const navigate = useNavigate();

  const {user} = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  const [ perfiles, setPerfiles ] = useState([]);
  const [ perfilesToTable, setPerfilesToTable ] = useState([]);

  const [ idBitacoraData, setIdBitacoraData ] = useState([]);

  //! MODALES
  const [ showDeleteModal, setShowDeleteModal ] = useState(false);
  const [ showModalPerfil, setShowModalPerfil ] = useState(false);
  const [ showModalPerfilesDisabled, setShowModalPerfilesDisabled ] = useState(false);
  const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false)
  const [ showBitacoraData, setShowBitacoraData ] = useState(false)

  const [ readyContent, setReadyContent ] = useState(false);
  
  const [ idPerfilToDelete, setIdPerfilToDelete ] = useState(0);
  const [ idToUpdate, setIdToUpdate ] = useState(0);
  const [ perfilToUpdate, setPerfilToUpdate ] = useState({});


  //? INICIANDO LA APP
  useEffect(() => {
    const hasAccess = verifyAccess(user, 'profilePerfilesAdmin', '/administrador/dashboard')
    if( !hasAccess ) navigate("/administrador/dashboard");
    if( hasAccess ) startResetApp();
  }, [])

  const getCheckbox = ( check ) => {
    let checkbox
    ( check == true ) ? checkbox = <Form.Check 
                                    type={'checkbox'}
                                    defaultChecked={true}
                                    disabled
                                  />
                      : checkbox = <Form.Check 
                                    type={'checkbox'}
                                    defaultChecked={false}
                                    disabled
                                  />

    return checkbox

  }

  //? ADAPTANDO LOS PERFILES A LA TABLA
  useEffect(() => {
    setPerfilesToTable([]);
    if(perfiles.length < 1) return

    const perfilesAdapted = perfiles.map( perfil => {
      const perfilToReturn = {};
      perfilToReturn.id = perfil.id;
      perfilToReturn.name = perfil.name;
      perfilToReturn.description = perfil.description;
      perfilToReturn.typePerfil = capitalizarString(perfil.tipoPerfil.nombre);
      ( perfil.dimensional === true ) ? perfilToReturn.dimensional = getCheckbox(true) : perfilToReturn.dimensional = getCheckbox(false);
      ( perfil.operational === true ) ? perfilToReturn.operational = getCheckbox(true) : perfilToReturn.operational = getCheckbox(false);
      ( perfil.report === true ) ? perfilToReturn.report = getCheckbox(true) : perfilToReturn.report = getCheckbox(false);
      ( perfil.message === true ) ? perfilToReturn.message = getCheckbox(true) : perfilToReturn.message = getCheckbox(false);
      ( perfil.economic === true ) ? perfilToReturn.economic = getCheckbox(true) : perfilToReturn.economic = getCheckbox(false);
      ( perfil.isPromather === true ) ? perfilToReturn.isPromather = 'Promather' : perfilToReturn.isPromather = 'Cliente';
      ( perfil.tecnica === true ) ? perfilToReturn.tecnica = getCheckbox(true) : perfilToReturn.tecnica = getCheckbox(false);
      perfilToReturn.acciones = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setIdToUpdate(perfil.id);
            setPerfilToUpdate(perfil);
            setShowModalPerfil(true);
          }}
        ></IconButton>,
        <IconButton
          title="Deshabilitar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "33%" }}
          onClick={() => {
            setIdPerfilToDelete(perfil.id)
            setShowDeleteModal(true)
          }}
        ></IconButton>,
        <IconButton
            title="Ver Bitácora"
            variant="white"
            size="sm"
            icon="book"
            style={{ boxShadow: "none", width: "33%" }}
            onClick={ () => {
              setIdBitacoraData(perfil.id)
              setShowBitacoraData(true);
            }}
          ></IconButton>
      ]
      return perfilToReturn
    } )
    setPerfilesToTable(perfilesAdapted)

  }, [perfiles])

  const startResetApp = () => {
    setPerfiles([]);
    setShowDeleteModal(false);
    setShowModalPerfil(false);
    getPerfilesEnabled();
  }

  //! OBTENER PERFILES HABILITADOS
  const getPerfilesEnabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/getAllPerfiles`;
    setReadyContent(false)
    try {
      const { data } = await axios.get(url)
      if(verifyResponseUnauthorizedAwait(data, 1)) return;
      if( data.statusCode == 200 ) setPerfiles(data.data)
      setReadyContent(true)
    } catch (error) {
      setReadyContent(true)
      console.error('Algo salió mal al consultar los perfiles habilitados');
    }
  }


  //! CREAR UN NUEVO PERFIL
  const createNewPerfil = async ( perfil ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/createNewPerfil`
    try {
      setIsLoading(true);
      const { data } = await axios.post( url, perfil );
      setIsLoading(false);
      if(verifyResponseUnauthorizedAwait(data, 2)){ return; };
      if( data.statusCode === 201 ) toastNotification('success', 'Perfil creado correctamente')
      if( data.statusCode !== 201 ) toastNotification('warning', "Algo salio mal al intentar crear el perfil")
      startResetApp()
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', "Algo salio mal al intentar crear el perfil")
      console.error('Algo salió mal al intentar crear un nuevo perfil');
    }
  
  }

  //! ACTUALIZAR UN PERFIL
  const updatePerfil = async ( id, perfil ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/updatePerfil/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url, perfil);
      setIsLoading(false);
      if(verifyResponseUnauthorizedAwait(data, 3)){ return };
      if( data.statusCode === 200 ) toastNotification('success', 'Perfil editado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', "Algo salio mal al intentar actualizar el perfil")
      startResetApp()
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', "Algo salio mal al intentar actualizar el perfil")
      console.error('Algo salió mal al actualizar el perfil')
    }
  }

  //! HABILITAR UN PERFIL
  const enablePerfil = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/enablePerfilById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.patch(url);
      setIsLoading(false);
      if(verifyResponseUnauthorizedAwait(data, 3)) return;
      if( data.statusCode === 200 ) toastNotification('success', 'Perfil editado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', "Algo salio mal al intentar habilitar el perfil");
      startResetApp()
    } catch (error) {
      setIsLoading(false);
      toastNotification('error', "Algo salio mal al intentar habilitar el perfil");
      console.error('Algo salió mal al habilitar el perfil')
    }
  }
  

  //! DESHABILITAR UN PERFIL
  const disablePerfil = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/perfiles/disablePerfilById/${id}`;
    try {
      setIsLoading(true);
      const { data } = await axios.delete(url);
      setIsLoading(false);
      if(verifyResponseUnauthorizedAwait(data, 4)){ return };
      if( data.statusCode === 200 ) toastNotification('success', 'Perfil deshabilitado correctamente')
      if( data.statusCode !== 200 ) toastNotification('warning', "Algo salio mal al intentar deshabilitar el perfil");
      startResetApp()
    } catch (error) {
      setIsLoading(false);
      console.error('Algo salió mal al deshabilitar el perfil')
      toastNotification('warning', "Algo salio mal al intentar deshabilitar el perfil");
    }
  }


  return (
    <>
        <Card.Body className="overflow-hidden p-lg-6">
          <Row className="justify-content-between">
            <Col lg={6} className="ps-lg-4 my-5text-lg-start">
              <h3 className="text-primary">Perfiles</h3>
            </Col>
          </Row>
          { !readyContent ? 
          <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
          : (
            <Card className="h-md-100">
              <Card.Body>
    
                <AdvanceTableWrapper
                  columns={columnsPerfiles}
                  data={perfilesToTable}
                  sortable
                  pagination
                  perPage={10}
                >
    
                  <Row className="flex-end-center mb-3">
                    <Col xs={12} lg={4} className='mx-auto mb-2'>
                      <div className='text-center mb-sm-2'>
                        <IconButton
                          variant="falcon-success"
                          size="sm"
                          icon="plus"
                          transform="shrink-3"
                          className="me-2"
                          onClick={() => {
                            setShowModalPerfil(true)
                          }}
                        >
                          Nuevo Perfil
                        </IconButton>
                      </div>
                    </Col>
                    <Col xs={12} lg={4} className='mx-auto mb-2'>
                      <div className='text-center mb-sm-2'>
                        <IconButton
                          variant="falcon-primary"
                          size="sm"
                          icon="book"
                          transform="shrink-3"
                          className="me-2"
                          onClick={() => {
                            setShowBitacoraEntidad(true)
                          }}
                        >
                          Bitácora General
                        </IconButton>
                      </div>
                    </Col>
                    <Col xs={12} lg={4} className='mx-auto mb-2'>
                      <div className='text-center mb-sm-2'>
                        <IconButton
                          variant="falcon-primary"
                          size="sm"
                          icon="trash"
                          transform="shrink-3"
                          className="me-2"
                          onClick={() => {
                            setShowModalPerfilesDisabled(true)
                          }}
                        >
                          Deshabilitados
                        </IconButton>
                      </div>
                    </Col>
                  </Row>
                  
                  <AdvanceTable
                    title='Perfiles'
                    subtitle='Tabla de Perfiles'
                    table
                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tableProps={{
                      bordered: true,
                      striped: true,
                      className: 'fs--1 mb-0 overflow-hidden'
                    }}
                  />
    
                  <div className="mt-3">
                    <AdvanceTableFooter
                      rowCount={perfiles.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
    
                </AdvanceTableWrapper>
              </Card.Body>
            </Card>
          ) }
        </Card.Body>

      {/* MODALES */}
        <ModalPerfil showModalPerfil={showModalPerfil} setShowModalPerfil={setShowModalPerfil} createNewPerfil={createNewPerfil} updatePerfil={updatePerfil}  idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} perfilToUpdate={perfilToUpdate} setPerfilToUpdate={setPerfilToUpdate}/>

        <ModalPerfilesDisabled showModalPerfilesDisabled={showModalPerfilesDisabled} setShowModalPerfilesDisabled={setShowModalPerfilesDisabled} enablePerfil={enablePerfil} />

        <BitacoraEntidad title={'Bitácora de Perfiles'} entidad={'perfil'} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad} />

        <BitacoraData title={'Bitácora del Perfil'} entidad={'perfil'} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData} idBitacoraData={idBitacoraData} />

      <div>
        <Modal
          show={showDeleteModal}
          onHide={() => setShowDeleteModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Deshabilitar Perfil</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro de deshabilitar el perfil de usuario?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger"
              disabled={isLoading}
              onClick={() => disablePerfil( idPerfilToDelete ) }
            >
              Deshabilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
    </>
  )
}

export default PerfilesAdmin
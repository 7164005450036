import React, { useEffect, useState } from 'react'
import { Modal, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsArea } from '../data'
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';


export const DisabledArea = ({ startResetApp }) => {

  
  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();
  const [ readyContent, setReadyContent ] = useState(false);
  
  const [ areasDisabled, setAreasDisabled] = useState([]);
  const [ enableArea, setEnableArea] = useState();
  const [ showEnableModal, setShowEnableModal] = useState(false);

  useEffect( () => getAllAreasDisabled(), []);

  const getAllAreasDisabled = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasDisabled`;
    axios.get(url).then((response) => {
      if( verifyResponseUnauthorizedFetch(response, 1) ) return;
      const list = response.data.data
      list.forEach((element) => {
        element.acciones = [
          <IconButton
          title="Habilitar"
          variant="white"
          size="sm"
          icon="check"
          style={{ boxShadow: "none", width: "100%" }}
          onClick={() => {
            setShowEnableModal(true)
            setEnableArea(element.id)
          }}
          ></IconButton>
        ]
      })
      setAreasDisabled(list);
      setReadyContent(true);
    }).catch(error => {
      setReadyContent(true);
      console.error(error);
      toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
    });
  }

  // HABILITAR AREA
  const handleEnable = async () => {
    setIsLoading(true);
    await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/area/enableArea/${enableArea}/${user.id}`)
    .then((response) => {
      setIsLoading(false);
      if( verifyResponseUnauthorizedFetch(response, 3) ) return;
      startResetApp()
      setReadyContent(false);
      getAllAreasDisabled()
      setShowEnableModal(false)
      toastNotification('success', 'Área habilitada correctamente')
    })
    .catch((err) => {
      setIsLoading(false);
      setReadyContent(true);
      toastNotification('error', 'Ocurrió un error al habilitar el área')
    })
  };

    
  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
      : (
        <>
      { !areasDisabled.length > 0 ? ( <h6>No hay Áreas Deshabilitadas</h6> ) 
      :
        (
          <div>
            <AdvanceTableWrapper
              columns={columnsArea}
              data={areasDisabled}
              sortable
              pagination
              perPage={10}
            >  
              <AdvanceTable
                title='Áreas Deshabilitadas'
                subtitle='Tabla de Áreas Deshabilitadas'
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0 overflow-hidden'
                }}
              />
              <div className="mt-3">
                <AdvanceTableFooter
                  rowCount={areasDisabled.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </div>
        )
      }

            {/* MODAL ENABLE */}
      <div>
        <Modal
          show={showEnableModal}
          onHide={() => setShowEnableModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Área</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere Habilitar el Área?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEnableModal(false)}
              disabled={isLoading}
            >
              Cancelar
            </Button>
            <Button 
              variant="danger" 
              onClick={handleEnable}
              disabled={isLoading}
            >
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
      )}
    </>
  )
}

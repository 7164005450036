import moment from "moment";
import { Badge, Card, CloseButton, Modal, Row } from "react-bootstrap"

export const MostrarDetalle = ({ show, setShow, solicitud }) => {
    
    const getEstadoSolicitud = (estado) => {
        if( estado.codigoEstado.trim() == 'open' ) return ( <Badge pill bg='warning'>{ estado.nombre_estado }</Badge> );
        if( estado.codigoEstado.trim() == 'closed' ) return ( <Badge pill bg='success'>{ estado.nombre_estado }</Badge> );
        if( estado.codigoEstado.trim() == 'in_progress' ) return ( <Badge pill bg='primary'>{ estado.nombre_estado }</Badge> );
    };

  return (
    <>
        { solicitud.id && (

            <Modal
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                size="lg"
                centered
            >
                <Modal.Header>
                    
                    <Modal.Title id="contained-modal-title-vcenter">
                        { 'Detalle de la Solicitud'}
                    </Modal.Title>

                    { <div className="ms-4">{ getEstadoSolicitud(solicitud.estado_solicitud) }</div>}

                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setShow(false)}
                    />
                </Modal.Header>

                <Modal.Body className="mt-4 px-5">
                        
                        <Row className="ml-2">
                            <div className="d-flex justify-content-around">
                                <p> Usuario: <span className="fw-bold">{ `${solicitud.usuarioSolicitud.name} ${solicitud.usuarioSolicitud.lastName}` }</span></p>
                                <p> Fecha: <span className="fw-bold">{ `${moment(solicitud.fecha_creacion).format('DD-MM-YYYY')}` }</span></p>
                            </div>
                        </Row>
                        
                        <Row>
                            <Card className="mx-auto py-3">

                                <div className="row mb-3">
                                    <div className="col-md-3 col-xs-12">
                                        <span className="fw-bold">Asunto:</span>
                                    </div>
                                    <div className="col-md-9 col-xs-12">
                                        { solicitud.asunto }
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <div className="col-md-3 col-xs-12">
                                        <span className="fw-bold">Mensaje:</span>
                                    </div>
                                    <div className="col-md-9 col-xs-12">
                                        { solicitud.mensaje }
                                    </div>
                                </div>
                            </Card>
                        </Row>


                        { solicitud.estado_solicitud.codigoEstado === 'closed' && (

                            <>
                                <Row className="ml-2 mt-5">
                                    <div className="d-flex justify-content-around">
                                        <p> Usuario: <span className="fw-bold">{ `${solicitud.usuarioAtencion.name} ${solicitud.usuarioAtencion.lastName}` }</span></p>
                                        <p> Fecha: <span className="fw-bold">{ `${moment(solicitud.fecha_atencion).format('DD-MM-YYYY')}` }</span></p>
                                    </div>
                                </Row>
                                
                                <Row>
                                    <Card className="mx-auto py-3">

                                        <div className="row mb-3">
                                            <div className="col-md-3 col-xs-12">
                                                <span className="fw-bold">Mensaje Resolución:</span>
                                            </div>
                                            <div className="col-md-9 col-xs-12">
                                                { solicitud.descripcion_resolucion }
                                            </div>
                                        </div>
                                    </Card>
                                </Row>
                            </>

                        ) }
                    
                </Modal.Body>
            </Modal>
        ) }
    
    </>
  )
}

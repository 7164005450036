import React, { useContext, useEffect, useState } from 'react'
import classNames from "classnames";
import useAuth from "../../hooks/useAuth";
import AppContext from "context/Context";
import { Link } from "react-router-dom";
import Section from 'components/common/Section';
import { Container, Navbar, Form, Button, Card, Image } from 'react-bootstrap';
import { topNavbarBreakpoint } from "config";
import FooterStandard from "components/pages/landing/FooterStandard";
import axios from 'utils/axios';
import { toastNotification, validatePass } from "helpers/utils";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import gif from '../../assets/gifs/animation_640_le0h19kc.gif'


const FirstLogin = () => {
  const navigate = useNavigate();
  const { logout } = useAuth()
  const {
    config: { isDark },
  } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  const [ validated, setValidated ] = useState(false);
  const [ newPassword, setNewPassword ] = useState('');
  const [ repeatNewPassword, setRepeatNewPassword ] = useState('');
  const [ buttonDisabled, setButtonDisabled ] = useState(false);

  const cerrarSesion = async () => {
    await logout();
  }

  const asignarPassword = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( newPassword !== repeatNewPassword ){
      toastNotification('error', 'Las contraseñas no coinciden');
      return
    }

    const isPassValid = validatePass( newPassword );
    if( !isPassValid ) return

    const url = `${process.env.REACT_APP_AUTH_SERVICE}/userPassword/updatePasswordFirstLogin`;
    try {
      setButtonDisabled(true);
      const { data } = await axios.post(url, { newPassword })
      if( data.statusCode === 200 ){
        toastNotification('success', 'Contraseña cambiada correctamente. Ya puede iniciar sesion con su nueva contraseña', 2500)
        setTimeout( async () => {
          cerrarSesion();
          navigate("/");
        }, 3000)
      }
      if( data.statusCode !== 200 ){
        setButtonDisabled(false);
        toastNotification('warning', 'Ha ocurrido un error al intentar asigar su nueva contraseña');
      }
    } catch (error) {
      toastNotification('error', 'Se ha producido un error al asignar tu nueva contraseña');
    }

  };

  return (
    <main style={{    
      backgroundColor: '#0b1727',
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight:'100vh'}}>
      <Navbar
      variant={isDark ? "light" : "dark"}
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames("navbar-standard navbar-theme", {
          "bg-100": !navbarCollapsed && isDark,
          "bg-dark": !navbarCollapsed && !isDark,
      })}
      >

      <ToastContainer/>
      <Container>
          <Navbar.Brand className="text-white dark__text-white" as={Link} to="/" style={{fontSize:'35px'}}>
          Promanet
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
          {/* <Navbar.Collapse className="scrollbar">
          <Nav />
          <LandingRightSideNavItem />
          </Navbar.Collapse> */}
      </Container>
      </Navbar>

      <Section bg="dark" className="pt-6 pb-6 pb-1 light d-flex justify-content-center h-75 align-items-center">
          
          <Card className="w-100 w-md-75 w-lg-50 mx-auto" style={{ backgroundColor: '#0b1727' }}>
              <Card.Header>
                  <div className='text-center'>
                    <Image src={gif} alt="Local GIF" /* fluid */ className="img-fluid w-50 mx-auto" />
                  </div>

                  <Card.Title className='text-center fs-3 mb-5 pt-2 text-white'>
                      Bienvenido a <span className='fw-bold fs-4'>Promanet 2.0</span>
                  </Card.Title>
                  <p className='text-white'>Por seguridad te recomendamos cambiar tu contraseña a una que solo tu sepas!. Recuerda, esta contraseña es privada y no se la debes proporcionar a nadie.</p>
              </Card.Header>
              <Card.Body>
                  
                  <Form noValidate validated={validated} onSubmit={asignarPassword}>

                      <Form.Group className="mb-3" controlId="exampleForm.newPassword">
                          <Form.Control 
                              placeholder='Nueva Contraseña'
                              autoComplete="off"
                              type="password"
                              style={{ color: "#d8e2ef", backgroundColor: "#0b1727" }}
                              name="newPassword"
                              required
                              value={newPassword}
                              onChange={(e) => {
                                  setNewPassword(e.target.value)
                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                              Por favor, ingrese la nueva contraseña
                          </Form.Control.Feedback>              
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="exampleForm.repeatNewPassword">
                          <Form.Control 
                              placeholder='Repita la nueva contraseña'
                              autoComplete="off"
                              type="password"
                              style={{ color: "#d8e2ef", backgroundColor: "#0b1727" }}
                              name="repeatNewPassword"
                              required
                              value={repeatNewPassword}
                              onChange={(e) => {
                                  setRepeatNewPassword(e.target.value)
                              }}
                          />
                          <Form.Control.Feedback type="invalid">
                              Por favor, repita la nueva contraseña
                          </Form.Control.Feedback>              
                      </Form.Group>

                      <Form.Group aria-disabled={true} className='mt-4 text-end'>
                          <Button type='submit' variant="success" className="m-2" disabled={buttonDisabled} >
                              Cambiar Contraseña
                          </Button>
                      </Form.Group>

                  </Form>
              </Card.Body>
          </Card>

      </Section>
      <FooterStandard />
    </main>
  )
}

export default FirstLogin
import React, { useState, useEffect } from 'react'
import { Card, CloseButton, Col, Form, Modal, Row, Button } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import axios from 'utils/axios';

import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import { columnsCostos } from './data';

import { NuevoItem } from './nuevoItem';

import { capitalizarString } from 'helpers';
import { formatterPesoChile, toastNotification } from 'helpers/utils';
import useLoadingContext from 'hooks/useLoadingContext';

export const NuevaPropuesta = ({ showNewPropuestaModal, setShowNewPropuestaModal, tiposCostos, tiposEstados, idEquipo, startResetApp }) => {

    registerLocale('es', es)
    const { isLoading, setIsLoading } = useLoadingContext();

    //! MODALES
    const [ showItemModal, setShowItemModal ] = useState(false);
    const [ validated, setValidated ] = useState(false);
    const [ date, setDate ] = useState('');


    //! DATOS DEL FORMULARIO
    const [ fechaInicio, setFechaInicio ] = useState('');
    const [ fechaTermino, setFechaTermino ] = useState('');
    const [ idEstadoPropuesta, setIdEstadoPropuesta ] = useState(1);
    const [ periodo, setPeriodo ] = useState(3);
    const [ nombre, setNombre ] = useState('');
    const [ descripcion, setDescripcion ] = useState('');
    const [ costosPropuesta, setCostosPropuestas ] = useState([]);
    
    const [ costosPropuestaActions, setCostosPropuestaActions ] = useState([]);


    //! DETECTA LOS CAMBIOS DEL STATE Y COPIA UN OBJETO Y LO ADAPTA PARA MOSTRARSE EN LA TABLA
    useEffect( async () => {
        async function saveNewsActions() {
            if (costosPropuesta.length < 0) return

            let costosActions = JSON.parse(JSON.stringify(costosPropuesta)).map( c => {
                let costo = tiposCostos.find(tipoCosto => tipoCosto.id === c.idTipoCosto) // Tipo de costo
                c.nameTipoCosto = capitalizarString(costo.nombre)
                c.valor= formatterPesoChile(c.valor);
                c.accion = [
                    <IconButton
                        title="Eliminar"
                        variant="white"
                        size="sm"
                        icon="trash-alt"
                        style={{ boxShadow: "none", width: "33%" }}
                        onClick={() => handleEliminarCosto(c)}
                    ></IconButton>,
                ];
                return c;
            })
            setCostosPropuestaActions(costosActions)
        }
        await saveNewsActions();

    }, [costosPropuesta] )

    //! FUNCION QUE CALCULA LA FECHA DE INICIO Y TERMINO DE UNA PROPUESTA
    const handleChangeDate = ( periodo = 3, date = moment() ) => {
        const inicio = moment(date).format()
        const termino = moment(inicio).add(periodo, 'months').format();
        setFechaInicio(inicio)
        setFechaTermino(termino)
    }

    //! FUNCION PARA ELIMINAR UN COSTO DEL OBJETO LOCAL
    function handleEliminarCosto( costo ) {
        const newCostos = costosPropuesta.filter( cost => cost.id !== costo.id );
        setCostosPropuestas(newCostos);
    }
    
    //! FUNCION AL PARA AGREGAR UNA NUEVA PROPUESTA CON SUS COSTOS
    const handleSubmit = async(e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return
        }

        costosPropuesta.forEach( c => {delete c.nameTipoCosto; delete c.id})

        const propuesta = {
            periodo: Number(periodo),
            descripcion,
            nombre,
            idEstadoPropuesta: Number(idEstadoPropuesta),
            idEquipment: Number(idEquipo),
            costosPropuesta
        }

        setIsLoading(true);
        const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/createNewPropuesta`
        const { data } = await axios.post(url, propuesta);
        if(data.statusCode == 200) {
            toastNotification('success','Propuesta creada correctamente')
            setShowNewPropuestaModal(false)
            startResetApp();
        }
        resetPropuesta();
        setIsLoading(false);
        
    };


    const resetPropuesta = () => {
        setIdEstadoPropuesta(1);
        setPeriodo(3);
        setNombre('');
        setDescripcion('');
        setCostosPropuestas([]);
    }


    //! FUNCION QUE AGREGA UN NUEVO ITEM AL OBJETO LOCAL
    const addNewItem = (item) => {
        item.id = uuidv4();
        setCostosPropuestas([...costosPropuesta, item])
    }

  return (
    <>
        <Modal
            size='xl'
            show={showNewPropuestaModal}
            centered
            onHide={() => setShowNewPropuestaModal(false)}
            className={ showItemModal ? 'd-none' : '' }
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nueva Propuesta
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowNewPropuestaModal(false)}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4"> 
                    
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row>
                                <Col md={12} lg={6}>
                                    <Card>
                                        <div className='m-3'>
                                            <h5>Datos de Propuesta</h5>
                                        </div>
                                        

                                        <Form.Group  className="m-4">
                                            <Form.Label>Estado:</Form.Label>
                                            <br/>
                                            <div className='text-center '>
                                                { tiposEstados.map( (estado) => (
                                                    (estado.id == 4 || estado.id == 5 || estado.estado == false ) ? '' : (
                                                    <Form.Check
                                                        defaultChecked={ (estado.id == 1) ? true : false }
                                                        key={estado.id}
                                                        inline
                                                        label={capitalizarString(estado.nombre)}
                                                        name="type"
                                                        type={'radio'}
                                                        id={estado.id}
                                                        value={estado.id}
                                                        required
                                                        onChange={(e) => {
                                                            setIdEstadoPropuesta(e.target.value)
                                                        }}
                                                    />
                                                    )
                                                )) }
                                            </div>
                                            <Form.Control.Feedback type="invalid">
                                                Por favor seleccione un estado.
                                            </Form.Control.Feedback>
                                        </Form.Group>

                                        <Form.Group className="m-4">
                                            <Form.Label>Nombre de la Propuesta:</Form.Label>
                                            <Form.Control 
                                                autoComplete="off"
                                                type="text" 
                                                name="nombre"
                                                required
                                                value={nombre}
                                                onChange={(e) => setNombre(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Debe ingresar un nombre a la propuesta.
                                            </Form.Control.Feedback>              
                                        </Form.Group>
                                        
                                        <Form.Group className="m-4">
                                            <Form.Label>Descripción:</Form.Label>
                                            <Form.Control 
                                                autoComplete="off"
                                                as="textarea" 
                                                rows={3} 
                                                name="descripcion"
                                                required
                                                value={descripcion}
                                                onChange={e => setDescripcion(e.target.value)}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Debe ingresar una descripcion a la propuesta.
                                            </Form.Control.Feedback>              
                                        </Form.Group>

                                            <Form.Group className="mb-4 m-4">
                                                <Form.Label>Duración (Días):</Form.Label>
                                                <Form.Control
                                                    autoComplete="off"
                                                    value={periodo}
                                                    name="periodo"
                                                    onChange={e => {
                                                        setPeriodo(e.target.value)
                                                    }}
                                                    type="number"
                                                    min={1}
                                                    pattern="^[0-9]+"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
                                                </Form.Control.Feedback> 
                                            </Form.Group>
                                        
                            
                                    </Card>
                                </Col>


                                {/* TABLA DE COSTOS */}
                                <Col>
                                    <Card>
                                        <div className='m-3'>
                                            <h5>Costos de la Propuesta</h5>
                                        </div>
                                        <Card.Body>
                                            <AdvanceTableWrapper
                                                columns={columnsCostos}
                                                data={costosPropuestaActions}
                                                sortable
                                                pagination
                                                perPage={50}
                                            >
                                                <Row className="flex-end-center mb-3">
                                                    <Col xs="auto" sm={8}>
                                                        <h6>Ítem Propuesta:</h6>
                                                    </Col>
                                                    <Col xs="auto" sm={4}>
                                                        <div id="new-document">
                                                            <IconButton
                                                            variant="falcon-success"
                                                            size="sm"
                                                            icon="plus"
                                                            transform="shrink-3"
                                                            className='me-2'
                                                            onClick={() => setShowItemModal(!showItemModal)}
                                                            >
                                                            Nuevo ítem
                                                            </IconButton>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {/* ESTA ES LA TABLA */}
                                                <AdvanceTable
                                                    title='Costos'
                                                    subtitle='Tabla de Costos'
                                                    searchBar={false}
                                                    btnExport={false}
                                                    table
                                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                                    rowClassName="align-middle white-space-nowrap"
                                                    tableProps={{
                                                        bordered: true,
                                                        striped: true,
                                                        className: 'fs--1 mb-0 overflow-hidden'
                                                    }}
                                                />
                                            </AdvanceTableWrapper>
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                            <Row className='text-end'>
                                <Form.Group className='text-end'>
                                    <Button
                                        className="m-2"
                                        variant="secondary"
                                        onClick={() => setShowNewPropuestaModal(false)}
                                        disabled={isLoading}
                                    >
                                        Cancelar
                                    </Button>
                                    <Button 
                                        className="m-2" 
                                        variant="success" 
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        Guardar
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>

        {/* MODAL PARA AGREGAR ITEM */}
        <Modal
            show={showItemModal}
            centered
            onHide={() => setShowItemModal(false)}
        >
            <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                Nuevo ítem
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowItemModal(false)}
            />
            </Modal.Header>
            <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">

                    <NuevoItem tiposCostos={tiposCostos}  setShowItemModal={setShowItemModal}
                    addNewItem={addNewItem}/>
                </Card.Body>
            </Card>
            </Modal.Body>
        </Modal>
    </> 
  )
}


// hooks
import useAuth from 'hooks/useAuth';
// routes
import RedirectByRole from "../components/RedirectByRole";

// ----------------------------------------------------------------------



export default function GuestGuard({ children }) {
    const { isAuthenticated } = useAuth();
    if (isAuthenticated) {
        return <RedirectByRole/>
    }

    return <>{children}</>;
}
import axios from 'utils/axios';
import { toastNotification } from "helpers/utils";

export const getAllAvisosMantenciones = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/avisoMantencion/getAllAvisos`;
    try {
        const { data } = await axios.get(url);
        return (data.statusCode === 200) ? data.data : [];
    } catch (error) {
        console.error(error);
        toastNotification('error', 'Ocurrió un error al intentar obtener los avisos de mantenciones del sistema')
        return [];
    }
};

export const getAllEstadosAvisosMantencion = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/estadoAvisoMantencion/getAllEstados`;
    try {
        const { data } = await axios.get(url);
        return (data.statusCode === 200) ? data.data : [];
    } catch (error) {
        toastNotification('error', 'Ocurrió un error al intentar obtener los estados de avisos de mantenciones')
        return [];
    }
};

export const createNewAviso = async ( newAviso ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/avisoMantencion/createNewAvisoMantencion`;
    try {
        const { data } = await axios.post(url, newAviso);
        return (data.statusCode === 201) ? data : {};
    } catch (error) {
        toastNotification('error', 'Ocurrió un error al intentar crear el nuevo aviso')
        return {};
    }
}

export const updateAviso = async ( aviso ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/avisoMantencion/updateAvisoMantencion/${aviso.id}`;
    try {
        const { data } = await axios.patch(url, aviso);
        return (data.statusCode === 200) ? data : {};
    } catch (error) {
        toastNotification('error', 'Ocurrió un error al intentar editar el aviso')
        return {};
    }
}

export const deleteAviso = async ( idToDelete ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/avisoMantencion/cancelarAvisoMantencion/${idToDelete}`;
    try {
        const { data } = await axios.delete(url);
        return (data.statusCode === 200) ? data : {};
    } catch (error) {
        toastNotification('error', 'Ocurrió un error al intentar cancelar el aviso de mantención')
        return {};
    }
}
import Camara from "components/camara/Camara";
import useAuth from "hooks/useAuth";
import React, { useEffect, useState } from "react";
import { Button, Card, CloseButton, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "utils/axios";

import { getExtension, toastNotification } from "helpers/utils";
import { TablaArchivos } from "pages/common/components";
import useLoadingContext from "hooks/useLoadingContext";

export const NuevoReporte = ({ startResetApp, showReportModal, setShowReportModal }) => {

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  let params = useParams();

  const [showCamaraModal, setShowCamaraModal] = useState(false);
  const author = `${user.displayName} ${user.displayLastName}`;
  const [inputKey, setInputKey] = useState(Date.now());

  const [ archivos, setArchivos ] = useState([]); // Lista de archivos
  
  const [ title, setTitle ] = useState(); // Titulo
  const [ report, setReport ] = useState(); // Descripcion
  const [ validated, setValidated ] = useState(false);


  useEffect( () =>{
    if( showReportModal ) return;
    setTitle('');
    setReport('');
    setArchivos([]);
    setValidated(false);
  }, [showReportModal]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const formRequest = new FormData();
    formRequest.append("title", title);
    formRequest.append("report", report);
    formRequest.append("id_equipment", params.id);
    formRequest.append("author", author);
    
    if (archivos) {
      for (const element of archivos) {
        formRequest.append("files", element.file);
      }
    }
    
    try {
      setIsLoading(true);
      const resp = await axios.post(`${process.env.REACT_APP_EQUIPO_SERVICE}/report/saveReport/`, formRequest);
      
      toastNotification('success', 'Reporte agregado correctamente', 2000)
      startResetApp();
      setShowReportModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if( error.response?.data?.statusCode === 413) toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
      return;
    }
  };

  const handleFilesChange = (e) => {
    setInputKey(Date.now());

    const filesRecibidos = [...e];
    let array = filesRecibidos.map((archivo) => {
      const nameExtension = getExtension(archivo.name);
      return {
        file: archivo,
        temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
        name_file: nameExtension[0],
        extension: nameExtension[1],
        date_file: Date.now(),
        size: archivo.size,
        user: { name: user.displayName, lastName: user.displayLastName },
      }
    })

    setArchivos([ ...archivos, ...array]);
  };

  const handlePhotoChange = ( file ) => {
    const nameExtension = getExtension(file.name);
    const newPhoto = {
      file: file,
      temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
      name_file: nameExtension[0],
      extension: nameExtension[1],
      date_file: Date.now(),
      size: file.size,
      user: { name: user.displayName, lastName: user.displayLastName },
    };

    setArchivos([...archivos, newPhoto]);
    setShowCamaraModal(false);
  }

  const handleTitleChange = (e) => {
    setTitle(e);
  };

  const handleReportChange = (e) => {
    setReport(e);
  };

  const handleDeleteFile = ( archivo ) => {
    setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
  }

  return (
    <>
      {/* MODAL PARA CREAR UN REPORTE */}
      <Modal 
        size="lg"
        show={showReportModal}
        centered
        className={ showCamaraModal ? 'd-none' : '' }
        onHide={() => setShowReportModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Nuevo Reporte
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowReportModal(false)}
          />
        </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">

                <Form noValidate validated={validated} onSubmit={handleSubmit} >
                  <Form.Group className="mb-3" controlId="formGroupName">
                    <Form.Label>Título:</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      required
                      onChange={(e) => handleTitleChange(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor ingresar Título.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Descripción:</Form.Label>
                  <Form.Control
                    onChange={(e) => handleReportChange(e.target.value)}
                    as="textarea"
                    name="report"
                    rows={3}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Descripción.
                  </Form.Control.Feedback>
                </Form.Group>

                <Tabs defaultActiveKey="uploadFile" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="uploadFile"
                    title="Subir Archivo"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                      <Form.Control
                        key={inputKey}
                        type="file"
                        multiple
                        onChange={(e) => handleFilesChange(e.target.files)} 
                      />
                    </Form.Group>
                  </Tab>
                  <Tab
                    eventKey="takePhoto"
                    title="Tomar Foto"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formPhotoMultiple" className="mb-3">
                      <Form.Label>Tome una fotografía:</Form.Label>
                      <Button
                        variant="success"
                        className="ms-4"
                        disabled={isLoading}
                        onClick={() => setShowCamaraModal(!showCamaraModal)}
                      >
                        Tomar Foto
                      </Button>
                    </Form.Group>
                    
                  </Tab>
                </Tabs>


                { archivos.length > 0 && (
                    <div className="my-4">
                      <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                    </div>
                  )
                }

                <Form.Group>
                  <Button
                    className="m-2"
                    variant="secondary"
                    onClick={() => setShowReportModal(false)}
                    disabled={isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button className="m-2" variant="success" type="submit" disabled={isLoading}>
                    Guardar
                  </Button>
                </Form.Group>
              </Form>

            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>


      <Modal
        size="lg"
        show={showCamaraModal}
        centered
        onHide={() => setShowCamaraModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Cámara</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowCamaraModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="fs--1 fw-normal">
              <Camara savePhoto={handlePhotoChange}/>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}


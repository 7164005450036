import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

// Ensure pdfjs gets the correct worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({ base64 }) => {

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);

    const base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const arrayBuffer = base64ToArrayBuffer(base64);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const goToNextPage = () => {
        setPageNumber((prevPage) => Math.min(prevPage + 1, numPages ?? 1));
    };
    
    const goToPreviousPage = () => {
        setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div style={{ minHeight: '790px', overflow: 'auto' }}>
            <Document
                className='w-100'
                file={{ data: arrayBuffer }}
                onLoadSuccess={onDocumentLoadSuccess}
                renderMode="canvas"
            >
                <Page 
                    pageNumber={pageNumber} 
                    renderAnnotationLayer={false} 
                    renderTextLayer={false} 
                    width={window.innerWidth <= 768 ? window.innerWidth * 0.9 : 600} // Ajuste dinámico del ancho
                />
            </Document>

            <div className='d-flex justify-content-between align-items-center mt-2'>

                {/* Botones de navegación */}
                <Button onClick={goToPreviousPage} disabled={pageNumber <= 1}>
                    Anterior
                </Button>

                <p>Página {pageNumber} de {numPages}</p>

                <Button onClick={goToNextPage} disabled={pageNumber >= (numPages ?? 1)}>
                    Siguiente
                </Button>

            </div>
        </div>
    );
};

export default PdfViewer;

import React, { useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import { v4 as uuid } from 'uuid'

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";

const videoConstraints = {
    facingMode: FACING_MODE_ENVIRONMENT
};

const Camara = ({ savePhoto }) => {
    
    const webcamRef = useRef(null);

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

    const foto = () => {
        let name = uuid();
        name = name.split('-');
        var captura = webcamRef.current.getScreenshot();
        const file = dataURLtoFile(captura, `${ name[0] }.jpeg`);
        
        savePhoto(file);
    };

    return (
        <>
            <Webcam 
                audio={false} 
                height='100%' 
                ref={webcamRef} 
                screenshotFormat='image/jpeg' 
                width={'100%'} 
                videoConstraints={videoConstraints}
            />
            <Row>
                <Col style={{alignItems:'center', display: 'flex', justifyContent: 'center'}}>
                    <Button variant='secondary' className='m-2'>Cancelar</Button>
                </Col>
                <Col style={{alignItems:'center', display: 'flex', justifyContent: 'center'}}>
                    <Button variant='success' className='m-2' onClick={foto}>Tomar Foto</Button>
                </Col>
            </Row>
        </>
    );
}

export default Camara;


import { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';

import axios from 'utils/axios';
import useLoadingContext from 'hooks/useLoadingContext';

import { toastNotification } from 'helpers/utils';
import { PageLayout } from 'layouts/PageLayout';
import { TableWrapper } from 'pages/common/components/TableWrapper';
import { columnsCategoriaMensajes } from 'pages/common/data/columnasTablas';
import { TableActionIcon, TableHeaderButton } from 'pages/common/components';
import BitacoraData from 'pages/common/components/Bitacora';
import { ModalCreateEditCategoria } from './ModalCreateEditCategoria';
import { ModalConfirmacion } from 'pages/common/components/ModalConfirmacion';
import { ModalCategoriasDeshabilitadas } from './ModalCategoriasDeshabilitadas';

const idEntidad = 29;
let idDatoBitacora = 0;
let idCategoriaToDelete = 0;

const getCategoriasMensajes = async () => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/getAllCategoriaMensajes`);
        return ( data.statusCode === 200 ) ? data.data : [];
    } catch (error) {
        console.error(error);
        toastNotification('warning', 'Ocurrio un error al intentar obtener las categorias de mensajes')
    };
};

const deleteCategoriaMensaje = async ( idCategoria ) => {
    try {
        const { data } = await axios.patch(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/disableCategoriaMensaje`, {idCategoria});
        ( data.statusCode === 200 )
            ? toastNotification('success', 'Categoría de mensaje deshabilitada correctamente')
            : toastNotification('warning', 'Ocurrio un error al intentar deshabilitar la categoria de mensaje');
        return ( data.statusCode === 200 ) ? data.data : {};
    } catch (error) {
        console.error(error);
        toastNotification('warning', 'Ocurrio un error al intentar obtener las categorias de mensajes')
    };
};

export const CategoriaMensaje = () => {

    const { setIsLoading } = useLoadingContext();

    const [ readyContent, setReadyContent ] = useState(false);
    const [ showModalBitacora, setShowModalBitacora ] = useState(false);
    const [ showModalCreateEdit, setShowModalCreateEdit ] = useState(false);
    const [ showModalDelete, setShowModalDelete ] = useState(false);
    const [ showModalDisabled, setShowModalDisabled ] = useState(false);

    const [ categoriaMensajes, setCategoriaMensajes ] = useState([]);
    const [ categoriaToUpdate, setCategoriaToUpdate ] = useState({})

    const createBtnActions = useCallback((categoria) => [
        <TableActionIcon key={'editar'} title='Editar' width='33%' icon='edit' onClick={() => { setCategoriaToUpdate(categoria); setShowModalCreateEdit(true); }} />,
        <TableActionIcon key={'deshabilitar'} title='Deshabilitar' width='33%' icon='trash-alt' onClick={() => { idCategoriaToDelete = categoria.id; setShowModalDelete(true); }} />,
        <TableActionIcon key={'bitacora'} title='Ver Bitácora' width='33%' icon='book' onClick={() => { idDatoBitacora = categoria.id; setShowModalBitacora(true); }} />,
    ], []);

    // OBTIENE LAS CATEGORIAS Y LAS ADAPTA PARA LA TABLA
    const getCategorias = useCallback(async () => {
        const categorias = await getCategoriasMensajes();
        const categoriasAdaptadas = categorias.map( categoria => {
            categoria.acciones = createBtnActions(categoria);
            return categoria;
        } );
        sortDataAndSet(categoriasAdaptadas);
        setReadyContent(true);
    }, []);

    const sortDataAndSet = ( categorias = [] ) => {
        setCategoriaMensajes( categorias.sort((a, b) => (a.descripcion < b.descripcion) ? -1 : (a.descripcion > b.descripcion) ? 1 : 0) );
    };

    const addCategoria = useCallback((categoria) => {
        categoria.acciones = createBtnActions(categoria);
        setCategoriaMensajes(prevCategorias => [...prevCategorias, categoria].sort(((a, b) => (a.descripcion < b.descripcion) ? -1 : (a.descripcion > b.descripcion) ? 1 : 0)));
    }, [createBtnActions, categoriaMensajes]);

    const updateCategoria = useCallback((categoria) => {
        categoria.acciones = createBtnActions(categoria);
        const categoriasActualizdas = categoriaMensajes.map( oldCategoria => oldCategoria.id === categoria.id ? categoria : oldCategoria );
        sortDataAndSet(categoriasActualizdas);
    }, [createBtnActions, categoriaMensajes]);

    const deleteCategoria = useCallback(async () => {
        setIsLoading(true);
        const categoriaDeleted = await deleteCategoriaMensaje(idCategoriaToDelete);
        setIsLoading(false);

        if( categoriaDeleted?.id === undefined ) return;

        const newCategorias = categoriaMensajes.filter( oldCategoria => oldCategoria.id !== categoriaDeleted.id );
        sortDataAndSet(newCategorias);

        idCategoriaToDelete = 0;
        setShowModalDelete(false);
    }, [idCategoriaToDelete]);

    useEffect(() => getCategorias(), []);


    return (
        <>
            <PageLayout key={'cateogiraMensajes'} title={'Categorías de Mensajes'}>
                { readyContent === false ? 
                    <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>
                : (
                    <Card className="h-md-100">
                        <Card.Body>

                            <TableWrapper
                                key={'Tabla'}
                                columnas={columnsCategoriaMensajes}
                                data={categoriaMensajes}
                                elementosPorPagina={10}
                                titleToExport={'Categoría de Mensajes'}
                                subtitleToExport={'Tabla de Categoria de Mensajes'}
                            >
                                <Row className="mb-3 text-center">
                                    <Col xs={12} md={4} lg={3} className='mx-auto mb-2'>
                                        { <TableHeaderButton key={'nuevaCategoria'} text='Nueva Categoría' icon='plus' type='success' onClick={() => setShowModalCreateEdit(true)} /> }
                                    </Col>
                                    <Col xs={12} md={4} lg={3} className='mx-auto mb-2'>
                                        { <TableHeaderButton key={'bitacoraGeneral'} text='Bitácora General' icon='book' type='primary' onClick={() => { setShowModalBitacora(true); idDatoBitacora = 0 }} /> }
                                    </Col> 
                                    <Col xs={12} md={4} lg={3} className='mx-auto mb-2'>
                                        { <TableHeaderButton key={'deshabilitados'} text='Deshabilitados' icon='trash' type='danger' onClick={() => setShowModalDisabled(true)} /> }
                                    </Col>
                                </Row>
                            </TableWrapper>
                            
                        </Card.Body>
                    </Card>
                )}
            </PageLayout>

            {/* MODALES */}
            {/* Modal de Bitacoras */}
            <BitacoraData 
                title={ idDatoBitacora > 0 ? 'Bitácora del Dato' : 'Bitacora General' }
                titleToExport={'Bitácora'}
                subtitleToExport={'Tabla de Bitácora'}
                showBitacoraData={showModalBitacora}
                setShowBitacoraData={setShowModalBitacora}
                idData={idDatoBitacora}
                idEntidad={idEntidad}
            />

            {/* Modal agregar-editar */}
            <ModalCreateEditCategoria 
                categoriaToUpdate={categoriaToUpdate}
                setCategoriaToUpdate={setCategoriaToUpdate}
                showCreateEditModal={showModalCreateEdit}
                setShowCreateEditModal={setShowModalCreateEdit}
                addCategoria={addCategoria}
                updateCategoria={updateCategoria}
            />

            {/* Modal Eliminar */}
            <ModalConfirmacion
                showModal={showModalDelete}
                setShowModal={setShowModalDelete}
                title='Deshabilitar Categoría de Mensaje'
                msg='¿Está seguro de querer deshabilitar la Categoría de Mensaje?'
                confirmar={deleteCategoria}
                btnVariant='danger'
                textDecline='Cancelar'
                textAcept='Deshabilitar'
            />

            <ModalCategoriasDeshabilitadas
                showModal={showModalDisabled}
                setShowModal={setShowModalDisabled}
                addCategoria={addCategoria}
            />
        </>
    )
}

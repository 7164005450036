import React, { useEffect } from 'react'
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';


export const Mantenciones = () => {
  const navigate = useNavigate();
  const { user } = useAuth()
  let params = useParams();
  
  useEffect( async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/cliente/inicio");
  }, []);

  return (
    <div>
      <h4 className="text-primary mb-4">Análisis Económico</h4>
      <div>
        <Nav variant="tabs" className='mb-2' fill={false}>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="anterior"
              eventKey="anterior"
            >Situación Anterior</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="propuestas"
              eventKey="propuestas"
            >Propuestas</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="real"
              eventKey="real"
            >Situación Real</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              as={Link}
              to="real-anterior"
              eventKey="real-anterior"
            >Situaciones Reales Anteriores</Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Outlet />
    </div>
  )
}

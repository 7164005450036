import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import { getSixWords } from 'helpers/utils';
import { FiltrosComponent } from '../components/FiltrosComponent';
import { getEquipmentByFilter, getEquiposOfUser, registryAccessToEquipment } from '../services/index';
import { TablaComponent } from '../components/TablaComponent';

export const EquiposPage = () => {

  const navigate = useNavigate();
  const { user } = useAuth();

  const [equipmentToTable, setEquipmentToTable] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  useEffect( async () => {
    const equipos = await getEquiposOfUser(user.id);
    setEquipmentList(equipos);
    setReadyContent(true);
  }, [user.id]);

  const findEquiposByFilters = async( filtros ) => {
    setEquipmentList(await getEquipmentByFilter(filtros))
  }

  useEffect(() => {
    const perfil = ( user.idTypeProfile === 1 ) ? 'vendedor' : 'cliente';
    const equiposToTable = equipmentList.map((equipo) => ({
      ...equipo,
      description: equipo.description.length > 0 ? getSixWords(equipo.description) : '',
      accion: (
        <IconButton
          title="Ver Detalle"
          variant="white"
          size="lg"
          icon="eye"
          className="me-2"
          onClick={() => {
            registryAccessToEquipment(equipo.id);
            navigate(`/${perfil}/equipo/${equipo.id}`);
          }}
          style={{ boxShadow: 'none', width: '100%' }}
        />
      ),
    }));
    setEquipmentToTable(equiposToTable);
  }, [equipmentList, navigate]);

  return (
    <Card.Body className="overflow-hidden p-lg-6">
      
      <Row className="justify-content-between">
        <Col lg={6} className="ps-lg-4 my-5text-lg-start">
          <h3 className="text-primary">Equipos</h3>
        </Col>
      </Row>
      
      {!readyContent ? (

        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: 200 }}>
          <Spinner animation="border" variant="success" />
        </div>
        
      ) : (
        <>

          <FiltrosComponent 
            key={'filtros'}
            findEquiposByFilters={findEquiposByFilters}
          />

          <TablaComponent key={'tableEquipos'} equipos={equipmentToTable} />
          
        </>
      )}
    </Card.Body>
  );
};
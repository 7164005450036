import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button, Row, Col, Accordion } from 'react-bootstrap';

const ModalPerfil = ({ showModalPerfil, setShowModalPerfil, createNewPerfil, updatePerfil, idToUpdate, setIdToUpdate, perfilToUpdate, setPerfilToUpdate }) => {

  const [ validated, setValidated ] = useState(false);

  const [ name, setName ] = useState((idToUpdate > 0) ? perfilToUpdate.name : '');
  const [ idProfile, setIdProfile ] = useState(1);
  const [ description, setDescription ] = useState('');

  const [ accesos, setAccesos ] = useState([]);

  const tiposPerfiles = [ { id: 1, nombre: "Vendedor" }, { id: 2, nombre: "Cliente" }, { id: 3, nombre: "Administrador" }, ]

  useEffect(() => {
    if( showModalPerfil == false ) {
        setIdToUpdate(0);
        setShowModalPerfil(false)
        setPerfilToUpdate({})
        return
    }
    
    if( idToUpdate > 0 ) {

        const arr = [];
        for (const prop in perfilToUpdate) {
            if (perfilToUpdate[prop] === true && prop !== "estado" && prop !== "isPromather") arr.push(prop);
        }

        setAccesos(arr);
        setName(perfilToUpdate.name)
        setDescription(perfilToUpdate.description)
        setIdProfile(perfilToUpdate.tipoPerfil.id)
    }
  }, [showModalPerfil])


  const resetState = () => {
    setName('')
    setDescription('')
  }
 
  // Marca-Descarmaca los checkbox y los agrega o los elimina del arreglo
  const handleCheckboxClick = ( nameCheck ) => {
    const marcado = ( accesos.includes(nameCheck) ) ? true : false;
    ( !marcado ) ? setAccesos([...accesos, nameCheck]) : setAccesos(accesos.filter(( name ) => name !== nameCheck ));
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
      if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    let perfil = {};
    accesos.forEach( (prop) => perfil[prop] = true );
    perfil.name = name;
    perfil.tipoPerfil = idProfile;
    perfil.description = description;

    if( idProfile == 1 || idProfile == 2 ){
        const filteredObj = Object.fromEntries(
            Object.entries(perfil).filter(([key, value]) => !key.includes("Admin"))
        );
        perfil = filteredObj;
    }

    if( idToUpdate > 0 ){
        updatePerfil( idToUpdate, perfil )
    }else{
        createNewPerfil(perfil);
    }
  }

  return (
    <>
    <Modal
        size='xl'
        show={showModalPerfil}
        centered
        onHide={() => {
            setIdToUpdate(0)
            setShowModalPerfil(false)
            setPerfilToUpdate({})
            resetState()
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? 'Actualizar Perfil' : 'Crear Nuevo perfil'}
            </Modal.Title>
            <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => {
                    setIdToUpdate(0)
                    setShowModalPerfil(false)
                    setPerfilToUpdate({})
                    resetState()
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group className="mb-3" controlId="InputText">
                                <Form.Label>Nombre:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    name="nombre"
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresar un nombre valida.
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="textArea">
                                <Form.Label>Descripción:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    as="textarea" 
                                    rows={3} 
                                    name="descripcion"
                                    onChange={(e) => setDescription(e.target.value)}
                                    value={description}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresar Descripción valida.
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group className="mb-3" controlId='tipoPerfil'>
                                <Form.Label>Tipo de Perfil:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name='typePerfil'
                                    value={idProfile}
                                    required
                                    onChange={(e) => setIdProfile(Number(e.target.value))}
                                > 
                                    <option value="" disabled>Seleccionar Perfil</option>
                                    { tiposPerfiles.map( perfil => ( <option key={perfil.id} value={perfil.id}>{ perfil.nombre }</option> ) ) }
                                    {/* <option value="1">Vendedor</option>
                                    <option value="2">Cliente</option>
                                    <option value="3">Administrador</option> */}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Por favor Seleccione un tipo de Perfil.
                                </Form.Control.Feedback>
                            </Form.Group>
                            
                            <Row className='my-3'>
                                <Accordion flush>

                                    {/* //? ACCESOS A DETALLE DE EQUIPOS  */}
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Accesos a Detalle de Equipos</Accordion.Header>
                                        <Accordion.Body className='d-flex'>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-dimensional'}
                                                    checked={accesos.includes('dimensional')}
                                                    onChange={() => handleCheckboxClick('dimensional')} 
                                                    />
                                                <Form.Label htmlFor="checkbox-dimensional">Dimensional</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-operacional'}
                                                    checked={accesos.includes('operational')}
                                                    onChange={() => handleCheckboxClick('operational')} 
                                                />
                                                <Form.Label htmlFor="checkbox-operacional">Operacional</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-reportes'}
                                                    checked={accesos.includes('report')}
                                                    onChange={() => handleCheckboxClick('report')} 
                                                />
                                                <Form.Label htmlFor="checkbox-reportes">Reportes</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-mensajes'}
                                                    checked={accesos.includes('message')}
                                                    onChange={() => handleCheckboxClick('message')} 
                                                />
                                                <Form.Label htmlFor="checkbox-mensajes">Mensajes</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-tecnica'}
                                                    checked={accesos.includes('tecnica')}
                                                    onChange={() => handleCheckboxClick('tecnica')}
                                                />
                                                <Form.Label htmlFor="checkbox-tecnica">Técnica</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-economica'}
                                                    checked={accesos.includes('economic')}
                                                    onChange={() => handleCheckboxClick('economic')} 
                                                />
                                                <Form.Label htmlFor="checkbox-economica">Económica</Form.Label>
                                            </Col>
                                            <Col className='d-flex gap-2'>
                                                <Form.Check 
                                                    type={'checkbox'}
                                                    id={'checkbox-estadistica'}
                                                    checked={accesos.includes('estadisticaEquipo')}
                                                    onChange={() => handleCheckboxClick('estadisticaEquipo')} 
                                                />
                                                <Form.Label htmlFor="checkbox-estadistica">Estadística</Form.Label>
                                            </Col>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/* //? ACCESO A USUARIOS  */}
                                    { ( idProfile === 3 ) ? (
                                        <>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Accesos a Usuarios</Accordion.Header>
                                                <Accordion.Body className='d-flex'>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-perfilesAdmin'}
                                                            checked={accesos.includes('perfilesAdmin')}
                                                            onChange={() => handleCheckboxClick('perfilesAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="'checkbox-perfilesAdmin'">Perfiles</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-usuariosAdmin'}
                                                            checked={accesos.includes('usuariosAdmin')}
                                                            onChange={() => handleCheckboxClick('usuariosAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="'checkbox-usuariosAdmin'">Usuarios</Form.Label>
                                                    </Col>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            
                                            {/* //? ACCESOS A CLIENTES  */}
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Accesos a Clientes</Accordion.Header>
                                                <Accordion.Body className='d-flex'>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-corporativosAdmin'}
                                                            checked={accesos.includes('corporativosAdmin')}
                                                            onChange={() => handleCheckboxClick('corporativosAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-corporativosAdmin">Corporativos</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-plantasAdmin'}
                                                            checked={accesos.includes('plantasAdmin')}
                                                            onChange={() => handleCheckboxClick('plantasAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-plantasAdmin">Plantas</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-lineaAdmin'}
                                                            checked={accesos.includes('lineaAdmin')}
                                                            onChange={() => handleCheckboxClick('lineaAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-lineaAdmin">Línea</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-areaAdmin'}
                                                            checked={accesos.includes('areaAdmin')}
                                                            onChange={() => handleCheckboxClick('areaAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-areaAdmin">Área</Form.Label>
                                                    </Col>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {/* //? ACCESOS A EQUIPOS  */}
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Accesos a Equipos</Accordion.Header>
                                                <Accordion.Body className='d-flex'>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-atributosAdmin'}
                                                            checked={accesos.includes('atributosAdmin')}
                                                            onChange={() => handleCheckboxClick('atributosAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-atributosAdmin">Atributos</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-equiposAdmin'}
                                                            checked={accesos.includes('equiposAdmin')}
                                                            onChange={() => handleCheckboxClick('equiposAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-equiposAdmin">Equipos</Form.Label>
                                                    </Col>
                                                    <Col className='d-flex gap-2'>
                                                        <Form.Check 
                                                            type={'checkbox'}
                                                            id={'checkbox-tipoEquipoAdmin'}
                                                            checked={accesos.includes('tipoEquipoAdmin')}
                                                            onChange={() => handleCheckboxClick('tipoEquipoAdmin')} 
                                                            />
                                                        <Form.Label htmlFor="checkbox-tipoEquipoAdmin">Tipos de Equipos</Form.Label>
                                                    </Col>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            {/* //? ACCESOS A MANTENEDORES  */}
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Accesos a Mantenedores</Accordion.Header>
                                                <Accordion.Body className=''>

                                                    <div className='row' >
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-industriasAdmin'}
                                                                    checked={accesos.includes('industriasAdmin')}
                                                                    onChange={() => handleCheckboxClick('industriasAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-industriasAdmin">Industrias</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-clasificacionAdmin'}
                                                                    checked={accesos.includes('clasificacionAdmin')}
                                                                    onChange={() => handleCheckboxClick('clasificacionAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-clasificacionAdmin">Clasificación</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-marcasAdmin'}
                                                                    checked={accesos.includes('marcasAdmin')}
                                                                    onChange={() => handleCheckboxClick('marcasAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-marcasAdmin">Marcas</Form.Label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-modelosAdmin'}
                                                                    checked={accesos.includes('modelosAdmin')}
                                                                    onChange={() => handleCheckboxClick('modelosAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-modelosAdmin">Modelos</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-tipoUnidadMedidaAdmin'}
                                                                    checked={accesos.includes('tipoUnidadMedidaAdmin')}
                                                                    onChange={() => handleCheckboxClick('tipoUnidadMedidaAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-tipoUnidadMedidaAdmin">Tipo Unidad Medida</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-cargosAdmin'}
                                                                    checked={accesos.includes('cargosAdmin')}
                                                                    onChange={() => handleCheckboxClick('cargosAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-cargosAdmin">Cargos</Form.Label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-tipoAreaAdmin'}
                                                                    checked={accesos.includes('tipoAreaAdmin')}
                                                                    onChange={() => handleCheckboxClick('tipoAreaAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-tipoAreaAdmin">Tipos de Áreas</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-tipoLineaAdmin'}
                                                                    checked={accesos.includes('tipoLineaAdmin')}
                                                                    onChange={() => handleCheckboxClick('tipoLineaAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-tipoLineaAdmin">Tipos de Líneas</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-tipoMensajesAdmin'}
                                                                    checked={accesos.includes('tipoMensajesAdmin')}
                                                                    onChange={() => handleCheckboxClick('tipoMensajesAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-tipoMensajesAdmin">Tipos de Mensajes</Form.Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className='row'>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-paisesAdmin'}
                                                                    checked={accesos.includes('paisesAdmin')}
                                                                    onChange={() => handleCheckboxClick('paisesAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-paisesAdmin">Países</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-regionesAdmin'}
                                                                    checked={accesos.includes('regionesAdmin')}
                                                                    onChange={() => handleCheckboxClick('regionesAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-regionesAdmin">Regiones</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-ciudadesAdmin'}
                                                                    checked={accesos.includes('ciudadesAdmin')}
                                                                    onChange={() => handleCheckboxClick('ciudadesAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-ciudadesAdmin">Ciudades</Form.Label>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-estadoPropuestaAdmin'}
                                                                    checked={accesos.includes('estadoPropuestaAdmin')}
                                                                    onChange={() => handleCheckboxClick('estadoPropuestaAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-estadoPropuestaAdmin">Estado Propuesta</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-tipoCostoAdmin'}
                                                                    checked={accesos.includes('tipoCostoAdmin')}
                                                                    onChange={() => handleCheckboxClick('tipoCostoAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-tipoCostoAdmin">Tipo Costo</Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-unidadesMedidaAdmin'}
                                                                    checked={accesos.includes('unidadesMedidaAdmin')}
                                                                    onChange={() => handleCheckboxClick('unidadesMedidaAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-unidadesMedidaAdmin">Unidad Medida</Form.Label>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-categoriaMensajeAdmin'}
                                                                    checked={accesos.includes('categoriaMensajeAdmin')}
                                                                    onChange={() => handleCheckboxClick('categoriaMensajeAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-categoriaMensajeAdmin">Categoría Mensaje</Form.Label>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-4 col-sm-6'>
                                                            <div className='d-flex gap-2'>
                                                                <Form.Check 
                                                                    type={'checkbox'}
                                                                    id={'checkbox-avisoMantencionAdmin'}
                                                                    checked={accesos.includes('avisoMantencionAdmin')}
                                                                    onChange={() => handleCheckboxClick('avisoMantencionAdmin')} 
                                                                    />
                                                                <Form.Label htmlFor="checkbox-avisoMantencionAdmin">Aviso de Mantenciones</Form.Label>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </>
                                    ) : '' }
                                    </Accordion>
                            </Row>

                            <Form.Group>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={() => {
                                        setIdToUpdate(0)
                                        setShowModalPerfil(false)
                                        setPerfilToUpdate({})
                                        resetState()
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>
                        </Row>

                    </Form>
                

                </Card.Body>

            </Card>
        </Modal.Body>
        </Modal>
    </>
  )
}

export default ModalPerfil

export const columns = [
  {
    accessor: 'id_equipment',
    Header: 'Id Equipo'
  },
  {
    accessor: 'name_equipment',
    Header: 'Nombre Equipo'
  },
  {
    accessor: 'issue',
    Header: 'Asunto'
  },
  {
    accessor: 'author',
    Header: 'Autor'
  },
  {
    accessor: 'date_message',
    Header: 'Fecha'
  },
  {
    accessor: 'accion',
    Header: 'Acciones'
  }
];

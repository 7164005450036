import React, { useState } from "react";

import { Button, Form, Row, Col } from "react-bootstrap";

export const NuevoItem = ({ tiposCostos, setShowItemModal, addNewItem }) => {
  const [validated, setValidated] = useState(false);

  const [idTipoCosto, setIdTipoCosto] = useState(0);
  const [descripcion, setDescripcion] = useState("");
  const [valor, setValor] = useState(0);
  const [diaCosto, setDiaCosto] = useState(0);
  const [periodo, setPeriodo] = useState(0);

  //? Para mostrar un tipo de costo u otro
  const [isPeriod, setIsPeriod] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const costoPropuesta = {
      valor,
      periodo,
      idTipoCosto,
      descripcion,
      diaCosto,
    };

    addNewItem(costoPropuesta);
    setShowItemModal(false);
  };


  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGroupGender">
          <Form.Label>Tipo de Costo</Form.Label>
          <Form.Select
            required
            name="tipoCosto"
            onChange={(e) => setIdTipoCosto(Number(e.target.value))}
          >
            <option value="">Seleccione ítem</option>
            {tiposCostos.map((item) => {
              let opcion;
              (item.estado)
                ? (opcion = (
                    <option name="tipoCosto" key={item.id} value={item.id} disabled={(item.estado === false) ? true : false}>
                      {item.nombre}
                    </option>
                  ))
                : (opcion = "");
              return opcion;
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {" "}
            Por favor seleccione un ítem.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Descripción:</Form.Label>
          <Form.Control
            autoComplete="off"
            type="text"
            as="textarea"
            rows={3}
            name="descripcion"
            onChange={(e) => setDescripcion(e.target.value)}
            value={descripcion}
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Descripción valida.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Valor:</Form.Label>
          <Form.Control
            autoComplete="off"
            value={valor == 0 ? "" : valor}
            name="costo"
            onChange={(e) => setValor(Number(e.target.value))}
            type="number"
            min={1}
            pattern="^[1-9]+"
            required
          />
          <Form.Control.Feedback type="invalid">
            Por favor ingresar Costo, número entero y positivo sobre 0 (ej:
            1000)
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="m-4">
          <Form.Label>Tipo de costo:</Form.Label>
          <br />
          <div className="text-center">
            <Form.Check
              inline
              label={`Costo Unitario`}
              name="type"
              type={"radio"}
              id={`fijo`}
              required
              defaultChecked={true}
              onClick={() => {
                setPeriodo(0);
                setIsPeriod(false);
              }}
            />
            <Form.Check
              inline
              label={`Costo Periodico`}
              name="type"
              type={"radio"}
              id={`periodico`}
              required
              onClick={() => setIsPeriod(true)}
            />
          </div>
          <Form.Control.Feedback type="invalid">
            Por favor seleccionar Estado.
          </Form.Control.Feedback>
        </Form.Group>

        {!isPeriod ? (
          <Form.Group className="mb-3">
            <Form.Label>Ingrese el día del costo Unitario:</Form.Label>
            <Form.Control
              autoComplete="off"
              value={diaCosto}
              name="periodo"
              onChange={(e) => setDiaCosto(Number(e.target.value))}
              type="number"
              min={0}
              pattern="^[0-9]+"
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor ingresar Duración, número entero y positivo sobre 0 (ej:
              5)
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Día Primer Costo (En Días):</Form.Label>
                <Form.Control
                  autoComplete="off"
                  value={diaCosto}
                  name="periodo"
                  onChange={(e) => setDiaCosto(Number(e.target.value))}
                  type="number"
                  min={0}
                  pattern="^[0-9]+"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Duración, número entero y positivo sobre 0
                  (ej: 5)
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Periodicidad (En Días):</Form.Label>
                <Form.Control
                  autoComplete="off"
                  value={periodo == 0 ? "" : periodo}
                  name="periodo"
                  onChange={(e) => setPeriodo(Number(e.target.value))}
                  type="number"
                  min={1}
                  pattern="^[0-9]+"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Por favor ingresar Duración, número entero y positivo sobre 0
                  (ej: 5)
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        )}

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowItemModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button} from 'react-bootstrap';
import { verifyResponseUnauthorizedAwait } from "helpers/utils";

import axios from 'utils/axios';

const ModalCiudades = ({ showCiudadModal, setShowCiudadModal, idToUpdate, setIdToUpdate, ciudadToUpdate, setCiudadToUpdate, createNewCiudad, updateCiudad, paises }) => {

  const [ nombre, setNombre ] = useState( ( idToUpdate > 0 ) ? ciudadToUpdate.nombre : '' )
  const [ regiones, setRegiones ] = useState([])
  const [ region, setRegion ] = useState(0)
  const [ pais, setPais ] = useState(0)
  const [ validated, setValidated ] = useState(false);


  useEffect(() => {
    if(showCiudadModal == false) return
    if( idToUpdate > 0 ){
        setNombre(ciudadToUpdate.descripcion)
        getCitiesByIdRegion(ciudadToUpdate.region.country.id)
        setPais(ciudadToUpdate.region.country.id)
        setRegion(ciudadToUpdate.region.id)
    }else{
        setNombre('')
        setPais(0)
    }
  }, [showCiudadModal])


  //! OBTENER CIUDADES POR ID DE LA REGION
  const getCitiesByIdRegion = async ( id ) => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getRegionsByIdCountry/${id}`;
    try {
      const { data } = await axios.get(url);
      if( verifyResponseUnauthorizedAwait(data, 1) ) return;
      if( data.statusCode == 200) setRegiones(data.data)
      if( data.statusCode !== 200) setRegiones([])
    } catch (error) {
      console.error('Ha ocurrido un error al consultar las ciudades por id de la region');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }
    
    if( idToUpdate > 0 ){
        const toUpdate = { id: idToUpdate, descripcion: nombre, id_region: Number(region), distrito: nombre }
        updateCiudad(toUpdate)
    }else{
        createNewCiudad({ descripcion: nombre, id_region: Number(region), distrito: nombre })
    }
  }



  return (
    <>
    <Modal
        show={showCiudadModal}
        centered
        onHide={() => {
            setShowCiudadModal(false)
            setIdToUpdate(0)
            setCiudadToUpdate({})
        }}
    >
        <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                { ( idToUpdate > 0 ) ? 'Actualizar Ciudad' : "Nueva Ciudad" }
            </Modal.Title>
            <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={ () => {
                    setShowCiudadModal(false)
                    setIdToUpdate(0)
                    setCiudadToUpdate({})
                }}
            />
        </Modal.Header>
        <Modal.Body className="p-0">
            <Card>
                <Card.Body className="fs--1 fw-normal p-4">
                
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>


                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Nombre:</Form.Label>
                            <Form.Control 
                                autoComplete="off"
                                type="text"
                                name="Nombre"
                                required
                                value={nombre}
                                onChange={(e) => {
                                    setNombre(e.target.value)
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor ingresar un nombre valido.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label> Seleccione un País</Form.Label>
                            <Form.Select
                                value={pais}
                                onChange={(e) => {
                                    setPais(Number(e.target.value))
                                    setRegion(0);
                                    getCitiesByIdRegion(e.target.value)
                                }}
                            >  
                                <option value={0} >Seleccione Opcion</option>
                                { paises.map( pais => 
                                    (<option key={pais.id} value={pais.id}>{pais.nombre}</option>)
                                 ) }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccione un paós valido
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="">
                            <Form.Label> Seleccione una Región</Form.Label>
                            <Form.Select
                                value={region}
                                onChange={(e) => {
                                    setRegion(Number(e.target.value))
                                }}
                            >  
                                <option value={0} >Seleccione Opcion</option>
                                { regiones.map( region => 
                                    (<option key={region.id} value={region.id}>{region.nombre}</option>)
                                 ) }
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                Por favor seleccione una región valida
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group aria-disabled={true}>
                            <Button
                                variant="secondary"
                                className="m-2"
                                onClick={() => {
                                    setShowCiudadModal(false)
                                    setIdToUpdate(0)
                                    setCiudadToUpdate({})
                                }}
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" variant="success" className="m-2">
                                Guardar
                            </Button>
                        </Form.Group>

                    </Form>

                </Card.Body>

            </Card>
        </Modal.Body>
    </Modal>
    </>
  )
}

export default ModalCiudades
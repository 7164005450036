import React from 'react'
import TablaMensajes from './TablaMensajes'
import useAuth from 'hooks/useAuth';
import { Navigate } from 'react-router-dom';

export const MensajesDetalle = () => {
  const { user } = useAuth();
  if( !user.profileMessage ) return <Navigate to={"/"} />;
  
  return (
    <>
      <h4 className="text-primary mb-4">Mensajes</h4>
      <TablaMensajes />
    </>
  )
}

import React, { useState, useEffect } from 'react'

import { Card, Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import axios from 'utils/axios';

import useAuth from 'hooks/useAuth';
import { useNavigate } from "react-router-dom";
import { verifyAccess } from 'helpers';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import IconButton from 'components/common/IconButton';

import { columnsRegiones } from '../data.js';
import { showAlertaTokenExpired, toastNotification, verifyResponseUnauthorizedAwait } from 'helpers/utils';
import Loading from './components/loading'

import BitacoraEntidad from '../components/BitacoraEntidad'
import BitacoraData from '../components/BitacoraData.js'
import ModalDesabilitados from './components/ModalDesabilitados.js';
import ModalRegiones from './components/ModalRegiones.js';
import useLoadingContext from 'hooks/useLoadingContext.js';


const PaisesAdmin = () => {
    const navigate = useNavigate();

    const {user} = useAuth();
    const { isLoading, setIsLoading } = useLoadingContext();

    //! Loading
    const [ loading, setLoading ] = useState(false);

    //! Bitacoras
    const [ idEntidad, setIdEntidad ] = useState(18);
    const [ idBitacoraData, setIdBitacoraData ] = useState([]);

    //! general
    const [ paises, setPaises ] = useState([]);
    const [ regiones, setRegiones ] = useState([]);
    const [ regionesToTable, setRegionesToTable ] = useState([]);
    const [ readyContent, setReadyContent ] = useState(false);

    const [ botonDisabled, setBotonDisabled ] = useState(false);

    //! Modales
    const [ showBitacoraEntidad, setShowBitacoraEntidad ] = useState(false);
    const [ showBitacoraData, setShowBitacoraData ] = useState(false);
    const [ showModalDelete, setShowModalDelete ] = useState(false)
    const [ showModalDisabled, setShowModalDisabled ] = useState(false)
    const [ showRegionModal, setShowRegionModal ] = useState(false)

    //! To CRUD
    const [ idToDelete, setIdToDelete ] = useState(0);
    const [ idToUpdate, setIdToUpdate ] = useState(0);
    const [ regionToUpdate, setRegionToUpdate ] = useState({});


    //? INICIANDO LA APP
    useEffect(() => {
      const hasAccess = verifyAccess(user, 'profileRegionesAdmin', '/administrador/dashboard')
      if( !hasAccess ) navigate("/administrador/dashboard");
      if( hasAccess ) startResetApp();
    }, [])

    useEffect( () => {
      if(regiones.length < 1) return
      const toTable = regiones.map( region => {
        let toReturn = {};
        toReturn.nombre = region.nombre
        toReturn.pais = region.country.nombre
        toReturn.acciones = [
          <IconButton
              title="Editar"
              variant="white"
              size="sm"
              icon="edit"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setRegionToUpdate(region);
                setIdToUpdate(region.id);
                setShowRegionModal(true);
              }}
          ></IconButton>,
          <IconButton
              title="Deshabilitar"
              variant="white"
              size="sm"
              icon="trash-alt"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={() => {
                setIdToDelete(region.id)
                setShowModalDelete(true)
              }}
          ></IconButton>,
          <IconButton
              title="Ver Bitácora"
              variant="white"
              size="sm"
              icon="book"
              style={{ boxShadow: "none", width: "33%" }}
              onClick={ () => {
                setShowBitacoraData(true)
                setIdBitacoraData(region.id)
              }}
          ></IconButton>
        ]
        return toReturn
      })
      setRegionesToTable(toTable);
    }, [regiones]);

    const startResetApp = () => {
      setLoading(false)
      setShowBitacoraData(false)
      setShowRegionModal(false)
      setShowModalDisabled(false)
      setShowBitacoraEntidad(false)
      setShowModalDelete(false)
      
      setIdToDelete(0)
      setIdToUpdate(0)
      Promise.all([ getAllPaises(), getAllRegiones() ])
        .then(([ regiones, paises ]) => {
          setReadyContent(true)
        })
        .catch(error => {
          toastNotification('warning', 'Error al intentar cargar la sección,  Por favor intentelo nuevamente');
          setReadyContent(true);
        })
    }

    const getAllPaises = async () => {
      setReadyContent(false)
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/pais/getAllCountries`
      try {
        const { data } = await axios.get(url)
        if( !data || data === undefined || data.statusCode === 401 ) {
          showAlertaTokenExpired()
          return;
        }
        if(data.statusCode == 200) setPaises(data.data)
        setReadyContent(true)
      } catch (error) {
        console.error('Ha ocurrido un error al consultar la bitacora');
        setReadyContent(true)
      }
    }
    
    const getAllRegiones = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/getAllRegions`;
        try {
            const { data } = await axios.get(url);
            if( data.statusCode == 200) setRegiones(data.data)
        } catch (error) {
            console.error('Ha ocurrido un error al consultar los paises')
        }
    }
    
    
    const createNewRegion = async ( region ) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/createNewRegion`;
      try {
        setIsLoading(true);
        const { data } = await axios.post(url, region)
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 2) ) return;
        if( data.statusCode == 201 ) toastNotification('success', 'Región creada correctamente')
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error al crear la región: ', error);
        toastNotification('error', 'Ocurrió un error al intentar crear la región')
      }
    }


    const updateRegion = async ( region ) => {
      const {id, nombre, idPais} = region;
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/updateRegion/${id}`;
      try {
        setIsLoading(true);
        const { data } = await axios.patch(url, {nombre, idPais})
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 3) ) return;
        if( data.statusCode == 200 ) toastNotification('success', 'Región actualizada correctamente')
        startResetApp();
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error al actualizar la región:',error);
        toastNotification('error', 'Ocurrió un error al intentar actualizar la región')
      }
    }


    const enableRegion = async (id) => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/enableRegion/${id}`;
      try {
        setIsLoading(true);
        const { data } = await axios.patch(url)
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 3) ) return;
        if( data.statusCode == 200 ) toastNotification('success', 'Región habilitada correctamente')
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error al habilitar la región:',error);
        toastNotification('error', 'Ocurrió un error al intentar habilitar la región')
      }
    }

    
    const disableRegionById = async () => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/region/disableRegion/${idToDelete}`;
      try {
        setIsLoading(true);
        const { data } = await axios.delete(url)
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 4) ) return;
        if(data.statusCode == 200 ) toastNotification('success', 'Región deshabiitada correctamente')
        startResetApp()
      } catch (error) {
        setIsLoading(false);
        console.error('Ha ocurrido un error al deshabilitar la región:',error);
        toastNotification('error', 'Ocurrió un error al intentar deshabilitar la región')
      }
    }

    return (
        <>
          <ToastContainer />

            <Card.Body className="overflow-hidden p-lg-6">
              <Row className="justify-content-between">
                <Col lg={6} className="ps-lg-4 my-5text-lg-start">
                  <h3 className="text-primary">Regiones</h3>
                </Col>
              </Row>
      
              {  !readyContent ? 
                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
              : (
              <Card className="h-md-100">
                <Card.Body>
      
                <AdvanceTableWrapper
                  columns={columnsRegiones}
                  data={regionesToTable}
                  sortable
                  pagination
                  perPage={10}
                >
    
                <Row className="mb-3">
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-success"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => setShowRegionModal(true)}
                      >
                        Nueva Región
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="book"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowBitacoraEntidad(true)
                        }}
                      >
                        Bitacora General
                      </IconButton>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='mx-auto mb-2'>
                    <div className='text-center'>
                      <IconButton
                        variant="falcon-primary"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="me-2"
                        onClick={() => {
                          setShowModalDisabled(true)
                        }}
                      >
                        Deshabilitados
                      </IconButton>
                    </div>
                  </Col>

                </Row>
                
                <AdvanceTable
                  title='Regiones'
                  subtitle='Tabla de Regiones'
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                />
                <div className="mt-3">
                  <AdvanceTableFooter
                    rowCount={regiones.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection
                  />
                </div>
              </AdvanceTableWrapper>
                </Card.Body>
              </Card>
              )}
            </Card.Body>

            <div>
            <BitacoraEntidad title={'Bitácora General'} idEntidad={idEntidad} showBitacoraEntidad={showBitacoraEntidad} setShowBitacoraEntidad={setShowBitacoraEntidad}/>
          </div>

          <div>
            <BitacoraData title={'Bitacora del Dato'} idEntidad={idEntidad} idBitacoraData={idBitacoraData} showBitacoraData={showBitacoraData} setShowBitacoraData={setShowBitacoraData}/>
          </div>


          <div>
            <ModalRegiones showRegionModal={showRegionModal} setShowRegionModal={setShowRegionModal} idToUpdate={idToUpdate} setIdToUpdate={setIdToUpdate} regionToUpdate={regionToUpdate} setRegionToUpdate={setRegionToUpdate} createNewRegion={createNewRegion} updateRegion={updateRegion}  paises={paises}/>
          </div>

          
          <div>
            <ModalDesabilitados showModalDisabled={showModalDisabled} setShowModalDisabled={setShowModalDisabled} enableData={enableRegion}/>
          </div>
          
    
    
          <div>{/* INICIO MODAL PARA ELIMINAR PROPUESTA */}
            <Modal
              show={showModalDelete}
              onHide={() => setShowModalDelete(false)}
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Deshabilitar Región</Modal.Title>
              </Modal.Header>
              <Modal.Body>¿Está seguro de deshabilitar la Región?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  disabled={isLoading}
                  onClick={() => setShowModalDelete(false)}
                  >
                  Cancelar
                </Button>
                <Button 
                  disabled={isLoading}
                  variant="danger"
                  onClick={() => disableRegionById()}
                >
                  Deshabilitar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>{/* FIN MODAL PARA ELIMINAR PROPUESTA */}
          
          { ( loading ) ? <Loading /> : '' }
        </>
    )
}

export default PaisesAdmin
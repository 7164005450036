import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';

const ActiveUsers = ({ users, end = 5, ...rest }) => {
  return (
    <>
      {users.slice(0, end).map(({ id, ...rest }, index) => (
        <ActiveUser {...rest} key={id} isLast={index === users.length - 1} />
      ))}
    </>
  );
};

const ActiveUser = ({ name, profile, conexiones, isLast }) => (
  <Flex className={classNames('align-items-center', { 'mb-3': !isLast })}>
    <Avatar
      size="2xl" 
      name={name.split(' ').slice(0, 2).join(' ')}
    />

    <div className='d-flex justify-content-between w-100'>

      <div className="ms-3">
        <h6 className="mb-0 fw-semi-bold">
          {name}
        </h6>
        <p className="text-500 fs--2 mb-0">{profile}</p>
      </div>
        
      <p className="align-self-center align-end text-500 fs--2 mb-0">Cantidad: <span className='fw-bold text-dark'>{conexiones}</span></p>

    </div>
  </Flex>
);

ActiveUser.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  role: PropTypes.string,
  isLast: PropTypes.bool
};

ActiveUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(ActiveUser.propTypes)),
  end: PropTypes.number
};

export default ActiveUsers;

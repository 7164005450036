// import Camara from "components/camara/Camara";
import useAuth from "hooks/useAuth";
import React, { useState } from "react";
import { Button, Card, CloseButton, Form, Modal, Tab, Tabs} from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "utils/axios";
import { getExtension, toastNotification } from "helpers/utils";
import { useEffect } from "react";
import { TablaArchivos } from "pages/common/components";
import Camara from "components/camara/Camara";
import useLoadingContext from "hooks/useLoadingContext";


const EditarReport = ({ reporte, setReporte, startResetApp, showEditReportModal, setShowEditReportModal }) => {

  const { user } = useAuth();
  const { isLoading, setIsLoading } = useLoadingContext();

  let params = useParams();
  const author = `${user.displayName} ${user.displayLastName}`;
  
  
  const [ inputKey, setInputKey ] = useState(Date.now());
  const [ showCamaraModal, setShowCamaraModal ] = useState(false);

  const [ title, setTitle ] = useState('');
  const [ report, setReport ] = useState('');
  
  const [ archivos, setArchivos ] = useState([]); // Listado de archivos para mostrar en pantalla
  const [ newArchivos, setNewArchivos ] = useState([]); // Nuevos archivos a agregar
  const [ archivosToDelete, setArchivosToDelete ] = useState([]); // Archivos para eliminar

  const [ validated, setValidated ] = useState(false);

  useEffect( () => {
    if( reporte ) asignForm();
  }, [reporte]);
  
  useEffect( () => {
    if( !showEditReportModal ) resetForm();
  }, [showEditReportModal])
  
  const asignForm = () => {
    setTitle(reporte?.title);
    setReport(reporte?.report);
    setArchivos( reporte.files );
  }

  const resetForm = () => {
    setTitle('');
    setReport('');
    setArchivos([]);
    setNewArchivos([]);
    setArchivosToDelete([]);
    setReporte();
    setValidated(false);
  }

  const handleSubmit = async (e) => {

    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    const formToSend = new FormData();
    
    formToSend.append("id_equipment", params.id);
    formToSend.append("author", author);
    if(title != null) formToSend.append("title", title);
    if(report != null) formToSend.append("report", report);
    if( archivosToDelete.length > 0 ) formToSend.append("filesToDelete", archivosToDelete);
    if ( newArchivos.length > 0 ) {
      for (const element of newArchivos) {
        formToSend.append("files", element.file);
      }
    }
  
    try {
      setIsLoading(true);
      const resp = await axios.put(`${process.env.REACT_APP_EQUIPO_SERVICE}/report/${reporte.id}`, formToSend);
      if( resp.status === 200 ){
        toastNotification('success','Reporte editado correctamente', 2000);
        startResetApp();
        setShowEditReportModal(false);
      }
      setIsLoading(false);
    } catch (error) {
      if( error.response?.data?.statusCode === 413) toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
      return;
    }

  };

  const handleFilesChange = (e) => {
    setInputKey(Date.now()); // Para reiniciar el valor del input

    const filesRecibidos = [...e];
    let array = filesRecibidos.map((archivo) => {
      const nameExtension = getExtension(archivo.name);
      return {
        file: archivo,
        temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
        name_file: nameExtension[0],
        extension: nameExtension[1],
        date_file: Date.now(),
        size: archivo.size,
        user: { name: user.displayName, lastName: user.displayLastName },
      }
    })

    setNewArchivos([ ...newArchivos, ...array ]);
    setArchivos([ ...archivos, ...array]);
  };

  const handlePhotoChange = ( file ) => {
    const nameExtension = getExtension(file.name);
    const newPhoto = {
      file: file,
      temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
      name_file: nameExtension[0],
      extension: nameExtension[1],
      date_file: Date.now(),
      size: file.size,
      user: { name: user.displayName, lastName: user.displayLastName },
    };

    setArchivos([...archivos, newPhoto]);
    setNewArchivos([ ...archivos, newPhoto ]);
    setShowCamaraModal(false);
  }


  const handleDeleteFile = ( archivo ) => {
    if( archivo.temporalId ){ // Archivo no creado
      setNewArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
      setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
    }else{ // Archivo a eliminar
      setArchivos( archivos.filter( arch => arch.id !== archivo.id ) );
      setArchivosToDelete([ ...archivosToDelete, archivo.id]);
    }
  }

  const handleTitleChange = (e) => {
    setTitle(e);
  };
  const handleReportChange = (e) => {
    setReport(e);
  };


  return (
    <>
      <Modal
        size="lg"
        className={ showCamaraModal ? 'd-none' : '' }
        show={showEditReportModal}
        centered
        onHide={() => setShowEditReportModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Editar Reporte
          </Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowEditReportModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card>
            <Card.Body className="fs--1 fw-normal p-4">

              <Form noValidate validated={validated} onSubmit={handleSubmit} >
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Título:</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={title}
                    name="title"
                    onChange={(e) => handleTitleChange(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Título.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="description">
                  <Form.Label>Descripción:</Form.Label>
                  <Form.Control
                    onChange={(e) => handleReportChange(e.target.value)}
                    defaultValue={report}
                    as="textarea"
                    name="report"
                    rows={3}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor ingresar Descripción.
                  </Form.Control.Feedback>
                </Form.Group>

                <Tabs defaultActiveKey="uploadFile" id="uncontrolled-tab-example">
                  <Tab
                    eventKey="uploadFile"
                    title="Subir Archivo"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                      <Form.Control 
                        key={inputKey}
                        type="file" 
                        multiple 
                        onChange={(e) => handleFilesChange(e.target.files)}
                      />
                    </Form.Group>
                  </Tab>

                  <Tab
                    eventKey="takePhoto"
                    title="Tomar Foto"
                    className="border-bottom border-x p-3"
                  >
                    <Form.Group controlId="formPhotoMultiple" className="mb-3">
                      <Form.Label>Tome una fotografía:</Form.Label>
                      <Button
                        variant="success"
                        className="ms-4"
                        disabled={isLoading}
                        onClick={() => setShowCamaraModal(true)}
                      >
                        Tomar Foto
                      </Button>
                    </Form.Group>
                    
                  </Tab>
                </Tabs>


                { archivos.length > 0 && (
                    <div className="my-4">
                      <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                    </div>
                  )
                }

                <Form.Group>
                  <Button
                    className="m-2"
                    variant="secondary"
                    onClick={() => setShowEditReportModal(false)}
                    disabled={isLoading}
                  >
                    Cancelar
                  </Button>
                  <Button className="m-2" variant="success" type="submit" disabled={isLoading}>
                    Guardar
                  </Button>
                </Form.Group>
              </Form>

            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
        

      <Modal
        size="lg"
        show={showCamaraModal}
        centered
        onHide={() => setShowCamaraModal(false)}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Cámara</Modal.Title>
          <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setShowCamaraModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="fs--1 fw-normal">
              <Camara savePhoto={handlePhotoChange}/>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditarReport;

import PropTypes from 'prop-types';
import { Card, Col, Row } from "react-bootstrap";

export const PageLayout = ({ title, children }) => {
    return (
        <Card.Body className="overflow-hidden p-lg-6">
            
            <Row className="justify-content-between">
                <Col lg={12} className="ps-lg-4 text-lg-start mb-2">
                    <h3 className="text-primary">{title}</h3>
                </Col>
            </Row>

            {children}
        </Card.Body>
    );
};

PageLayout.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node
};

export default PageLayout;

export const columnasTabla = [
    {
      accessor: 'accion',
      Header: 'Acciones'
    },
    {
      accessor: 'id',
      Header: 'Id'
    },
    {
      accessor: 'name',
      Header: 'Nombre'
    },
    {
      accessor: 'brand',
      Header: 'Marca'
    },
    {
      accessor: 'model',
      Header: 'Modelo'
    },
    {
      accessor: 'area',
      Header: 'Área'
    },
    {
      accessor: 'description',
      Header: 'Descripción'
    },
];
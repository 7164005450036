
export const columns = [
    {
      accessor: 'title',
      Header: 'Título'
    },
    {
      accessor: 'description',
      Header: 'Descripción'
    },
    {
      accessor: 'dateCreation',
      Header: 'Fecha'
    },
      {
        accessor: 'accion',
        Header: 'Acciones'
      }
  ];

  export const columns2 = [
    {
      accessor: 'fecha',
      Header: 'Fecha'
    },
    {
      accessor: 'autor',
      Header: 'Autor'
    },
    {
      accessor: 'accion',
      Header: 'Acción'
    }
  ];
  
  export const data2 = [
    {
      fecha: '2022-10-14',
      autor: 'Vendedor',
      accion: 'Se actualiza descripción del documento'
    },        
    {
        fecha: '2022-09-10',
        autor: 'Administrador',
        accion: 'Se adjunto ( documentotecnico.pdf)'
      },        {
        fecha: '2022-03-01',
        autor: 'Administrador',
        accion: 'Documento Creado'
      }
    ];
import React, { useState, useEffect } from 'react'
import { Form, Modal, CloseButton, Card, Button, Row, Col } from 'react-bootstrap';

const ModalAddEditOpcion = ({ showModal, setShowModal, idToUpdate, opcionToUpdate, submitOpcion }) => {

  const [validated, setValidated] = useState(false);
  const [ descripcion, setDescripcion ] = useState('');

  useEffect( () => {
    ( idToUpdate > 0 ) ? setDescripcion(opcionToUpdate.descripcion) : setDescripcion('');
  }, [showModal])

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
        e.preventDefault();
        e.stopPropagation();
        setValidated(true);
        return
    }

    if( idToUpdate > 0 ){
        //! ACTUALIZAR
        opcionToUpdate.descripcion = descripcion;
        submitOpcion(opcionToUpdate);
    }else{
        //! AGREGAR
        submitOpcion({ descripcion });
        setDescripcion('');
    }
  }

  return (
    <>
        <Modal
            show={showModal}
            centered
            onHide={() => {
                
            }}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { ( idToUpdate > 0 ) ? 'Actualizar opción' : 'Agregar Opción'}
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowModal(false);
                    }}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Descripción:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    as="textarea" 
                                    rows={3} 
                                    name="descripcion"
                                    onChange={(e) => setDescripcion(e.target.value)}
                                    value={descripcion}
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    Por favor ingresar Descripción valida.
                                </Form.Control.Feedback>              
                            </Form.Group>

                            <Form.Group>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancelar
                                </Button>
                                <Button disabled={ ( descripcion.length < 1 ) ? true : false } type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>

                        </Form>

                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    </>
  )

}

export default ModalAddEditOpcion
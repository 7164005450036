import React, { useState, useEffect } from 'react'
import axios from "utils/axios";
import { columnsCiudades } from '../../data'
import { Card, Modal, Spinner, CloseButton, Button } from 'react-bootstrap'

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalDesabilitados = ({ showModalDisabled, setShowModalDisabled, enableData }) => {

  const [ mensajesToTable, setMensajesToTable ] = useState([]);
  const [ dataDisabled, setDataDisabled ] = useState([]);
  const [readyContent, setReadyContent] = useState(false);

  const [idCiudadToEnable, setIdCiudadToEnable] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (showModalDisabled) {
        setReadyContent(false);
        getAllCitiesDisabled();
    };
  }, [showModalDisabled]);

  useEffect( () => {
      const dataActions = dataDisabled.map( data => {
        let toReturn = {}
        toReturn.descripcion = data.descripcion;
        toReturn.distrito = data.distrito;
        toReturn.region = data.region.nombre;
        toReturn.pais = data.region.country.nombre;
        toReturn.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                    setShowConfirmation(true)
                    setIdCiudadToEnable(data.id)
                }}
            ></IconButton>
        ]
        return toReturn
    } )
    setMensajesToTable(dataActions)
  }, [dataDisabled]);

      //! OBTENER TODAS LAS CIUDADES DESHABILITADAS
      const getAllCitiesDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/ciudad/getAllCitiesDisabled`;
        try {
          const { data } = await axios.get(url);
          if(verifyResponseUnauthorizedAwait(data, 1)) return;
          if( data.statusCode == 200) setDataDisabled(data.data)
          if( data.statusCode !== 200) setDataDisabled([])
          setReadyContent(true);
        } catch (error) {
          console.error('Ha ocurrido un error al consultar las ciudades deshabilitadas');
          setReadyContent(true);
        }
      }

  return (
    <>
        <Modal
            size='xl'
            show={showModalDisabled}
            centered
            className={ showConfirmation ? 'd-none' : '' }
            onHide={() => {
                setShowModalDisabled(false)
            }}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Datos Deshabilitados
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowModalDisabled(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( dataDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                        <AdvanceTableWrapper
                            columns={columnsCiudades}
                            data={mensajesToTable}
                            sortable
                            pagination
                            perPage={5}
                        >
                        
                        <AdvanceTable
                            title='Ciudades Deshabilitadas'
                            subtitle='Tabla de Ciudades Deshabilitadas'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={dataDisabled.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) }
                </Card>
                )}
            </Modal.Body>
        </Modal>

        <Modal
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setIdCiudadToEnable(0);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title>Habilitar Ciudad</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de querer habilitar la ciudad?</Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={() => {
                    setShowConfirmation(false);
                    setIdCiudadToEnable(0);
                }}
            >
                Cancelar
            </Button>
            <Button variant="success" 
                onClick={() => {
                    setShowConfirmation(false);
                    setShowModalDisabled(false);
                    enableData(idCiudadToEnable );
                }}
            >
                Habilitar
            </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default ModalDesabilitados
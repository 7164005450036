import React, { useState } from 'react'
import { Row, Col, Modal, Button, Spinner } from 'react-bootstrap'
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { columnsTipoUnidadMedida } from '../data'
import IconButton from 'components/common/IconButton';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useLoadingContext from 'hooks/useLoadingContext';


export const DisabledTipoUnidadMedida = () => {

    const { user } = useAuth();
    const { setIsLoading } = useLoadingContext();
    const [ readyContent, setReadyContent ] = useState(false);

    const [ tiposDisabled, setTiposDisabled] = useState([]);
    const [ enableTipo, setEnableTipo] = useState();
    const [ showEnableModal, setShowEnableModal] = useState(false);

    const startResetApp = async () => {
        getAllTiposDisabled();
      }

      const getAllTiposDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tipoUnidadMedida/getAllMeasureUnitTypesDisabled`;
        axios.get(url).then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          const list = response.data.data
          list.forEach((element) => {
            element.acciones = [
              <IconButton
              title="Habilitar"
              variant="white"
              size="sm"
              icon="check"
              style={{ boxShadow: "none", width: "100%" }}
              onClick={() => {
                setShowEnableModal(true)
                setEnableTipo(element.id)
              }}
              ></IconButton>
            ]
          })
          setTiposDisabled(list);
          setReadyContent(true);
        }).catch(error => {
          setReadyContent(true);
          console.error(error);
          toastNotification('warning', 'Error al intentar cargar la sección, Por favor intentelo nuevamente');
        });
      }

        // HABILITAR CLASIFICACION
        const handleEnable = async () => {
          setIsLoading(true);
          await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/tipoUnidadMedida/enableMeasureUnitType/${enableTipo}/${user.id}`)
          .then((response) => {
            setIsLoading(false);
            if( verifyResponseUnauthorizedFetch(response, 3) ) return;
            setReadyContent(false);
            startResetApp()
            setShowEnableModal(false)
            toastNotification('success', 'Tipo unidad de medida habilitada correctamente')
          }          )
          .catch((err) => {
            setIsLoading(false);
            setReadyContent(true);
            toastNotification('error', 'Ocurrió un error al habilitar la unidad de medida')
          })
        };

        useState( () => {
            startResetApp();
          }, [])
    

  return (
    <>
      { !readyContent ? 
        <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
      : (
        <>
                { !tiposDisabled.length > 0 ? (
                <h6>No hay Tipos de Unidad de Medida Deshabilitados</h6>
              ) :
              (
                <div>
                  <AdvanceTableWrapper
                    columns={columnsTipoUnidadMedida}
                    data={tiposDisabled}
                    sortable
                    pagination
                    perPage={10}
                  >  
                    <AdvanceTable
                      title='Tipos de Unidades de Medida Deshabilitadas'
                      subtitle='Tabla de Tipos de Unidades de Medida Deshabilitadas'
                      table
                      headerClassName="bg-200 text-900 text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tableProps={{
                        bordered: true,
                        striped: true,
                        className: 'fs--1 mb-0 overflow-hidden'
                      }}
                    />
                    <div className="mt-3">
                      <AdvanceTableFooter
                        rowCount={tiposDisabled.length}
                        table
                        rowInfo
                        navButtons
                        rowsPerPageSelection
                      />
                    </div>
                  </AdvanceTableWrapper>
                </div>
              )
            }

            {/* MODAL ENABLE */}
            <div>
        <Modal
          show={showEnableModal}
          onHide={() => setShowEnableModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Habilitar Tipo de Unidad de Medida</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere Habilitar el Tipo de Unidad de Medida?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowEnableModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" 
             onClick={handleEnable}
            >
              Habilitar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </>
      )}
    </>
  )
}

import React, { useState, useEffect } from 'react'
import { columnsAtributos } from '../data'
import { Card, Modal, CloseButton, Spinner, Button } from 'react-bootstrap'
import axios from "utils/axios";

import IconButton from 'components/common/IconButton';

import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { verifyResponseUnauthorizedAwait } from 'helpers/utils';

const ModalAtributosDisabled = ({title, showDisabled, setShowDisabled, habilitarAtributo }) => {

  const [ atributosToTable, setAtributosToTable ] = useState([]);
  const [ atributosDisabled, setAtributosDisabled ] = useState([]);
  const [ readyContent, setReadyContent ] = useState(false);

  const [ showConfirmation, setShowConfirmation ] = useState(false);
  const [ idAtributoToEnable, setIdAtributoToEnable ] = useState(0);

  useEffect(() => {
    if (showDisabled) {
        setReadyContent(false);
        getAtributosDisabled();
    };
  }, [showDisabled]);

  useEffect( () => {
      const atributos = atributosDisabled.map( atributo => {
        ( atributo.isDimensional ) ? atributo.isDimensional = 'Si' : atributo.isDimensional = 'No'
        atributo.tipoMedida = atributo.typeMeasureUnit.descripcion;
        atributo.acciones = [
            <IconButton
                title="Habilitar"
                variant="white"
                size="sm"
                icon="check"
                style={{ boxShadow: "none", width: "100%" }}
                onClick={() => {
                    setShowConfirmation(true)
                    setIdAtributoToEnable(atributo.id)
                }}
            ></IconButton>
        ]
        return atributo
    } );
    setReadyContent(true);
    setAtributosToTable(atributos)
  }, [atributosDisabled]);

    //! OBTENER LOS ATRIBUTOS DESHABILITADOS
    const getAtributosDisabled = async () => {
        const url = `${process.env.REACT_APP_ADMIN_SERVICE}/atributos/getAtributosDisabled`;
        try {
          const { data } = await axios.get(url)
          if(verifyResponseUnauthorizedAwait(data, 1)) return;
          if (data.statusCode === 200 || data.statusCode === 204) setAtributosDisabled(data.data);
        } catch (error) {
          console.error("Error al intentar obtener los atributos deshabilitados");
        }
      }
    

  return (
    <>
        <Modal
            size='xl'
            show={showDisabled}
            centered
            className={ showConfirmation ? 'd-none' : '' }
            onHide={() => {
                setShowDisabled(false)
            }}
            >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { title }
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={ () => {
                        setShowDisabled(false)
                    }}
                />
            </Modal.Header>

            <Modal.Body className="p-3">
            { !readyContent ? 
            <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> 
            : (
                <Card className='p-3'>
                    { ( atributosDisabled.length < 1 ) ? <p className='text-center'>No se han encontrado datos</p> : (

                        <AdvanceTableWrapper
                            columns={columnsAtributos}
                            data={atributosToTable}
                            sortable
                            pagination
                            perPage={5}
                        >
                        
                        <AdvanceTable
                            title='Atributos Deshabilitados'
                            subtitle='Tabla de Atributos Deshabilitados'
                            table
                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                            rowClassName="align-middle white-space-nowrap"
                            tableProps={{
                            bordered: true,
                            striped: true,
                            className: 'fs--1 mb-0 overflow-hidden'
                            }}
                        />
                        <div className="mt-3">
                            <AdvanceTableFooter
                            rowCount={atributosDisabled.length}
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                            />
                        </div>
                        </AdvanceTableWrapper>
                    ) }
                </Card>
                )}
            </Modal.Body>
        </Modal>

        <Modal
            show={showConfirmation}
            onHide={() => {
                setShowConfirmation(false);
                setIdAtributoToEnable(0);
            }}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title>Habilitar Atributo</Modal.Title>
            </Modal.Header>
            <Modal.Body>¿Está seguro de querer habilitar el atributo?</Modal.Body>
            <Modal.Footer>
            <Button
                variant="secondary"
                onClick={() => {
                    setShowConfirmation(false);
                    setIdAtributoToEnable(0);
                }}
            >
                Cancelar
            </Button>
            <Button variant="success" 
                onClick={() => {
                    setShowConfirmation(false);
                    setShowDisabled(false);
                    habilitarAtributo(idAtributoToEnable);
                }}
            >
                Habilitar
            </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}

export default ModalAtributosDisabled
import {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from "hooks/useAuth";
import {Button, Modal} from "react-bootstrap";

const SessionTimeout = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [openModal, setOpenModal] = useState(false)

  const cerrarModal = () =>{
    setOpenModal(false);
  }
  useEffect(() => {
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout( async () => {
            setOpenModal(true);
          }, 30 * 60 * 1000); // 30 minutos en milisegundos
          //5 * 1000); // 30 segundos en milisegundos
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach((event) => document.addEventListener(event, resetTimeout));

    resetTimeout();

    return () => {
      clearTimeout(timeoutId);
      events.forEach((event) => document.removeEventListener(event, resetTimeout));
    };
  }, [navigate]);

  return <div><Modal show={openModal}
                backdrop="static"
                keyboard={false}
  >
    <Modal.Header>
      <Modal.Title>Confirmación de inactividad</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      Se ha detectado inactividad en el sitio. ¿Desea continuar navegando?
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={logout}>
        No
      </Button>
      <Button variant="primary" onClick={cerrarModal} >Si</Button>
    </Modal.Footer>
  </Modal>
  </div>;
};

export default SessionTimeout;
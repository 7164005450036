import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import axios from 'utils/axios';
import { toastNotification, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import useLoadingContext from "hooks/useLoadingContext";

export const EditUnidadMedida = ({data , handleChange, setShowEditModal}) => {

    const { user } = useAuth();
    const { setIsLoading } = useLoadingContext();


    const [validated, setValidated] = useState(false);
    const [tiposUnidadesMedida, setTiposUnidadesMedida] = useState([]);

    const [ nombre, setNombre ] = useState(data.nombre);
    const [ descripcion, setDescripcion ] = useState(data.descripcion);
    const [ idTipoUnidadMedida, setIdTipoUnidadMedida ] = useState(data.idTipoUnidadMedida);

    useEffect(() => {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tipoUnidadMedida/getAllMeasureUnitTypes`;
      axios.get(url).then((response) => {
        if( verifyResponseUnauthorizedFetch(response, 1) ) return;
        if(response.data.statusCode === 200){
          const list =  response.data.data;
          setTiposUnidadesMedida(list)
        }
      });
    }, [])

    const handleSubmit = async(e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
          e.preventDefault();
          e.stopPropagation();
        } else {

          setIsLoading(true);

          const formData = {
            nombre: nombre,
            descripcion: descripcion,
            idTipoUnidadMedida: idTipoUnidadMedida
          }

          await axios.put(`${process.env.REACT_APP_ADMIN_SERVICE}/unidadMedida/${data.id}/${user.id}`,formData)
            .then((response) => {
              if( verifyResponseUnauthorizedFetch(response, 3) ) return;
                handleChange(true);
                setShowEditModal(false);
                toastNotification('success', 'Unidad de medida editada correctamente');
            })
            .catch((err) => {
              console.error(err)
              toastNotification('error', 'La Unidad Medida no se pudo editar.')
            })
          }
          setIsLoading(false);
          setValidated(true);
    };

  return (
    <div>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>

        <Form.Group className="mb-3">
            <Form.Label>Nombre:</Form.Label>
            <Form.Control
                type="text"
                name='nombre'
                value={nombre}
                required
                onChange={(e) => setNombre(e.target.value)}
                            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Descripción:</Form.Label>
            <Form.Control 
                autoComplete="off"
                as="textarea" 
                rows={3} 
                name="descripcion"
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                required
            />
            <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
            </Form.Control.Feedback>              
        </Form.Group>

        <Form.Group className="mb-3">
            <Form.Label>Tipo Unidad de Medida:</Form.Label>
            <Form.Select 
                required
                name="idTipoUnidadMedida"
                value={idTipoUnidadMedida}
                onChange={(e) => setIdTipoUnidadMedida(e.target.value)}>
                <option value=''>Seleccione Tipo Unidad de Medida</option>
                {tiposUnidadesMedida.map((item) => 
                    <option 
                        name="tipoUnidadMedida" 
                        key={item.id} 
                        value={item.id}>
                            {item.descripcion}
                    </option>)}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                Por favor seleccione Tipo Unidad de Medida.
            </Form.Control.Feedback>
        </Form.Group>

        <Form.Group>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowEditModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}

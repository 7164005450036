import React, { useCallback, useEffect, useState } from 'react';

import { Card, Col, Row, Spinner } from 'react-bootstrap';

import StatisticsCard from 'components/dashboards/stats-cards/StatisticsCard';
import BasicBarChart from 'components/common/BasicBarChart'
import bg1 from 'assets/img/icons/spot-illustrations/corner-1.png';
import bg2 from 'assets/img/icons/spot-illustrations/corner-2.png';
import bg3 from 'assets/img/icons/spot-illustrations/corner-3.png';
import axios from 'utils/axios';
import moment from 'moment';
import { toastNotification } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import { TableWrapper } from '../../components/TableWrapper';
import { FiltrosComponent } from './components/FiltrosComponent';


export const columnsConexiones = [
    {
      accessor: 'equipo_id',
      Header: 'Id'
    },
    {
      accessor: 'equipo_name',
      Header: 'Nombre'
    },
    {
      accessor: 'equipo_codigo_id',
      Header: 'Código ID'
    },
    {
      accessor: 'cantidad_accedido',
      Header: 'Accesos'
    }, 
    {
      accessor: 'fecha_acceso',
      Header: 'Último Acceso'
    },
]

export const AccesosEquipos = () => {

    const [ readyContent, setReadyContent ] = useState(true);

    const [ summaryTotals, setSummaryTotals ] = useState({});
    const [ accessEquipments, setAccessEquipments ] = useState([]);
    const [ dataToTable, setDataToTable ] = useState([]);

    const [ corporativos, setCorporativos ] = useState([]);
    const [ plantas, setPlantas ] = useState([]);
    const [ lineas, setLineas ] = useState([]);
    const [ areas, setAreas ] = useState([]);
    const [ tiposEquipos, setTiposEquipos ] = useState([]);

    const [ configFilters, setConfigFilters ] = useState({ corporativoSelected: 0, plantaSelected: 0, lineaSelected: 0, areaSelected: 0, tipoEquipoSelected: 0, desde: '', hasta: '' });

    const [ plantasToSelect, setPlantasToSelect ] = useState([]);
    const [ linaesToSelect, setLineasToSelect ] = useState([]);
    const [ areasToSelect, setAreasToSelect ] = useState([]);

    useEffect(() => { startResetApp(); getAccessToEquipmentByFilters() }, []);

    const startResetApp = async () => {
        setReadyContent(false);
        try {
            
            const [ respCorporativos, respPlantas, respLineas, respAreas, respTiposEquipos, respSummaryEquipment, respAccessToEquipments ] = await Promise.all([
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporationsByState`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/planta/getAllFloorsByState`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/linea/getAllLineByState`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasByState`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposEquiposByState`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getResumeEquipments`),
                axios.get(`${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getAccessToEquipments`)
            ]);

            const { data: dataCorporativos } = respCorporativos;
            const { data: dataPlantas } = respPlantas;
            const { data: dataLineas } = respLineas;
            const { data: dataAreas } = respAreas;
            const { data: dataTiposEquipos } = respTiposEquipos;
            const { data: dataSummaryEquipment } = respSummaryEquipment;
            const { data: dataAccessToEquipments } = respAccessToEquipments;

            setCorporativos(dataCorporativos.data);
            setPlantas(dataPlantas.data);
            setLineas(dataLineas.data);
            setAreas(dataAreas.data);
            setTiposEquipos(dataTiposEquipos.data);
            setSummaryTotals({ totalEquipments: Number(dataSummaryEquipment.data.totalEquipments), equipmentsActivated: Number(dataSummaryEquipment.data.equipmentsActived), equipmentsDisabled: Number(dataSummaryEquipment.data.equipmentsDisabled)  });
            formatDataToTable(dataAccessToEquipments.data);
            
        } catch (error) {
            toastNotification('warning', 'Ocurrio un error al intentar obtener la estadistica de los accesos a equipos')
            console.error(error);
        }
        setReadyContent(true);
    };

    const handleConfigFilters = ({ corporativo, planta, linea, area, tipoEquipo, dateDesde, dateHasta }) => {
      setConfigFilters({
        corporativoSelected: corporativo !== undefined ? corporativo : configFilters.corporativoSelected,
        plantaSelected: planta !== undefined ? planta : configFilters.plantaSelected,
        lineaSelected: linea !== undefined ? linea : configFilters.lineaSelected,
        areaSelected: area !== undefined ? area : configFilters.areaSelected,
        tipoEquipoSelected: tipoEquipo !== undefined ? tipoEquipo : configFilters.tipoEquipoSelected,
        desde: dateDesde !== undefined ? dateDesde : configFilters.desde,
        hasta: dateHasta !== undefined ? dateHasta : configFilters.hasta,
      })
    }

    const handleChnageStructure = ({ corporativo, planta, linea }) => {

        if( corporativo !== undefined ) {
            setConfigFilters({ corporativoSelected: corporativo, plantaSelected: 0, lineaSelected: 0, areaSelected: 0 });
            setPlantasToSelect( plantas.filter( planta => planta.id_corporativo === corporativo ) )
            setLineasToSelect([]);
            setAreasToSelect([]);
        };

        if( planta !== undefined ) {
            setConfigFilters({ plantaSelected: planta, lineaSelected:0,  areaSelected: 0 });
            setLineasToSelect( lineas.filter( linea => linea.id_planta === planta ) );
            setAreasToSelect([]);
        }
        
        if( linea !== undefined ) {
            setConfigFilters({ lineaSelected: linea, areaSelected: 0 });
            setAreasToSelect( areas.filter( area => area.id_linea === linea ) );
        }
    }

    const clearFilters = () => setConfigFilters({ corporativoSelected: 0, plantaSelected: 0, lineaSelected: 0, areaSelected: 0, tipoEquipoSelected: 0, desde: '', hasta: '' });

    const getAccessToEquipmentByFilters = async () => {
        try {

            const body = {};
            if( configFilters.corporativoSelected > 0 ) body.idCorporativo = configFilters.corporativoSelected;
            if( configFilters.plantaSelected > 0 ) body.idPlanta = configFilters.plantaSelected;
            if( configFilters.lineaSelected > 0 ) body.idLinea = configFilters.lineaSelected;
            if( configFilters.areaSelected > 0 ) body.idArea = configFilters.areaSelected;
            if( configFilters.tipoEquipoSelected > 0 ) body.idTipoEquipo = configFilters.tipoEquipoSelected;
            if( configFilters.desde !== '' ) body.startDate = configFilters.desde;
            if( configFilters.hasta !== '' ) body.endDate = configFilters.hasta;

            const { data } = await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/estadisticas/getEquipmentMoreInterest`, body);
            setAccessEquipments(data.data.map( val => { return { name: val.equipo_name, value: Number(val.cantidad_accedido) } } ));
        } catch (error) {
            toastNotification('warning', 'Ocurrio un error al intentar obtener la estadistica de los accesos a equipos')
            console.error(error);
        }
    }

    const formatDataToTable = useCallback((accesos) => {
        const data = accesos.map( access => {
            const obj = {...access};
            obj.fecha_acceso = ( <p className='text-warning fs--1 p-0 m-0 text-center'>{ moment(obj.fecha_acceso).format('DD/MM/YYYY  h:mm:ss a') }</p> )
            obj.cantidad_accedido = ( <p className='text-success fs--1 p-0 m-0 text-center fw-bold'>{ obj.cantidad_accedido }</p> )
            return obj;
        });
    
        setDataToTable(data);
    }, []);


    const statsData = [
        {
            title: 'Equipos en el Sistema',
            value: summaryTotals.totalEquipments,
            decimal: false,
            suffix: '',
            prefix: '',
            link: '',
            linkText: '',
            valueClassName: 'text-info',
            image: bg2
        },
        {
            title: 'Equipos Habilitados',
            value: summaryTotals.equipmentsActivated,
            decimal: false,
            suffix: '',
            prefix: '',
            valueClassName: 'text-success',
            link: '',
            linkText: '',
            image: bg3
        },
        {
            title: 'Equipos Deshabilitados',
            value: summaryTotals.equipmentsDisabled,
            decimal: false,
            suffix: '',
            prefix: '',
            valueClassName: 'text-warning',
            link: '',
            linkText: '',
            image: bg1
        },
    ];

  return (
    <>
        { !readyContent
          ? ( <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div> )
          : (
            <Card.Body className="overflow-hidden p-lg-6">

                <Row className="justify-content-between mb-4">
                    <Col>
                        <h3 className="text-primary">Estadísticas Accesos a Equipos</h3>
                    </Col>
                </Row>

                {/* Resumen de Equipos */}
                <Row className="g-3 mb-3">
                    {statsData.map((stat, index) => (
                        <Col key={stat.title} sm={index === 2 ? 12 : 6} md={4}>
                            <StatisticsCard stat={stat} style={{ minWidth: '12rem' }} />
                        </Col>
                    ))}
                </Row>


                {/* Grafico de Barra y Torta */}
                <Row className="g-3 mb-3">
                    <Card>
                        <Card.Header className='fs--1 d-flex justify-content-between'>
                            <div>Graficos de equipos más accedidos</div>
                            <div className='text-end'>
                                <IconButton
                                    variant="falcon-success"
                                    size="sm"
                                    icon="search"
                                    transform="shrink-3"
                                    className="me-2 mb-2"
                                    onClick={getAccessToEquipmentByFilters}
                                >
                                    Buscar
                                </IconButton>
                                <IconButton
                                    variant="falcon-info"
                                    size="sm"
                                    icon="trash"
                                    transform="shrink-3"
                                    className="me-2 mb-2"
                                    onClick={clearFilters}
                                >
                                    Limpiar
                                </IconButton>
                            </div>
                        </Card.Header>
                        <Card.Body className='p-3'>
                            <Row className="mb-4">
                                <FiltrosComponent 
                                    key={'filtrosGraph'}
                                    handleChnageStructure={handleChnageStructure}
                                    configFilters={configFilters}
                                    corporativos={corporativos}
                                    plantasToSelect={plantasToSelect}
                                    linaesToSelect={linaesToSelect}
                                    areasToSelect={areasToSelect}
                                    tiposEquipos={tiposEquipos}
                                    handleConfigFilters={handleConfigFilters}
                                />
                            </Row>
                            
                            <div className='mb-4'>
                                <BasicBarChart 
                                    className="mb-5"
                                    data={accessEquipments}
                                    key={'grafico'} 
                                    labelAxisX='Equipos'
                                    labelAxisY='Cantidad de Accesos'
                                />
                            </div>

                        </Card.Body>
                    </Card>
                </Row>

                {/* Tabla de conexiones de usuarios  */}
                <Row className="g-3 mb-3">
                    <Card>
                        <Card.Body>

                            <TableWrapper
                                key={'Tabla'}
                                columnas={columnsConexiones}
                                data={dataToTable}
                                elementosPorPagina={10}
                                titleToExport={'Accesos de Equipos'}
                                subtitleToExport={'Tabla de Accesos de Equipos'}
                            />

                        </Card.Body>
                    </Card>
                </Row>

            </Card.Body>
          )
        }
    </>
  )
}
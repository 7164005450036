// import { Outlet } from "react-router-dom";
//
import NavbarStandard from "components/pages/landing/NavbarStandard";
import FooterStandard from "components/pages/landing/FooterStandard";
import HeaderStandar from "components/pages/landing/HeaderStandar";
import { CarouselPage } from "components/pages/landing/CarouselPage";
import { ToastContainer } from "react-toastify";

// ----------------------------------------------------------------------

export default function Landing() {
    return (
    <main style={{    
      backgroundColor: ' #0b1727',
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100vh'}}>
      <ToastContainer />
      <NavbarStandard />
      <HeaderStandar />
      {/* <div>
        <Outlet />
      </div> */}
            <CarouselPage />
      <FooterStandard />
    </main>
  );
}

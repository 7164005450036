import React from 'react';
import Section from 'components/common/Section';
import imagen1 from 'assets/img/59060c8b0cbeef0acff9a65b.png';
import { Col, Row } from 'react-bootstrap';

export const HeaderStandar = () => {
    return (
        <>
            <Section bg="dark" className="pt-6 pb-1 light" >
            <Row>
                <Col xl={11}>
                <div className="text-wrapper" style={{ textAlign: 'center' }}>
                    {/* <br /> */}
                    <hr/>
                  <h2 style={{ color: 'white' }} ><b>BIENVENIDO A PROMANET</b></h2>
                    {/* <br /> */}
                    <h4 style={{ color: 'white' }}>TODA LA INFORMACIÓN DE SELLADO NECESARIA DE SUS EQUIPOS ROTATIVOS, A UN CLICK</h4>
                    {/* <br /> */}
                    <h4 style={{ color: 'white' }}>UN DESARROLLO DE PROMATHER S.A</h4>
                </div>
                </Col>
                <Col xl={1}>
                <div>
                    <img className='m-2' src={imagen1} style={{width:'120px'}}></img>
                </div>
                </Col>
            </Row>
            <hr/>

            </Section>
        </>
    )
}

export default HeaderStandar;

import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Button, Card, CloseButton, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import ActiveUsers from './grafico/GraficoAnterior';
import {columns, columnsCliente} from './data';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import { NuevoItem } from './nuevoItem';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import { toastNotification, graficar, formatterPesoChile } from "helpers/utils";
import { EditarItem } from './editarItem';
import moment from 'moment';
import useAuth from 'hooks/useAuth';
import ValidateAccessEquipment from 'components/pages/ValidateAccessEquipment';
import TipoPerfilEnum from 'utils/TipoPerfilEnum';

export const SituacionAnterior = () => {
  const navigate = useNavigate();
  const { user } = useAuth()
  let params = useParams();

  // MODAL
  const [showDeleteCostoModal, setShowDeleteCostoModal] = useState(false);
  const [showEditCostoModal, setShowEditCostoModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);

  const [deleteCosto, setDeleteCosto] = useState();
  const [editarCosto, setEditarCosto] = useState();

  const [ situacionAnterior, setSituacionAnterior] = useState();
  const [ costosAnteriores, setCostosAnteriores] = useState([]);
  const [ costosAnterioresActions, setCostosAnterioresActions] = useState([]); //Nueva

  const [ maxValue , setMaxValue] = useState(0);

  // PERIODO A GRAFICAR
  const [ periodoSituacionAnterior , setPeriodoSituacionAnterior] = useState(3);
  const [ graficarSituacionAnterior, setGraficarSituacionAnterior] = useState([]);

  const [ readyContent, setReadyContent ] = useState(false);


  const startResetApp = async () => {
    const hasAccess = await ValidateAccessEquipment(user, params.id);
    if( !hasAccess ) navigate("/vendedor/inicio");
    if(hasAccess) getSituacionAnterior();
    setReadyContent(true);
  }

  useEffect(() => {
    const inicio = moment();
    const termino = moment().add(periodoSituacionAnterior, 'months');
    const graficarCostosAnteriores = graficar(inicio, termino, costosAnteriores);
    setGraficarSituacionAnterior(graficarCostosAnteriores);
    const maxSituacionAnterior = Math.max(...graficarCostosAnteriores);
    setMaxValue(maxSituacionAnterior);
  }, [costosAnteriores, periodoSituacionAnterior])


  useEffect(() => {

    costosAnteriores.forEach( e => {
      const tipoCosto = e.tipoCosto;
      e.tipoCosto =  tipoCosto.trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
      e.valorFormat = formatterPesoChile(e.valor);
      e.diaCostoFormat = `Día ${e.diaCosto}`;
      if(e.periodo === 1){
        e.periodoDias = `${e.periodo} día`;
      }else{
        e.periodoDias = `${e.periodo} días`;         
      }
      e.accion = [
        <IconButton
          title="Editar"
          variant="white"
          size="sm"
          icon="edit"
          style={{ boxShadow: "none", width: "50%" }}
          onClick={() => {
            setShowEditCostoModal(!showEditCostoModal);
            setEditarCosto(e);
          }}
        ></IconButton>,
        <IconButton
          title="Eliminar"
          variant="white"
          size="sm"
          icon="trash-alt"
          style={{ boxShadow: "none", width: "50%" }}
          onClick={() => {
            setShowDeleteCostoModal(!showDeleteCostoModal);
            setDeleteCosto(e.id);
          }}
        ></IconButton>
      ];
    } )

    setCostosAnterioresActions(costosAnteriores)
    
  }, [costosAnteriores]);


  const getSituacionAnterior = async () => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getSituacionAnteriorByIdEquipment/${params.id}`;
    const { data } = await axios.get(url);
    if(data.statusCode === 200){
      setSituacionAnterior(data.data[0]);
      getCostosSituacionAnterior(data.data[0].id)
    }
  }

  const getCostosSituacionAnterior = async ( id ) => {
    const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/getCostosByIdSituacion/${id}`
    const { data } = await axios.get(url);
    if( data.statusCode === 200) setCostosAnteriores(data.data)
  }

  useState( () => {
    startResetApp();
  }, [])

  // ELIMINAR COSTO
  const handleDeleteCosto = async () => {
      await axios.delete(`${process.env.REACT_APP_EQUIPO_SERVICE}/situacionAnterior/${deleteCosto}`)
      .then(() => {
        startResetApp()
        setShowDeleteCostoModal(false)
        }
      )
      .catch((err) => {
        toastNotification('error', 'No se pudo Eliminar.', 2000)
        setTimeout(() => {
          setShowDeleteCostoModal(false);
        }, 2000);
      })
  };

  // ACTUALIZAR USEEFFECT CARGA DATA 
  const handleChangeNuevoItem = (data) => {
    startResetApp()
  }
  
  // ACTUALIZAR USEEFFECT CARGA DATA 
  const handleChangeEditarItem = (data) => {
    startResetApp()
  }  


  return (
    <>
    { !readyContent ? 
      <div className='d-flex justify-content-center align-items-center' style={{ minHeight: 200 }}> <Spinner className='' animation="border" variant="success" /> </div>  
      : (
        <>
          <div>
            <Row>
              <Col lg={6} className="mt-2 mb-4">
                <Card>
                  <Card.Body>
                    <AdvanceTableWrapper
                      columns={( user.idTypeProfile === TipoPerfilEnum.administrador ) ? columns : columnsCliente}
                      data={costosAnterioresActions}
                      sortable
                      pagination
                      perPage={1000}
                    >
                      <Row>
                        <h5>Costos Situación Anterior</h5>
                      </Row>

                      <Row className="mb-2">

                        <Col xs={12} md={8} className="order-2 order-md-1 mb-4 mt-2">  
                          <Form.Group >
                            <Form.Label>Cantidad Meses Periodo:</Form.Label>
                            <Form.Control
                              autoComplete="off"
                              defaultValue={periodoSituacionAnterior}
                              name="periodo"
                              onChange={(e) => setPeriodoSituacionAnterior(Number(e.target.value))}
                              type="number"
                              min={1}
                              pattern="^[0-9]+"
                              required            
                            />
                          </Form.Group>
                        </Col>

                        <Col xs={12} md={4} className="order-1 order-md-2 mt-sm-4 mb-sm-3 d-flex align-items-center justify-content-center">
                          { user.idTypeProfile === TipoPerfilEnum.administrador && (
                            <div id="new-document">
                              <IconButton
                                variant="falcon-success"
                                size="sm"
                                icon="plus"
                                transform="shrink-3"
                                className='me-2'
                                onClick={() => setShowItemModal(!showItemModal)}
                              >
                                Nuevo ítem
                              </IconButton>
                            </div>
                          ) }
                        </Col>

                      </Row>

                      <AdvanceTable
                        title='Situación Anterior'
                        subtitle='Tabla de Situaciones Anteriores'
                        searchBar={false}
                        btnExport={false}
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs--1 mb-0 overflow-hidden'
                        }}
                      />
                    </AdvanceTableWrapper>
                  </Card.Body>
                </Card>
              </Col >
              <Col className="mt-2 mb-4">
                <ActiveUsers dataSituacionAnterior = {graficarSituacionAnterior} periodo = {periodoSituacionAnterior} value={maxValue} />
              </Col >
            </Row>
          </div>
        </>
      )}
      <div>
        <Modal
            show={showItemModal}
            centered
            onHide={() => setShowItemModal(false)}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Nuevo ítem
              </Modal.Title>
              <CloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setShowItemModal(false)}
              />
            </Modal.Header>
            <Modal.Body className="p-0">
              <Card>
                <Card.Body className="fs--1 fw-normal p-4">

                    <NuevoItem data={situacionAnterior} change={handleChangeNuevoItem} setShowItemModal={setShowItemModal} />

                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
      </div>
      <div>
        <Modal
          show={showEditCostoModal}
          centered
          onHide={() => setShowEditCostoModal(false)}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Editar ítem
            </Modal.Title>
            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setShowEditCostoModal(false)}
            />
          </Modal.Header>
          <Modal.Body className="p-0">
            <Card>
              <Card.Body className="fs--1 fw-normal p-4">
                <EditarItem data={editarCosto} change={handleChangeEditarItem} setShowEditCostoModal={setShowEditCostoModal} />
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
      <div>
        <Modal
          show={showDeleteCostoModal}
          onHide={() => setShowDeleteCostoModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Eliminar ítem</Modal.Title>
          </Modal.Header>
          <Modal.Body>¿Está seguro que quiere eliminar el ítem?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteCostoModal(false)}
            >
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleDeleteCosto}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  )
}


import React, { useState, useEffect } from 'react'
import { Card, CloseButton, Col, Form, FormLabel, Modal, Row, Button } from 'react-bootstrap'
import axios from 'utils/axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';

import IconButton from 'components/common/IconButton';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

import { UpdateItem } from './updateItem';

import { columnsCostos } from './data';
import { capitalizarString } from 'helpers';
import { formatterPesoChile, toastNotification } from 'helpers/utils';

import { NuevoItem } from './nuevoItem';
import useLoadingContext from 'hooks/useLoadingContext';


export const ActualizarPropuesta = ({ tiposCostos, tiposEstados, propuestaToUpdate, setShowUpdatePropuestaModal, showUpdatePropuestaModal,startResetApp, setPropuestaSelect,setPropuestaToUpdate={setPropuestaToUpdate} }) => {
    registerLocale('es', es)

    const { isLoading, setIsLoading } = useLoadingContext();

    //! DATOS PARA EL FORMULARIO
    const [ fechaInicioSituacionReal, setFechaInicioSituacionReal ] = useState('');

    const [idEstadoPropuesta, setIdEstadoPropuesta] = useState(propuestaToUpdate.idEstadoPropuesta.id);
    const [periodo, setPeriodo] = useState(propuestaToUpdate.periodo);
    const [nombre, setNombre] = useState(propuestaToUpdate.nombre);
    const [idEquipo, setIdEquipo] = useState(propuestaToUpdate.idEquipo.id);
    const [descripcion, setDescripcion] = useState(propuestaToUpdate.descripcion);

    const [formDisabled, setFormDisabled] = useState(false);
    
    //! COSTOS PROPUESTA( IDENTICO A BD ) - COSTOS PROPUESTA ACTIONS( CON BOTONES PARA LA TABLA )
    const [costosPropuesta, setCostosPropuestas] = useState([]);
    const [costosPropuestaActions, setCostosPropuestaActions] = useState([]);

    //! ITEM PARA ENVIAR AL MODAL AL ACTUALIZAR
    const [itemToUpdate, setitemToUpdate] = useState({});
    
    //! STATE PARA MOSTRAR RESPECTIVOS MODALES
    const [showItemModal, setShowItemModal] = useState(false);
    const [showUpdateItemModal, setUpdateShowItemModal] = useState(false);
    const [showNewDateToInstall, setShowNewDateToInstall] = useState(false);

    const [validated, setValidated] = useState(false);
    const [date, setDate] = useState(null);

    const [habilitarButton, setHabilitarButton] = useState(true);


    //? OBTENEMOS LOS COSTOS DE LA PROPUESTA
    useEffect(async () => {
        getCostosPropuestaById(propuestaToUpdate.id);
        if( idEstadoPropuesta == 4 || idEstadoPropuesta == 5 ) setFormDisabled(true)
    }, [])


    //? TOMAMOS UNA COPIA DEL OBJETO DE COSTOS Y CREAMOS UNO ADAPTADO A LA TABLA
    useEffect( async () => {
        async function saveNewsActions() {

            let costosActions = JSON.parse(JSON.stringify(costosPropuesta)).map( c => {
                const costoToReturn = {...c};
                let costo = tiposCostos.find(tipoCosto => tipoCosto.id === c.idTipoCosto)
                costoToReturn.nameTipoCosto = capitalizarString(costo.nombre)
                costoToReturn.valor= formatterPesoChile(c.valor);
                costoToReturn.accion = [
                    <IconButton
                        disabled={formDisabled}
                        title="Editar"
                        variant="white"
                        size="sm"
                        icon="edit"
                        style={{ boxShadow: "none", width: "33%" }}
                        onClick={() => handleUpdateCosto(c)}
                    ></IconButton>,
                    <IconButton
                        disabled={formDisabled}
                        title="Eliminar"
                        variant="white"
                        size="sm"
                        icon="trash-alt"
                        style={{ boxShadow: "none", width: "33%" }}
                        onClick={() => handleEliminarCosto(c)}
                    ></IconButton>,
                ];
                return costoToReturn;
            })
            setCostosPropuestaActions(costosActions)
        }
        await saveNewsActions();
    }, [costosPropuesta] )

    //! FUNCION PARA MOSTRAR EL MODAL DE ACTUALIZAR UN ITEM
    const handleUpdateCosto = (costo) => {
        const { accion, ...rest } = costo;
        setitemToUpdate(rest);
        setUpdateShowItemModal(true)
    }

    //! OBTENEMOS LOS COSTOS POR EL ID DE LA PROPUESTA
    const getCostosPropuestaById = async id => {
        const url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getCostosByIdPropuesta/${id}`;
        const { data } = await axios.get(url);
        let costos = data.data.map( cost => {
            cost.idTipoCosto = cost.idTipoCosto.id
            return cost;
        } )
        setCostosPropuestas(costos);
    }

    //! FUNCION PARA ELIMINAR COSTOS DEL OBJ LOCAL
    const handleEliminarCosto = (costo) => {
        const newCostos = costosPropuesta.filter( c => c.id !== costo.id);
        setCostosPropuestas(newCostos);
    }

    //! FUNCION PARA ACTUALIZAR 
    const updateItem = (id, item) => {
        const newCostos = costosPropuesta.map( costo => {
            if( costo.id === id){
                costo.descripcion = item.descripcion
                costo.periodo = item.periodo
                costo.valor = item.valor
                costo.idTipoCosto = item.idTipoCosto
                costo.diaCosto = item.diaCosto
            }
            return costo
        } )
        setCostosPropuestas([ ...newCostos ]);
    }


    //! VERIFICAR RADIO BUTTON PARA PODER MOSTRAR MODAL O NO    
    const previoToSubmit = async (e) => {
        e.preventDefault();
        if( idEstadoPropuesta == 4 ) {
            setShowNewDateToInstall(true)
        }else{
            handleSubmit(e)
        }
    }
    
    //! FUNCION AL ENVIAR EL FORMULARIO
    const handleSubmit = async(e) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            setValidated(true);
            return
        }
                
        let url;
        const costosSinActions = costosPropuesta.map( costo => {
            const { accion, ...rest } = costo;
            rest.idTipoCosto = Number(rest.idTipoCosto)
            rest.id = Number(rest.id)
            return rest
        });


        const propuesta = {
            periodo: Number(periodo),
            descripcion,
            nombre,
            idEstadoPropuesta: Number(idEstadoPropuesta),
            idEquipment: Number(idEquipo),
            costosPropuesta: costosSinActions.map( costo => {
                const { id, nameTipoCosto,...rest } = costo
                return rest;
            })
        }

        if(propuesta.idEstadoPropuesta == 4 ) {            
            url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/getSituacionRealByIdEquipo/${idEquipo}`
            
            setIsLoading(true);
            const { data } = await axios.get(url);

            if( data.statusCode == 200) {
                setIsLoading(false);
                toastNotification('warning', 'El equipo ya cuenta una propuesta activa');
                setFormDisabled(false);
                return;
            }else{
                
                let newCostos = propuesta.costosPropuesta.filter( c => {
                    if( c.periodo == 0 && c.diaCosto == 0){
                        return c
                    }
                });

                propuesta['costosReales'] = newCostos;
                propuesta.fechaInicio = moment(fechaInicioSituacionReal).format();
                propuesta.fechaTermino = moment(fechaInicioSituacionReal).add(periodo, 'days').format();
                propuesta.idPropuesta = Number(propuestaToUpdate.id);
                
                delete propuesta.costosPropuesta;
                url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/createNewSituacionReal`
                
                setIsLoading(true);
                const { data } = await axios.post(url, propuesta);
                setIsLoading(false);
                if( data.statusCode == 200 ) toastNotification('success','La propuesta fue inicializada correctamente');
            }
        }


        setIsLoading(true);
        delete propuesta.id;
        url = `${process.env.REACT_APP_EQUIPO_SERVICE}/propuestas/updatePropuestaById/${propuestaToUpdate.id}`
        const { data } = await axios.patch(url, propuesta);
        if(data.statusCode == 200) { 
            setShowUpdatePropuestaModal(!showUpdatePropuestaModal);
            toastNotification('success','Propuesta Actualizada Correctamente', 2000);
            await startResetApp();
        }
        setIsLoading(false);
    };


    //! AGREGAR UN NUEVO ITEM
    const addNewItem = ( item ) => {
        item.id = uuidv4();
        setCostosPropuestas([...costosPropuesta, item])
    }


  return (
    <>
        <Modal
            size='xl'
            show={showUpdatePropuestaModal}
            centered
            className={ ( showItemModal || showUpdateItemModal || showNewDateToInstall ) ? 'd-none' : '' }
            onHide={() => setShowUpdatePropuestaModal(false)}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar Propuesta
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowUpdatePropuestaModal(false)}
                />
            </Modal.Header>

            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        {/* gere */}
                        <Form noValidate validated={validated} onSubmit={previoToSubmit}>

                            <Row>
                                <Col>
                                    <Card>
                                        <div className='m-3'>
                                            <h5>Datos de Propuesta</h5>
                                        </div>

                                            <Form.Group  className="m-4">
                                                <Form.Label>Estado:</Form.Label>
                                                <br/>
                                                <div className='text-center '>
                                                    { tiposEstados.map( (estado) => {
                                                                    
                                                        let check = false
                                                        if( estado.id === idEstadoPropuesta ) check = true

                                                        if( estado.id)
                                                        
                                                        return (<Form.Check
                                                            disabled={(propuestaToUpdate.idEstadoPropuesta.id == 5 || estado.id == 5 || estado.estado == false ) ? true : false}
                                                            key={estado.id}
                                                            inline
                                                            label={capitalizarString(estado.nombre)}
                                                            name="type"
                                                            type={'radio'}
                                                            id={estado.id}
                                                            defaultChecked={check}
                                                            onChange={ e => {
                                                                setIdEstadoPropuesta(e.target.id)
                                                            }}
                                                            required
                                                        />)
                                                    }) }
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor seleccionar Estado.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group className="m-4">
                                                <Form.Label>Nombre de la Propuesta:</Form.Label>
                                                <Form.Control 
                                                    disabled={formDisabled}
                                                    autoComplete="off"
                                                    type="text" 
                                                    name="nombre"
                                                    required
                                                    value={nombre}
                                                    onChange={(e) => setNombre(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar Nombre de la Propuesta.
                                                </Form.Control.Feedback>              
                                            </Form.Group>
                                            
                                            <Form.Group className="m-4">
                                                <Form.Label>Descripción:</Form.Label>
                                                <Form.Control 
                                                disabled={formDisabled}
                                                    autoComplete="off"
                                                    as="textarea" 
                                                    rows={3} 
                                                    name="descripcion"
                                                    value={descripcion}
                                                    required
                                                    onChange={e => setDescripcion(e.target.value)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar Descripción.
                                                </Form.Control.Feedback>              
                                            </Form.Group>

                                            <Form.Group className="mb-4 m-4">
                                                <Form.Label>Duración (Días):</Form.Label>
                                                <Form.Control
                                                    disabled={formDisabled}
                                                    autoComplete="off"
                                                    value={periodo}
                                                    name="periodo"
                                                    onChange={e => setPeriodo(e.target.value)}
                                                    type="number"
                                                    min={1}
                                                    pattern="^[0-9]+"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Por favor ingresar Duración, número entero y positivo sobre 0 (ej: 5)
                                                </Form.Control.Feedback> 
                                            </Form.Group>
                                        {/* </Form> */}
                                    </Card>
                                </Col>

                                <Col>
                                    <Card>
                                    <div className='m-3'>
                                        <h5>Costos de la Propuesta</h5>
                                    </div>
                                        <Card.Body>
                                            <AdvanceTableWrapper
                                                columns={columnsCostos}
                                                data={costosPropuestaActions}
                                                sortable
                                                pagination
                                            >
                                                <Row className="flex-end-center mb-3">
                                                    <Col xs="auto" sm={8}>
                                                        <h6>Ítem Propuesta:</h6>
                                                    </Col>
                                                    <Col xs="auto" sm={4}>
                                                        <div id="new-document">
                                                            <IconButton
                                                            disabled={formDisabled}
                                                            variant="falcon-success"
                                                            size="sm"
                                                            icon="plus"
                                                            transform="shrink-3"
                                                            className='me-2'
                                                            onClick={() => {
                                                                setShowItemModal(!showItemModal)
                                                            }}
                                                            >
                                                                Nuevo ítem
                                                            </IconButton>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <AdvanceTable
                                                    title='Costos'
                                                    subtitle='Tabla de Costos'
                                                    searchBar={false}
                                                    btnExport={false}
                                                    table
                                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                                    rowClassName="align-middle white-space-nowrap"
                                                    tableProps={{
                                                    bordered: true,
                                                    striped: true,
                                                    className: 'fs--1 mb-0 overflow-hidden'
                                                    }}
                                                />
                                            </AdvanceTableWrapper>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            { !formDisabled && 
                                <Row className='text-end'>
                                    <Form.Group className='text-end'>
                                        <Button
                                            className="m-2"
                                            variant="secondary"
                                            onClick={ () => setShowUpdatePropuestaModal(false)}
                                        >
                                            Cancelar
                                        </Button>
                                        <Button className="m-2" variant="success" type="submit" disabled={formDisabled || isLoading}>
                                            Guardar
                                        </Button>
                                    </Form.Group>
                                </Row>
                            }
                        </Form>
                    </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>

        <> {/* MODALES */}

            {/* MODAL PARA AGREGAR ITEM */}
            <Modal
                show={showItemModal}
                centered
                onHide={() => setShowItemModal(false)}
            >
                <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Nuevo ítem
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setShowItemModal(false)}
                />
                </Modal.Header>
                <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">

                        <NuevoItem tiposCostos={tiposCostos}  setShowItemModal={setShowItemModal}
                        addNewItem={addNewItem}/>
                    </Card.Body>
                </Card>
                </Modal.Body>
            </Modal>
            
            <Modal
                show={showUpdateItemModal}
                centered
                onHide={() => setUpdateShowItemModal(false)}
            >
                <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar ítem
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={() => setUpdateShowItemModal(false)}
                />
                </Modal.Header>
                <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                        <UpdateItem tiposCostos={tiposCostos} updateItem={updateItem} setUpdateShowItemModal={setUpdateShowItemModal} itemToUpdate={itemToUpdate}/>
                    </Card.Body>
                </Card>
                </Modal.Body>
            </Modal>

            <Modal /* MODAL PARA INGRESAR FECHA DE INICIO AL PASAR PROPUESTA A INSTALADA */
                show={showNewDateToInstall}
                centered
                onHide={() => setShowNewDateToInstall(false)}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Fecha de Instalación
                    </Modal.Title>
                    <CloseButton
                        className="btn btn-circle btn-sm transition-base p-0"
                        onClick={() => setShowNewDateToInstall(false)}
                    />
                </Modal.Header>

                <Modal.Body className="p-3 px-4">
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-4 me-4">
                            <FormLabel>Inicio Planificado:</FormLabel>
                            <DatePicker
                                locale='es'
                                dateFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                disabled={formDisabled}
                                selected={fechaInicioSituacionReal}
                                onChange={(date)=> {
                                    setFechaInicioSituacionReal(date);
                                }}
                                // maxDate={new Date}
                                formatWeekDay={day => day.slice(0, 3)}
                                className='form-control'
                                required
                            />
                        </Form.Group>
                        <Form.Group className='text-end'>
                            <Button
                                className="m-2"
                                variant="secondary"
                                onClick={() => setShowNewDateToInstall(false)}
                            >
                                Cancelar
                            </Button>
                            <Button className="m-2" variant="success" type="submit" onClick={ () => {
                                // setShowNewDateToInstall(false)
                                setFormDisabled(true)
                            }}>
                                Instalar
                            </Button>
                        </Form.Group>
                    </Form>
                </Modal.Body>
            </Modal>
        </>

    </> 
  )
}

import { useCallback, useEffect, useState } from "react";
import { Button, Card, CloseButton, Form, Modal } from "react-bootstrap";

import { toastNotification } from "helpers/utils";
import axios from "utils/axios";
import useLoadingContext from "hooks/useLoadingContext";
import PropTypes from 'prop-types';

// Función para manejar la creación y actualización de categorías
const saveCategoria = async (url, method, data) => {
    try {
        const response = await axios({ url, method, data });
        const { statusCode } = response.data;
        const isSuccess = method === 'post' ? statusCode === 201 : statusCode === 200;
        const successMessage = method === 'post' 
            ? 'Categoría de mensaje creada correctamente' 
            : 'Categoría de mensaje actualizada correctamente';
        const errorMessage = method === 'post' 
            ? 'Ocurrió un error al intentar crear la categoría de mensaje' 
            : 'Ocurrió un error al intentar actualizar la categoría de mensaje';

        isSuccess
            ? toastNotification('success', successMessage)
            : toastNotification('warning', errorMessage);

        return response.data;
    } catch (error) {
        console.error(`Ha ocurrido un error al ${method === 'post' ? 'crear' : 'actualizar'} la categoría de mensaje`, error);
        toastNotification('error', `Ocurrió un error al intentar ${method === 'post' ? 'crear' : 'actualizar'} la categoría de mensaje`);
    }
};

const createNewCategoria = (descripcion) =>
    saveCategoria(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/createNewCategoriaMensaje`, 'post', { descripcion });

const updateCategoriaMensaje = (idCategoria, descripcion) =>
    saveCategoria(`${process.env.REACT_APP_ADMIN_SERVICE}/categoriaMensaje/updateCategoriaMensaje`, 'patch', { idCategoria, descripcion });

export const ModalCreateEditCategoria = ({ 
    showCreateEditModal,
    setShowCreateEditModal,
    categoriaToUpdate = {},
    setCategoriaToUpdate,
    addCategoria,
    updateCategoria,
}) => {

    const { setIsLoading } = useLoadingContext();

    const [ descripcion, setDescripcion ] = useState(categoriaToUpdate.descripcion || '');
    const [ validated, setValidated ] = useState(false);

    const handleSubmit = async ( e ) => {
        e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return;
        };

        setIsLoading(true);
        try {
            const response = categoriaToUpdate.id
                ? await updateCategoriaMensaje(categoriaToUpdate.id, descripcion)
                : await createNewCategoria(descripcion);

            if (response?.statusCode === 200 || response?.statusCode === 201) {
                categoriaToUpdate.id ? updateCategoria(response.data) : addCategoria(response.data);
                onCloseModal();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };


    const onCloseModal = useCallback(() => {
        setDescripcion('');
        setShowCreateEditModal(false);
        setValidated(false);
        setCategoriaToUpdate({});
    }, []);


    useEffect(() => {
        if( showCreateEditModal === true ) setDescripcion(categoriaToUpdate.descripcion || '');
    }, [showCreateEditModal])

    return (
        <Modal
            show={showCreateEditModal}
            centered
            onHide={onCloseModal}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { ( categoriaToUpdate.id > 0 ) ? 'Actualizar Categoría de Mensaje' : "Nueva Categoría de Mensaje" }
                </Modal.Title>
                <CloseButton
                    className="btn btn-circle btn-sm transition-base p-0"
                    onClick={onCloseModal}
                />
            </Modal.Header>
            <Modal.Body className="p-0">
                <Card>
                    <Card.Body className="fs--1 fw-normal p-4">
                    
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>


                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Descripción:</Form.Label>
                                <Form.Control 
                                    autoComplete="off"
                                    type="text"
                                    name="descripcion"
                                    minLength={5}
                                    required
                                    value={descripcion}
                                    onChange={(e) => setDescripcion(e.target.value)}
                                />
                                <Form.Control.Feedback type="invalid">
                                    La descripción debe de tener al menos 5 caracteres.
                                </Form.Control.Feedback>              
                            </Form.Group>
                            

                            <Form.Group aria-disabled={true}>
                                <Button
                                    variant="secondary"
                                    className="m-2"
                                    onClick={onCloseModal}
                                >
                                    Cancelar
                                </Button>
                                <Button type="submit" variant="success" className="m-2">
                                    Guardar
                                </Button>
                            </Form.Group>

                        </Form>

                    </Card.Body>
                    
                </Card>
            </Modal.Body>
        </Modal>
    );
};

ModalCreateEditCategoria.propTypes = {
    showCreateEditModal: PropTypes.bool.isRequired,
    setShowCreateEditModal: PropTypes.func.isRequired,
    categoriaToUpdate: PropTypes.shape({
        id: PropTypes.number,
        descripcion: PropTypes.string,
        estado: PropTypes.bool,
    }),
    setCategoriaToUpdate: PropTypes.func.isRequired,
    addCategoria: PropTypes.func.isRequired,
    updateCategoria: PropTypes.func.isRequired,
};
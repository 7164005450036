import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../../components/common/Flex';
import { PieChart } from 'echarts/charts';
import * as echarts from 'echarts/core';
import { coloresGraphics, getColor } from '../../../../helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { BasicEChartsCliente } from '../../../../components/common/BasicEChart';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { setColors } from 'helpers';
import { Link } from 'react-router-dom';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);



const getOptions = (data, radius) => ({

  color: coloresGraphics,

  tooltip: {
    padding: [7, 10],
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.percent}%`
  },
  series: [
    {
      name: '11',
      type: 'pie',
      radius,
      avoidLabelOverlap: false,
      emphasis: {
        scale: false
      },
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false,
        position: 'center',
        formatter: '{a}',
        fontSize: 23,
        color: getColor('dark')
      },
      data
    }
  ]
});

const MarketShareItem = ({ item, index, total }) => {
  const { name, color, value } = item;

  const percentage = ((value * 100) / total).toFixed(1);
  
  return (
    <Flex
      alignItems="center"
      justifyContent="between"
      className={`fw-semi-bold fs--2 ${index === 0 && 'mt-3'}`}
    >
      <p className="mb-1">
        <FontAwesomeIcon
          icon="circle"
          className={`me-2`}
          color={ color || '#7D3C98' }
        />
        {name}
      </p>
      <div>{percentage}%</div>
    </Flex>
  );
};

const PlantGraphic = ({ data, radius, title = '' }) => {
  const total = data.reduce((acc, val) => val.value + acc, 0);

  const [ dataToShow, setDataToShow ] = useState(data);
  const [ isShowComplete, setIsShowComplete ] = useState(false);


  useEffect(() => {
    isShowComplete ? setDataToShow(data) : calculateTop(data);
  }, [isShowComplete, data]);


  const calculateTop = ( dataToCalculate ) => {
    let firstFour = [];
    
    // Calcular el top
    if( dataToCalculate.length > 4 ){
      firstFour = [...dataToCalculate].slice(0, 10);
      let restSum = [...dataToCalculate].slice(10).reduce((acc, obj) => acc + obj.value, 0); // Se calcula el acomulado del resto que quedará bajo la categoria de otros
      firstFour.push({value: restSum, name: 'Otros', color: coloresGraphics[firstFour.length] });
      setDataToShow(firstFour);
      return;
    }
    setDataToShow(dataToCalculate);
  }


  const buttonToggleViewMore = ( texto = '', icon = '' ) => (
    <Link
      to={''}
      className="fw-semi-bold fs--2 text-nowrap"
      onClick={() => setIsShowComplete(!isShowComplete)}
    >
      { texto }
      <FontAwesomeIcon
        icon={icon}
        className="ms-1"
        transform="down-1"
      />
    </Link>
  );

  return (
    <>
      <Row className='g-4'>
        <div className="d-flex justify-content-between g-4">
          <h6 className="mt-1">{ title }</h6>
          { data.length > 10
            ? isShowComplete 
              ? buttonToggleViewMore('Ver Menos', 'arrow-up') 
              : buttonToggleViewMore('Ver Todo', 'arrow-down')
            : ''
          }
        </div>
      </Row>

      <Row>

        <Col sm={12} xxl={8} className="pe-2 mt-3">

          {dataToShow.map((item, index) => (
            <MarketShareItem
              item={item}
              index={index}
              key={index}
              total={total}
            />
          ))}
        </Col>

        <Col xs="auto" className='mx-auto'>
          <div className="ps-0">
            <BasicEChartsCliente
              echarts={echarts}
              options={getOptions(dataToShow, radius)}
              style={{ width: '6.625rem', height: '10.625rem' }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

MarketShareItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    value: PropTypes.number.isRequired
  }),
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
};

PlantGraphic.propTypes = {
  data: PropTypes.array.isRequired,
  radius: PropTypes.array.isRequired
};

export default PlantGraphic;

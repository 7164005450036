import PropTypes from 'prop-types';
import IconButton from "components/common/IconButton";

export const TableHeaderButton = ({ type = '', icon = '', text = '', onClick }) => {
    return (
        <IconButton
            variant={`falcon-${type}`}
            size="sm"
            icon={icon}
            transform="shrink-3"
            className="me-2"
            onClick={onClick}
        >
            { text }
        </IconButton>
    );
};

TableHeaderButton.propTypes = {
    type: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

import React, { useEffect, useState } from 'react'
import { Button, CloseButton, Form, Modal, Card } from 'react-bootstrap'
import { useParams } from "react-router-dom";

import axios from 'utils/axios';

import { getExtension, toastNotification } from 'helpers/utils';
import { TablaArchivos } from '../../components';
import useAuth from 'hooks/useAuth';
import useLoadingContext from 'hooks/useLoadingContext';

export const EditarDocumento = ({ literaturaToEdit, setEditLiterature, startResetApp, showEditLiteratureModal, setShowEditLiteratureModal }) => {

    let params = useParams();

    const { user } = useAuth();
    const { isLoading, setIsLoading } = useLoadingContext();

    const [inputKey, setInputKey] = useState(Date.now());

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [ archivos, setArchivos ] = useState([]); // Listado de archivos para mostrar en pantalla
    const [ newArchivos, setNewArchivos ] = useState([]); // Nuevos archivos a agregar
    const [ archivosToDelete, setArchivosToDelete ] = useState([]); // Archivos para eliminar

    const [ validated, setValidated ] = useState(false);

    useEffect(() => {
      if( literaturaToEdit ) asignForm();
    }, [literaturaToEdit]);

    useEffect( () => {
      if( !showEditLiteratureModal ) resetForm();
    }, [showEditLiteratureModal]);

    const asignForm = () => {
      setTitle(literaturaToEdit.title);
      setDescription(literaturaToEdit.description);
      setArchivos( literaturaToEdit.files );
    }

    const resetForm = () => {
      setTitle('');
      setDescription('');
      setArchivos([]);
      setNewArchivos([]);
      setArchivosToDelete([]);
      setEditLiterature();
      setValidated(false);
    }

    const handleSubmit = async(e) => {
      
      e.preventDefault();
      const form = e.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      const formToSend = new FormData();

      formToSend.append("id_equipment", params.id);
      if(title != null) formToSend.append("title", title);
      if(description != null)formToSend.append("description", description);
      if( archivosToDelete.length > 0 ) formToSend.append("filesToDelete", archivosToDelete)
      if (newArchivos) {
        for (const element of newArchivos) {
          formToSend.append("files", element.file);
        }
      }

      try {
        setIsLoading(true);
        const resp = await axios.put(`${process.env.REACT_APP_EQUIPO_SERVICE}/technicalLiterature/${literaturaToEdit.id}`, formToSend);
        if( resp.status === 200 ){
            toastNotification('success','Literatura editada correctamente', 2000)
            setShowEditLiteratureModal(false);
            startResetApp();
        }
        setIsLoading(false);
      } catch (error) {
          error.response?.data?.statusCode === 413 
            ? toastNotification("error", "El tamaño de uno de los archivos supera el peso maximo", 2000)
            : toastNotification("error", "Ocurrio un error inesperado.", 2000)
          setIsLoading(false);
        return;
      }
    };

    
    const handleFilesChange = (e) => {
      setInputKey(Date.now()); // Para reiniciar el valor del input
  
      const filesRecibidos = [...e];
      let array = filesRecibidos.map((archivo) => {
        const nameExtension = getExtension(archivo.name);
        return {
          file: archivo,
          temporalId: `${nameExtension[0]}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}`,
          name_file: nameExtension[0],
          extension: nameExtension[1],
          date_file: Date.now(),
          size: archivo.size,
          user: { name: user.displayName, lastName: user.displayLastName },
        }
      })
  
      setNewArchivos([ ...newArchivos, ...array ]);
      setArchivos([ ...archivos, ...array]);
    };
  
  
    const handleDeleteFile = ( archivo ) => {
      if( archivo.temporalId ){ // Archivo no creado
        setNewArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
        setArchivos( archivos.filter( arch => arch.temporalId !== archivo.temporalId ) );
      }else{ // Archivo a eliminar
        setArchivos( archivos.filter( arch => arch.id !== archivo.id ) );
        setArchivosToDelete([ ...archivosToDelete, archivo.id]);
      }
    }

    return (
        <>
        <div> {/* MODAL PARA EDITAR UNA LITERATURA */}
            <Modal
              size='lg'
              show={showEditLiteratureModal}
              centered
              onHide={() => setShowEditLiteratureModal(false)}
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">Editar Documento</Modal.Title>
                <CloseButton
                  className="btn btn-circle btn-sm transition-base p-0"
                  onClick={() => setShowEditLiteratureModal(false)}
                />
              </Modal.Header>
              <Modal.Body className="p-0">
                <Card>
                  <Card.Body className="fs--1 fw-normal p-4">

                    <Form noValidate validated={validated} onSubmit={handleSubmit} >

                        <Form.Group className="mb-3" controlId="formGroupName">
                            <Form.Label>Título:</Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={literaturaToEdit?.title}
                                name='title'
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                              Por favor ingresar Título.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Descripción:</Form.Label>
                            <Form.Control
                                onChange={(e) => setDescription(e.target.value)}
                                defaultValue={literaturaToEdit?.description}
                                as="textarea"
                                name='description'
                                rows={3}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                              Por favor ingresar Descripción.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Seleccione Archivos a Subir:</Form.Label>
                            <Form.Control 
                              key={inputKey}
                              type="file"
                              multiple
                              onChange={(e) => handleFilesChange(e.target.files)} 
                            />
                        </Form.Group>

                        { archivos.length > 0 && (
                            <div className="my-4">
                              <TablaArchivos key={'archivos'} archivos={archivos} isCrud={true} handleDelete={handleDeleteFile} />
                            </div>
                          )
                        }

                        <Button disabled={isLoading} className='m-2' variant="secondary" onClick={() => setShowEditLiteratureModal(false)}>
                            Cancelar
                        </Button>
                        <Button disabled={isLoading} className='m-2' variant="success" type='submit'>
                            Guardar
                        </Button>
                    </Form>
                  </Card.Body>
                </Card>
              </Modal.Body>
            </Modal>
          </div>
        </>
    )
}

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { BiErrorCircle } from 'react-icons/bi';
import axios from "utils/axios";
import { toastNotification, verifyResponseUnauthorizedAwait, verifyResponseUnauthorizedFetch } from "helpers/utils";
import useAuth from "hooks/useAuth";
import InputAtributos from "../components/InputAtributosDimensional";
import InputAtributosOperacional from "../components/inputAtributosOperacional";
import { convertStringsToUpperCase } from "helpers";
import useLoadingContext from "hooks/useLoadingContext";

export const CreateEquipo = ({
  handleChange,
  setShowCreateModal,
  allSizesEquipos,
}) => {
  const { user } = useAuth();
  const { setIsLoading } = useLoadingContext();
  const [validated, setValidated] = useState(false);

  const [corporativos, setCorporativos] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [tiposEquipos, setTiposEquipos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [modelos, setModelos] = useState([]);
  const [atributos, setAtributos] = useState([]);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [codeId, setCodeId] = useState();
  const [serialNumber, setSerialNumber] = useState();
  const [size, setSize] = useState();
  const [rpm, setRpm] = useState();
  const [idArea, setIdArea] = useState();
  const [idTipo, setIdTipo] = useState();
  const [idMarca, setIdMarca] = useState();
  const [idModelo, setIdModelo] = useState();
  const [arreglo, setArreglo] = useState([]);

  const [unidadesMedida, setUnidadesMedida] = useState([]);


  useEffect(() => {
    Promise.all([ getAllTiposEquipos(), getAllCorporativos(), getAllMarcas(), getAllUnidadesMedida() ])
      .then(([ allTiposEquipos, allCorporativos, allMarcas, allUnidadesMedida ]) => {
    })
    .catch(error => {
      toastNotification('warning', 'Error al cargar el formulario, por favor intentelo nuevamente');
    })
  }, []);

  const getAllUnidadesMedida = async () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/unidadMedida/getAllMeasureUnits`;
    try {
      const { data } = await axios.get(url);
      if (data.statusCode == 200) setUnidadesMedida(data.data);
    } catch (error) {
      console.error("Error al obtener todas las unidades de medida en el formulario.");
      throw "Error al obtener todas las unidades de medida en el formulario.";
    }
  };

  const getAllCorporativos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/corporativo/getAllCorporations`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setCorporativos(list);
      }
    }).catch( error => {
      console.error("Error al obtener los corporativos");
    } );
  };

  const getAllTiposEquipos = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/tiposEquipos/getAllTiposDeEquipos`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setTiposEquipos(list);
      }
    }).catch( error => {
      console.error("Error al obtener los tipos de equipos");
    } );
  };

  const getAllMarcas = () => {
    const url = `${process.env.REACT_APP_ADMIN_SERVICE}/marca/getAllBrands`;
    axios.get(url).then((response) => {
      if (response.data.statusCode === 200) {
        const list = response.data.data;
        setMarcas(list);
      }
    }).catch( error => {
      console.error("Error al obtener las marcas");
    } );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (idTipo === undefined) {
      toastNotification("error", "El tipo de equipo es obligatorio");
      return;
    }

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      let formData = {
        name: name,
        description: description,
        codeId: codeId,
        serialNumber: serialNumber,
        size: Number(size),
        rpm: rpm,
        id_area: Number(idArea),
        id_tipo: Number(idTipo),
        id_marca: Number(idMarca),
        id_modelo: Number(idModelo),
        id_usuario: Number(user.id),
      };
      formData = convertStringsToUpperCase(formData);
      formData.atributos = arreglo;

      try {
        setIsLoading(true);
        const { data } = await axios.post(`${process.env.REACT_APP_ADMIN_SERVICE}/equipo/createEquipment/`, formData );
        setIsLoading(false);
        if( verifyResponseUnauthorizedAwait(data, 2) ) return;
        if (data.statusCode == 201) {
          handleChange(true);
          setShowCreateModal(false);
          toastNotification("success", "Equipo creado correctamente");
        } else if (data.statusCode >= 400 && data.statusCode <= 500) {
          toastNotification("error", "El Equipo no se pudo agregar");
        } else {
          toastNotification("error", "Error interno al intentar agregar un equipo, por favor comunicarse con equipo de desarrollo");
        }
      } catch (error) {
        setIsLoading(false);
        toastNotification("error", "El Equipo no se pudo agregar");
      }
    }
    setValidated(true);
  };

  const handleChangeMarca = (e) => {
    const id = e.target.value;
    setIdMarca(id);
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/modelo/getModelsByIdBrand/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setModelos(list);
          } else setModelos([]);
        })
        .catch((err) => {
          console.error("Error al obtener lpos modelos por la marca");
          setModelos([]);
        });
    } else {
      setModelos([]);
    }
  };

  const handleChangeTipoEquipo = (e) => {
    setArreglo([]);
    const id = e.target.value;
    setIdTipo(id);
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/equipo/findAttributeTypeEquipment2/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setAtributos(list);
          } else {
            setAtributos([]);
          }
        })
        .catch((err) => {
          console.error("Error al obtener los atributos del tipo de equipo");
          setAtributos([]);
        });
    } else {
      setAtributos([]);
    }
  };

  const handleChangeAtributos = (item) => {
    if (item.value === "") {
      // Si el item viene sin valor, se elimina del arreglo
      setArreglo((prevAtributos) =>
        arreglo.filter((atributo) => atributo.id_atributo !== item.id_atributo)
      );
      return;
    }

    const index = arreglo.findIndex(
      (element) => element.id_atributo === item.id_atributo
    );

    if (index !== -1) {
      const newArreglo = [...arreglo];
      newArreglo[index] = item;
      setArreglo(newArreglo);
    } else {
      setArreglo([...arreglo, item]);
    }
  };

  const handleChangeCorporativo = (e) => {
    const id = e.target.value;
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/planta/getFloorsByIdCorporation/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setPlantas(list);
            setLineas([]);
            setAreas([]);
          } else {
            setPlantas([]);
            setLineas([]);
            setAreas([]);
          }
        })
        .catch((err) => {
          console.error("Error al obtener las plantas del corporativo");
          setPlantas([]);
          setLineas([]);
          setAreas([]);
        });
    } else {
      setIdArea();
      setPlantas([]);
      setLineas([]);
      setAreas([]);
    }
  };

  const handleChangePlanta = (e) => {
    const id = e.target.value;
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/linea/getLinesByIdFloor/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setLineas(list);
            setAreas([]);
          } else {
            setLineas([]);
            setAreas([]);
          }
        })
        .catch((err) => {
          console.error("Error al obtener las lineas de la planta");
          setLineas([]);
          setAreas([]);
        });
    } else {
      setIdArea();
      setLineas([]);
      setAreas([]);
    }
  };

  const handleChangeLinea = (e) => {
    const id = e.target.value;
    if (id > 0) {
      const url = `${process.env.REACT_APP_ADMIN_SERVICE}/area/getAllAreasByIdLine/${id}`;
      axios
        .get(url)
        .then((response) => {
          if( verifyResponseUnauthorizedFetch(response, 1) ) return;
          if (response.data.statusCode === 200) {
            const list = response.data.data;
            setAreas(list);
          } else {
            setAreas([]);
          }
        })
        .catch((err) => {
          console.error("Error al obtener las areas de la linea");
          setAreas([]);
        });
    } else {
      setIdArea();
      setAreas([]);
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col sm={12} lg={6}>
            <Form.Group className="mb-3">
              {/* Primera Forma */}
              <Form.Label className="d-flex align-items-center">
                Nombre:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Nombre.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Descripción:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Control
                autoComplete="off"
                as="textarea"
                rows={1}
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Descripción.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Código Id:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Control
                type="text"
                name="codeId"
                value={codeId}
                required
                onChange={(e) => setCodeId(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Código Id.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Número de Serie:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Control
                type="text"
                name="serialNumber"
                value={serialNumber}
                required
                onChange={(e) => setSerialNumber(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar Número de Serie.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Tamaño:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                name="size"
                value={size}
                required
                onChange={(e) => setSize(e.target.value)}
              >
                <option value="">Seleccionar tamaño</option>
                {allSizesEquipos.map((size) => {
                  if (size.estado)
                    return (
                      <option key={size.id} value={size.id}>
                        {size.descripcion}
                      </option>
                    );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor Seleccione un Tamaño.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                RPM:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Control
                type="text"
                name="rpm"
                value={rpm}
                required
                onChange={(e) => setRpm(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Por favor ingresar RPM.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col sm={12} lg={6}>
            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Corporativo:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                name="id_corporativo"
                onChange={handleChangeCorporativo}
              >
                <option value="">Seleccione Corporativo</option>
                {corporativos.map((item) => (
                  <option name="corporativo" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Planta:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select name="id_planta" onChange={handleChangePlanta}>
                <option value="">Seleccione Planta</option>
                {plantas.map((item) => (
                  <option name="planta" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Línea:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select name="id_linea" onChange={handleChangeLinea}>
                <option value="">Seleccione Línea</option>
                {lineas.map((item) => (
                  <option name="linea" key={item.id} value={item.id}>
                    {item.descripcion}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Área:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                required
                name="id_area"
                value={idArea}
                onChange={(e) => setIdArea(e.target.value)}
              >
                <option value="">Seleccione Área</option>
                {areas.map((item) => (
                  <option name="area" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Área.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Marca:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                required
                name="id_marca"
                value={idMarca}
                onChange={handleChangeMarca}
              >
                <option value="">Seleccione Marca</option>
                {marcas.map((item) => (
                  <option name="marca" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Marca.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Modelo:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                required
                name="id_modelo"
                value={idModelo}
                onChange={(e) => setIdModelo(e.target.value)}
              >
                <option value="">Seleccione Modelo</option>
                {modelos.map((item) => (
                  <option name="modelo" key={item.id} value={item.id}>
                    {item.nombre}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Modelo.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="d-flex align-items-center">
                Tipo Equipo:
                <BiErrorCircle className="ms-2" />
              </Form.Label>
              <Form.Select
                required
                name="id_tipo"
                value={idTipo}
                onChange={handleChangeTipoEquipo}
              >
                <option value="">Seleccione Tipo Equipo</option>
                {tiposEquipos.map((item) => (
                  <option name="tipo_equipo" key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                Por favor seleccione Tipo Equipo.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {atributos.length > 0 ? (
            <Col>
              <div>
                <Card className={"p-3 mb-3"}>
                  <h5 className="mb-5">Atributos Operacionales</h5>
                  {atributos.map((item) => {
                    return (
                      <InputAtributosOperacional
                        id={item.id}
                        key={item.id}
                        item={item}
                        handleChangeAtributos={handleChangeAtributos}
                        unidadesMedida={unidadesMedida}
                      />
                    );
                  })}
                </Card>

                <Card className={"p-3 mb-3"}>
                  <h5 className="mb-5">Atributos Dimensionales</h5>
                  {atributos.map((item) => {
                    return (
                      <>
                        <InputAtributos
                          id={item.id}
                          key={item.id}
                          item={item}
                          handleChangeAtributos={handleChangeAtributos}
                          unidadesMedida={unidadesMedida}
                        />
                      </>
                    );
                  })}
                </Card>
              </div>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <div>
          <Button
            variant="secondary"
            className="m-2"
            onClick={() => setShowCreateModal(false)}
          >
            Cancelar
          </Button>
          <Button type="submit" variant="success" className="m-2">
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};
